import styled from 'styled-components'
import { media } from 'styles/utilities/functions'
import { spacing } from 'styles/utilities/variables'

const StyledColorPickerList = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletPortraitBelow`
    padding: 0 ${spacing.medium};
    margin-top: ${spacing.medium};
  `}
`

export default StyledColorPickerList
