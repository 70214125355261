import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'

export const StyledPrintOrder = styled.section`
  /* margin: 4rem; */
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 140px 140px 140px auto;
  gap: 2rem;
  width: 210mm;
  padding: 3rem;

  p {
    margin-bottom: 2px;
  }

  @page {
    size: A4;
    margin: 1rem;
  }

  h2 {
    margin-bottom: .5rem;
  }
`

export const StyledPrintOrderTitle = styled.div`
  grid-column: span 2;
`

export const StyledPrintOrderAddress = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

`

export const StyledPrintOrderInfo = styled.div`
  border-top: 1px solid black;
  padding-top: .5rem;
  grid-row: span 1;
`

export const StyledPrintOrderContact = styled.div``

export const StyledPrintOrderProductList = styled.table`
  border-top: 1px solid black;
  padding-top: .5rem;
  grid-column: span 2;
  grid-row: span 2;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  thead th:nth-child(1) {
    width: 10%;
  }

  thead th:nth-child(2) {
    width: 15%;
  }

  thead th:nth-child(3) {
    width: 30%;
  }

  thead th:nth-child(4) {
    width: 45%;
  }

  tbody > tr {
    border-top: 1px solid ${colors.grey04};
  }

  th,
  td {
    padding: 1rem 0;
  }
`
