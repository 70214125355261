import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import StyledPrintContainer from './style'
import { createPortal } from 'react-dom'

const PrintContainer = (props) => {
  const { children } = props
  const [ready, setReady] = React.useState(false)

  useEffect(() => {
    setReady(true)
  }, [])


  return ready ? createPortal((
      <StyledPrintContainer>
        {children}
      </StyledPrintContainer>
      ),
      document.getElementById('print-portal'
    )
  ) : null
}

PrintContainer.propTypes = {
  children: PropTypes.any,
}

export { PrintContainer }
