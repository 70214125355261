import React from 'react'
import StyledHeader from 'components/editor/Header/Header.style'
import Button from 'components/common/Button'
import { Link } from 'react-router-dom'
import Logo from 'components/common/Logo'

const Header = ({ backTitle = 'Tillbaka'}) => {
  return (
    <StyledHeader>
      <StyledHeader.Top>
        <Logo />
        <Link to='/'>
          <Button type="plain light">{backTitle}</Button>
        </Link>
      </StyledHeader.Top>
    </StyledHeader>
  )
}

export { Header }
