export const ADD_TOAST = 'ADD_TOAST'
export const addToast = (toast) => {
  return async (dispatch) => {
    await dispatch({
      type: ADD_TOAST,
      payload: toast,
    })
  }
}

export const REMOVE_TOAST = 'REMOVE_TOAST'
export const removeToast = (id) => {
  return async (dispatch) => {
    await dispatch({
      type: REMOVE_TOAST,
      payload: id,
    })
  }
}

export const addToastWithTimer = (toast, time = 5000) => {
  return async (dispatch) => {
    await dispatch(addToast(toast))
    setTimeout(() => {
      requestAnimationFrame(() => {
        dispatch(removeToast(toast.id))
      })
    }, time)
  }
}
