import styled from 'styled-components'

const StyledIcon = styled.div`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: ${({ width }) => (width ? width + 'px' : '24px')};
    height: ${({ height }) => (height ? height + 'px' : '24px')};
    stroke: currentColor;
    stroke-width: 1;
    // stroke-linecap: round;
    // stroke-linejoin: round;
    color: currentColor;
    fill: none;
  }
`

export default StyledIcon
