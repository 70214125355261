import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StyledMobileEditor from 'components/editor/Editor/EditorMobile.style'
import {
  ToolbarHeaderMobile,
  ToolbarHeaderMobileSingle,
} from 'components/editor/Toolbar/Header'

import Header from 'components/editor/Header'
import SignList from 'components/editor/SignList'
import { EType } from 'constants/Editor'
import SignSingleView from 'components/editor/SignSingleView'
import ToolbarPane from 'components/editor/Toolbar/Pane'
import ButtonRound from 'components/common/ButtonRound'
import { showNewSignModal } from 'redux/actions/appActions'
import EmptyListMessage from 'components/editor/EmptyListMessage'
import { CartMobile } from 'components/common/Cart'

const stateSelector = (state) => {
  return {
    editor: state.editor,
    signs: state.signs.present.signs,
    activeItem: state.editor.activeItem,
    isBulkEditing: state.editor.isBulkEditing,
  }
}

const EditorMobileLayout = (props) => {
  const { isTableStage, isSingleStage } = props
  const dispatch = useDispatch()
  const { editor, signs, isBulkEditing } = useSelector(stateSelector)

  const handleAddButtonClick = () => {
    dispatch(showNewSignModal())
  }

  const hasSigns = signs && signs.length > 0

  return (
    <StyledMobileEditor>
      <StyledMobileEditor.Header>
        <Header />
      </StyledMobileEditor.Header>

      <StyledMobileEditor.Stage>
        <CartMobile />
        {isTableStage && <ToolbarHeaderMobile />}
        {/* {isTableStage && hasSigns && <SignList type={EType.mobile} />} */}
        <SignList show={isTableStage && hasSigns} type={EType.mobile} />
        {!hasSigns && isTableStage && (
          <EmptyListMessage
            title={'Här var det tomt!'}
            message={'Tryck på plusset nedan för att skapa en ny skylt.'}
          />
        )}

        {isSingleStage && (
          <>
            <ToolbarHeaderMobileSingle />
            <SignSingleView signs={signs} editor={editor} />
          </>
        )}
        {isTableStage && !isBulkEditing && (
          <StyledMobileEditor.AddButton>
            <ButtonRound icon={'add'} clickHandler={handleAddButtonClick} />
          </StyledMobileEditor.AddButton>
        )}
      </StyledMobileEditor.Stage>

      {isSingleStage && (
        <StyledMobileEditor.ToolbarPane>
          <ToolbarPane />
        </StyledMobileEditor.ToolbarPane>
      )}
    </StyledMobileEditor>
  )
}

export default EditorMobileLayout
