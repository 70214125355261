import { combineReducers } from 'redux'
import undoable from 'redux-undo'
import { signsReducer } from 'redux/reducers/SignsReducer'
import { editorReducer } from 'redux/reducers/EditorReducer'
import { toastReducer } from 'redux/reducers/ToastReducer'
import { customerReducer } from 'redux/reducers/CustomerReducer'
import { orderReducer } from 'redux/reducers/OrderReducer'
import { appReducer } from 'redux/reducers/AppReducer'
import { undoableActions } from 'redux/actions/signsActions'
import { cartReducer } from 'redux/reducers/CartReducer'

const isUndoableAction = action => {
  return undoableActions.indexOf(action.type) >= 0
}

export default combineReducers({
  signs: undoable(signsReducer, {
    filter: (action, currentState, previousHistory) => {
      return isUndoableAction(action)
    }
  }),
  editor: editorReducer,
  app: appReducer,
  toasts: toastReducer,
  customer: customerReducer,
  order: orderReducer,
  cart: cartReducer,
})
