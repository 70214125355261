import styled from 'styled-components'
import { typography, colors, spacing } from 'styles/utilities/variables'

const StyledPaneHorizontal = styled.div`
  background: ${colors.grey01};
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
`

StyledPaneHorizontal.Menu = styled.nav`
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin: 0 0 ${spacing.small};
  padding: ${spacing.small} 0 0 ${spacing.small};
  border-top: 1px solid ${colors.grey02};
`

StyledPaneHorizontal.MenuItem = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 5.4rem;
  min-height: 4.7rem;
  padding: ${spacing.xSmall};
  margin: 0 ${spacing.small} 0 0;
  border: ${props =>
    props.isActive ? `1px solid ${colors.grey03}` : '1px solid transparent'};
  border-radius: 4px;
`

StyledPaneHorizontal.MenuIcon = styled.div`
  //background: red;
  //width: 15px;
  //height: 15px;
  //margin-bottom: ${spacing.small};
  margin: auto 0;
  padding-bottom: ${spacing.xSmall};
`

StyledPaneHorizontal.MenuText = styled.div`
  margin-top: auto;
  font-size: ${typography.sizes.small};
`

StyledPaneHorizontal.Panels = styled.div`
  width: 100%;
  //margin: 0 0 0 ${spacing.medium};
  // padding: 0 0 0 ${spacing.medium};
  overflow-x: scroll;
`

StyledPaneHorizontal.Panel = styled.div`
  //margin: ${spacing.medium} 0 ${spacing.small} 0;
  // margin: ${spacing.medium} 0 0 0;
  margin: ${spacing.small} 0 ${spacing.small} 0;
`

StyledPaneHorizontal.Warning = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
`

export default StyledPaneHorizontal
