import React from 'react'
import useMousePosition from 'hooks/useMousePosition'
import StyledErrorPopup from './ErrorPopup.style'

const ErrorPopup = ({ message }) => {
  const { x, y } = useMousePosition()
  const validCoords = typeof x === 'number' && typeof y === 'number'

  if (!validCoords) return null

  return (
    <StyledErrorPopup x={x} y={y}>
      {message}
    </StyledErrorPopup>
  )
}

export default ErrorPopup
