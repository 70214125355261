import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'

const StyledColorPickerButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => (background ? background : colors.primary)};
  border: ${({ background }) =>
    background === '#ffffff' ? '1px solid' : 'none'};
  border-color: ${colors.grey04};
  color: ${({ color }) => (color ? color : colors.white)};
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    `
    box-shadow: 0px 0px 0px 4px ${colors.grey04};
  `}
`

StyledColorPickerButton.Text = styled.div`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 1.2rem;
`

export default StyledColorPickerButton
