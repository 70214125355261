import React from 'react'
import { useDispatch } from 'react-redux'

import { duplicateSigns } from 'redux/actions/signsActions'

import { SIGN_TYPES } from 'constants/Signs'

import List from 'components/common/List/List.style'
import Button from 'components/common/Button'
import Icon from 'components/common/Icon'

// import RowContentExpander from './RowContentExpander'

const SignListMobile = (props) => {
  const {
    editor,
    setActiveSign,
    goToEditor,
    handleCheckboxChange,
    categorisedSigns,
    delimiterText,
    capitalizeMounting,
    show,
  } = props
  const dispatch = useDispatch()

  const goToSingleView = (sign) => {
    return () => {
      setActiveSign(sign)
      goToEditor()
    }
  }
  return (
    <List show={show}>
      {categorisedSigns.map((category) => {
        const signType = SIGN_TYPES.find(
          (signType) => signType.type === category.type
        )
        const lastSignInCategory = category.signs[category.signs.length - 1]
        return (
          <div key={category.type}>
            <List.Header>
              {signType.name}
              <Button
                icon={'addCircle'}
                type={'only-icon'}
                handleButtonClick={() =>
                  dispatch(duplicateSigns([lastSignInCategory.values.id]))
                }
              />
            </List.Header>

            {/* <RowContentExpander items={category.signs} type={'mobile_sign'}> */}
            {category.signs.map((sign, index) => {
              const text = delimiterText(sign.values.text)
              const mounting = capitalizeMounting(sign.values.mounting)
              const isSelected = editor.bulkEdit.indexOf(sign.values.id) >= 0
              const hasTooWideText = sign.values.tooWideTextRowIds.length > 0

              // const getErrorMessage = () => {
              //   if (hasTooWideText) {
              //     let pre =
              //       sign.values.text.length === 1 ? 'Texten' : 'En av texterna'
              //     return sign.values.textSize === 'small'
              //       ? `${pre} är för lång, förkorta texten`
              //       : `${pre} är för lång, gör textstorleken mindre`
              //   }

              //   return null
              // }

              return (
                <List.Row
                  key={index}
                  onClick={
                    editor.isBulkEditing
                      ? handleCheckboxChange(sign.values.id, isSelected)
                      : goToSingleView(sign)
                  }
                >
                  <List.RowAction open={editor.isBulkEditing}>
                    <input
                      type='checkbox'
                      checked={isSelected}
                      onChange={
                        editor.isBulkEditing
                          ? () => {}
                          : handleCheckboxChange(sign.values.id, isSelected)
                      }
                    />
                  </List.RowAction>
                  <List.RowContent>
                    <List.RowTitle>{text}</List.RowTitle>
                    {/* {getErrorMessage() && (
                      <List.RowError small>{getErrorMessage()}</List.RowError>
                    )} */}
                    <List.RowMeta>
                      {sign.values.theme.name}, {mounting}
                    </List.RowMeta>
                    {hasTooWideText && (
                      <List.RowErrorIcon>
                        <Icon name={'errorTriangle'} size={24} />
                      </List.RowErrorIcon>
                    )}
                  </List.RowContent>
                </List.Row>
              )
            })}
            {/* </RowContentExpander> */}
          </div>
        )
      })}
    </List>
  )
}

export default SignListMobile
