import styled from 'styled-components'
import { colors, spacing, typography } from 'styles/utilities/variables.js'
import Button from 'components/common/Button'

const StyledPreviewPanel = styled.div`
  padding: ${spacing.small};
  overflow: hidden;
  background: ${colors.grey01};
  border-bottom: 1px solid ${colors.grey03};
`

StyledPreviewPanel.Header = styled.div`
  padding: ${spacing.small};
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: rgba( 0, 0, 256, 0.1 );
`

StyledPreviewPanel.CanvasWrapper = styled.div`
  position: relative;
  // background: rgba( 256, 0, 0, 0.1 );
`

StyledPreviewPanel.Label = styled.p`
  font-size: ${typography.sizes.small};
  margin-bottom: 0;
`

StyledPreviewPanel.Button = styled(Button)`
  font-size: ${typography.sizes.small};
  font-weight: inherit;
  margin: 0;
  padding: 0;
  min-height: auto;
`

export default StyledPreviewPanel
