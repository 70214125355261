// import { fetchPayment } from 'api/Payment'
import Cart from 'components/common/Cart/Cart'
import { Divider } from 'components/common/Divider'
import { Heading } from 'components/common/Heading'
import Section from 'components/common/Section'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { updateCartHash } from 'redux/actions/appActions'
import { fetchSingleOrder } from 'redux/actions/orderActions'
import { deleteAllSigns } from 'redux/actions/signsActions'
import Loader from 'components/common/Loader'
import { StyledOrderInfo, StyledOrderInfoItem } from './style'
import IconLink from 'components/common/IconLink'
import CustomerView from '../CustomerView'
import { fetchCart } from 'api/Cart'
import { getData } from 'helpers/storage'

const stateSelector = (state) => {
  return {
    order: state.order,
    isLoading: state.order.isLoading,
    shipping: state.app.shipping,
  }
}

const Confirmation = () => {
  const { order, isLoading, shipping } = useSelector(stateSelector)
  let { id } = useParams()
  const dispatch = useDispatch()
  const [cart, setCart] = useState()

  useEffect(() => {
    if (cart?.status === 'complete') {
      dispatch(deleteAllSigns())
      dispatch(updateCartHash(undefined))
    }
  }, [cart, dispatch])

  useEffect(() => {
    const getCart = async () => {
      dispatch(fetchSingleOrder(id))
      const cartHash = getData('cart-hash')
      if (cartHash) {
        const cartResponse = await fetchCart(cartHash)

        setCart(cartResponse)
      }
    }

    getCart()
  }, [dispatch, id])

  return (
    <CustomerView backTitle='Gör en till skylt'>
      <Heading size={1}>Klart!</Heading>
      <Divider />

      <Section>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <p>
              Tack! Vi har nu tagit emot din beställning och ett bekräftelsemail
              har skickats till den emailadress du angav vid beställningen. Har
              du frågor kring din order eller vill komma i kontakt med oss går
              det bra att maila oss på{' '}
              <a href='mailto:info@tomolo.se'>info@tomolo.se</a>
            </p>

            <Divider />

            <StyledOrderInfo>
              <StyledOrderInfoItem>
                <b>Ordernummer:</b>
                <p>{order.external_id}</p>
              </StyledOrderInfoItem>
              <StyledOrderInfoItem>
                <b>Orderdatum:</b>
                <p>{order.created_at.split(' ')[0]}</p>
              </StyledOrderInfoItem>
            </StyledOrderInfo>
          </>
        )}
      </Section>

      <Section>
        <Heading size={2}>Din order</Heading>
        <Divider />

        {isLoading ? (
          <Loader />
        ) : (
          <Cart
            tax={order.tax}
            total={order.total}
            signs={order.items}
            wcOrder={order.wc_order}
            checkout={true}
            shipping={shipping}
          />
        )}
      </Section>
      <IconLink to={'/'} text={'Tillbaka till verktyget'} icon={'arrowLeft'} />
    </CustomerView>
  )
}

export default Confirmation
