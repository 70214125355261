import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  undoLastAction,
  redoLastAction,
  setActiveStage,
} from 'redux/actions/editorActions'
import {
  showShareModal,
  showDeleteSignModal,
  showImportSpreadsheetModal,
  hideCart,
  showCart,
  showNewSignModal,
} from 'redux/actions/appActions'
import { HeaderDesktop } from './HeaderDesktop'
import { HeaderMobile } from './HeaderMobile'
import { useResponsive } from 'hooks/useResponsive'
import {
  duplicateSigns,
} from 'redux/actions/signsActions'
import { TABLE_STAGE } from 'constants/Editor'

const stateSelector = (state) => {
  return {
    signs: state.signs,
    editor: state.editor,
    activeItem: state.editor.activeItem,
    activeStage: state.editor.activeStage,
    isLoggedIn: state.app.isLoggedIn,
    isCartActive: state.app.isCartActive,
    prices: state.app.prices,
  }
}

const Header = () => {
  const { breakpoints } = useResponsive()
  const dispatch = useDispatch()
  const {
    signs,
    editor,
    activeItem,
    activeStage,
    isCartActive,
  } = useSelector(stateSelector)

  const singleActiveItem = activeItem ? [activeItem] : [] // Check if there is an activeItem. We dont want an array like [0]
  const activeItems =
    activeStage === TABLE_STAGE && editor.bulkEdit.length > 0
      ? editor.bulkEdit
      : singleActiveItem

  const handleUndoClick = () => {
    dispatch(undoLastAction())
  }

  const handleRedoClick = () => {
    dispatch(redoLastAction())
  }

  const handleStageChangeClick = (stage) => {
    dispatch(setActiveStage(stage))
  }

  const handleShowShareModalClick = () => {
    dispatch(showShareModal())
  }

  const handleShowImportSpreadsheetModalClick = () => {
    dispatch(showImportSpreadsheetModal())
  }

  const handleAddNewClick = () => {
    dispatch(showNewSignModal())
  }

  const handleDuplicateClick = () => {
    dispatch(duplicateSigns(activeItems))
  }

  const handleDeleteClick = () => {
    dispatch(showDeleteSignModal())
  }

  const handleToggleCartClick = () => {
    isCartActive ? dispatch(hideCart()) : dispatch(showCart())
  }

  if (breakpoints.tabletPortraitBelow) {
    return (
      <HeaderMobile
        signs={signs}
        editor={editor}
        activeItem={activeItem}
        activeItems={activeItems}
        activeStage={activeStage}
        handleShowShareModalClick={handleShowShareModalClick}
        handleUndoClick={handleUndoClick}
        handleRedoClick={handleRedoClick}
        handleStageChangeClick={handleStageChangeClick}
        handleAddNewClick={handleAddNewClick}
        handleDuplicateClick={handleDuplicateClick}
        handleDeleteClick={handleDeleteClick}
        handleToggleCartClick={handleToggleCartClick}
      />
    )
  }

  return (
    <HeaderDesktop
      signs={signs}
      editor={editor}
      activeItem={activeItem}
      activeItems={activeItems}
      activeStage={activeStage}
      handleShowShareModalClick={handleShowShareModalClick}
      handleShowImportSpreadsheetModalClick={
        handleShowImportSpreadsheetModalClick
      }
      handleUndoClick={handleUndoClick}
      handleRedoClick={handleRedoClick}
      handleStageChangeClick={handleStageChangeClick}
      handleAddNewClick={handleAddNewClick}
      handleDuplicateClick={handleDuplicateClick}
      handleDeleteClick={handleDeleteClick}
    />
  )
}

export default Header
