export const BASE_UNIT = 0.8

export const colors = {
  primary: '#37396E',
  secondary: '#00574B',
  white: '#FFFFFF',
  grey01: '#F9F9F9',
  grey02: '#F0F0F0',
  grey03: '#E0E0E0',
  grey04: '#C4C4C4',
  grey05: '#777777',
  black: '#222222',

  blueLight: '#43457B',
  blueMid: '#3034A1',
  blueDark: '#292B52',
  tealDark: '#00574B',
  tealLight: '#C0EAEA',
  warning: '#EB0000',
  success: '#00574B'
}

export const signColors = {
  white: '#ffffff',
  black: '#000000',
  red: '#E4291E',
  yellow: '#FFD600',
  green: '#2BA80B',
  blue: '#3D59E7'
}

export const spacing = {
  xxLarge: `${BASE_UNIT * 8}rem`,
  xLarge: `${BASE_UNIT * 6}rem`,
  large: `${BASE_UNIT * 3}rem`,
  medium: `${BASE_UNIT * 2}rem`,
  small: `${BASE_UNIT}rem`,
  xSmall: `${BASE_UNIT / 2}rem`,
  xxSmall: `${BASE_UNIT / 4}rem`,
  baseUnit: BASE_UNIT
}

export const typography = {
  primaryFont: 'GT Pressura',
  lineHeight: '1.14',
  sizes: {
    xxLarge: `2.4rem`,
    xLarge: `1.7rem`,
    large: `${BASE_UNIT * 2}rem`,
    medium: `${BASE_UNIT * 1.75}rem`,
    small: `${BASE_UNIT * 1.5}rem`,

    base: `${BASE_UNIT * 1.75}rem`
  }
}

export const radius = {
  small: '.4rem',
  medium: '.8rem',
  large: '1.2rem'
}

export const mq = {
  tabletLandscapeDown: 1024,
  tabletLandscapeBelow: 1023,
  tabletPortraitDown: 768,
  tabletPortraitBelow: 767,
  mobileDown: 480
}
