import React, { useState, useRef } from 'react'
import * as Papa from 'papaparse'
import { useDispatch } from 'react-redux'

import Modal from 'components/common/Modal'

import { hideImportSpreadsheetModal } from 'redux/actions/appActions'
import { createSignsFromSpreadsheetData } from 'redux/actions/editorActions'
import LoadBar from 'components/common/LoadBar'
// import { categoriesSignsByType, sortCategorisedSigns } from 'helpers/signs'
// import { deleteSignsAndSetActive } from 'redux/actions/signsActions'

const ImportSpreadsheetModal = () => {
  const dispatch = useDispatch()

  const [step, setStep] = useState(1)
  const [chosenFile, setChosenFile] = useState(null)
  const [percentage, setPercentage] = useState(0)

  const inputEl = useRef(null)

  const handleInputChange = (e) => {
    let regex = new RegExp('(.*?).(csv)$')

    if (!regex.test(inputEl.current.value.toLowerCase())) {
      inputEl.current.value = ''
      alert('Var vänlig välj en .csv-fil')
    } else {
      let file = inputEl.current.files[0]
      setChosenFile(file)
    }
  }

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const handleFileOnLoad = async (e) => {
    const parsed = await Papa.parse(e.target.result)

    if (parsed.errors.length) {
      alert('Din .csv-fil kunde inte tolkas')
    } else {
      // Chunk it up
      let i,
        j,
        currentChunk,
        chunkAmount = 10
      for (i = 0, j = parsed.data.length; i < j; i += chunkAmount) {
        currentChunk = parsed.data.slice(i, i + chunkAmount)
        dispatch(createSignsFromSpreadsheetData(currentChunk))
        setPercentage((i / parsed.data.length) * 100)
        await timeout(50)
      }

      console.log('🚀 ~ done, todo, set first sign as active')

      setTimeout(() => {
        setStep(4)
      }, 300)
    }
  }

  const readFile = () => {
    setTimeout(() => {
      let reader = new FileReader()
      reader.onload = handleFileOnLoad
      reader.readAsText(chosenFile)
    }, 100)
  }

  return (
    <>
      <Modal
        isActive={step === 1}
        header={'Importera kalkylblad'}
        rejectText={'Avbryt'}
        rejectHandler={() => dispatch(hideImportSpreadsheetModal())}
        acceptText={'Nästa'}
        acceptHandler={() => setStep(2)}
        size={{ maxWidth: '340px' }}
      >
        <p>
          Om du föredrar att skapa dina skyltar i t ex Microsoft Excel, Google
          Kalkylark eller Apples Numbers går det också bra.
        </p>
        <p>
          Ladda bara hem vår mall och fyll i dina uppgifter. När du är klar
          laddar du upp filen och fortsätter med din beställning.
        </p>
      </Modal>

      <Modal
        isActive={step === 2}
        header={'Importera kalkylblad'}
        rejectText={'Avbryt'}
        rejectHandler={() => dispatch(hideImportSpreadsheetModal())}
        acceptText={'Nästa'}
        acceptHandler={() => {
          if (chosenFile) {
            setStep(3)
            readFile()
          } else {
            alert('Var vänlig välj en .csv-fil innan du fortsätter')
          }
        }}
        size={{ maxWidth: '340px' }}
      >
        <p>
          Notera att du behöver spara kalkylbladet i formatet .CSV. Se vårt
          hjälpavsnitt om hur du sparar.
        </p>
        <p>
          <input
            ref={inputEl}
            type={'file'}
            accept={'.csv'}
            onChange={handleInputChange}
          />
        </p>
      </Modal>

      <Modal
        isActive={step === 3}
        // header={''}
        // rejectText={''}
        // rejectHandler={() => {}}
        // acceptText={''}
        // acceptHandler={() => {}}
        size={{ maxWidth: '340px' }}
      >
        <p>Laddar...</p>
        <LoadBar percentage={percentage} />
      </Modal>

      <Modal
        isActive={step === 4}
        header={'Uppladdat och inlagt'}
        rejectText={''}
        rejectHandler={() => dispatch(hideImportSpreadsheetModal())}
        acceptText={'Ok'}
        acceptHandler={() => dispatch(hideImportSpreadsheetModal())}
        size={{ maxWidth: '340px' }}
      >
        <p>
          Nu är dina skyltar inlagda i verktyget och redo att fortsätta att
          bearbetas.
        </p>
        <p>
          Välj t ex tema, justera storleken eller fästmetod. När du är klar går
          du vidare till kassan.
        </p>
      </Modal>
    </>
  )
}

export default ImportSpreadsheetModal
