import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WebFont from 'webfontloader'

import { ResetStyle } from 'styles/base/reset'
import { GlobalStyle } from 'styles/base/globalStyle'

import Editor from 'components/editor/Editor'

import Toast from 'components/common/Toast'
import { removeToast } from 'redux/actions/toastActions'
import {
  fetchCartItems,
  fetchPricesData,
} from 'redux/actions/appActions'
import ShareModal from 'components/editor/ShareModal'
import DeleteSignModal from 'components/editor/DeleteSignModal'
import ImportSpreadsheetModal from 'components/editor/ImportSpreadsheetModal'
import ErrorPopup from 'components/editor/ErrorPopup'
import Checkout from 'components/customer/Checkout'
import { Route, Switch } from 'react-router'
import Confirmation from 'components/customer/Confirmation'
import Order from 'components/customer/Order'
import NewSignModal from 'components/editor/NewSignModal'
import CookieBar from 'components/customer/CookieBar'
import ProceedToCheckoutModal from 'components/editor/ProceedToCheckoutModal'

const App = () => {
  const dispatch = useDispatch()
  const [fontsLoaded, setFontsLoaded] = useState()

  useEffect(() => {
    if (!fontsLoaded) {
      WebFont.load({
        active: () => {
          setFontsLoaded(true)
        },
        google: {
          families: [
            'Inter',
            'Libre Baskerville',
            'Nunito',
            'Roboto Slab',
            'Tomorrow',
          ],
        },
      })
    }
  }, [fontsLoaded])

  const {
    toasts,
    isLoggedIn,
    cartHash,
    isShareModalActive,
    isProceedToCheckoutModalActive,
    isNewSignModalActive,
    isDeleteSignModalActive,
    isImportSpreadsheetModalActive,
    isErrorPopupActive,
    errorPopupMessage,
  } = useSelector((state) => {
    return {
      toasts: state.toasts,
      isLoggedIn: state.app.isLoggedIn,
      cartHash: state.app.cartHash,
      isShareModalActive: state.app.isShareModalActive,
      isProceedToCheckoutModalActive: state.app.isProceedToCheckoutModalActive,
      isNewSignModalActive: state.app.isNewSignModalActive,
      isDeleteSignModalActive: state.app.isDeleteSignModalActive,
      isImportSpreadsheetModalActive: state.app.isImportSpreadsheetModalActive,
      isErrorPopupActive: state.app.isErrorPopupActive,
      errorPopupMessage: state.app.errorPopupMessage,
    }
  })

  // useConnectionStatus()

  useEffect(() => {
    dispatch(fetchCartItems())
  }, [isLoggedIn, cartHash])

  useEffect(() => {
    dispatch(fetchPricesData())
  }, [dispatch])

  if (!fontsLoaded) return null

  return (
    <div className='App'>
      <ResetStyle />
      <GlobalStyle />

      <div id="print-portal" />

      <Switch>
        <Route exact path='/'>
          <Editor />
          {toasts.map((toast, key) => {
            return (
              <Toast
                key={key}
                id={toast.id}
                message={toast.message}
                type={toast.type}
                offset={key}
                clickHandler={() => {
                  dispatch(removeToast(toast.id))
                }}
              />
            )
          })}

          {isNewSignModalActive && <NewSignModal />}
          {isProceedToCheckoutModalActive && <ProceedToCheckoutModal />}
          {isShareModalActive && <ShareModal />}
          {isDeleteSignModalActive && <DeleteSignModal />}
          {isImportSpreadsheetModalActive && <ImportSpreadsheetModal />}
          {isErrorPopupActive && <ErrorPopup message={errorPopupMessage} />}

          {/* {isLoggedIn ? <Redirect to='/dashboard' /> : <PublicHomePage />} */}
        </Route>
        {/* <Login /> */}
        <Route exact path='/checkout/:paymentId?'>
          <Checkout />
        </Route>
        <Route path='/confirmation/:id'>
          <Confirmation />
        </Route>
        <Route path='/order/:id'>
          <Order />
        </Route>
      </Switch>
      <CookieBar />
    </div>
  )
}

export default App
