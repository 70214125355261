import React from 'react'
import StyledForm from './style'

const Form = (props) => {
  return <StyledForm {...props}>{props.children}</StyledForm>
}

Form.Group = (props) => {
  return <StyledForm.Group {...props}>{props.children}</StyledForm.Group>
}

// Add more....

export { Form }