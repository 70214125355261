import React from 'react'
import Button from 'components/common/Button'
import { StyledButtonGroup } from 'components/common/Button/Button.style'
import StyledSegmentedControl from './SegmentedControl.style'

const SegmentedControl = (props) => {
  const { buttons, handleClick } = props
  return (
    <StyledSegmentedControl>
      <StyledButtonGroup>
        {buttons.map((button, key) => {
          const active = button.isActive ? 'active' : ''
          return (
            <Button
              key={key}
              handleButtonClick={() => handleClick(button.type)}
              type={active}
            >
              {button.name}
            </Button>
          )
        })}
      </StyledButtonGroup>
    </StyledSegmentedControl>
  )
}

export default SegmentedControl
