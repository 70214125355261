import React from 'react'
import { SINGLE_STAGE, TABLE_STAGE } from 'constants/Editor'
import StyledHeader from 'components/editor/Header/Header.style'
import { CartDesktop } from 'components/common/Cart'
import Button from 'components/common/Button'
import { StyledButtonGroup } from 'components/common/Button/Button.style'
import Logo from 'components/common/Logo'
import AddToCartButton from 'components/common/AddToCartButton'

const HeaderDesktop = (props) => {
  const {
    signs,
    activeStage,
    handleUndoClick,
    handleRedoClick,
    handleStageChangeClick,
    handleAddNewClick,
    handleDuplicateClick,
    handleDeleteClick,
    activeItems,
  } = props

  const disableUndo = signs.past ? signs.past.length <= 0 : true
  const disableRedo = signs.future ? signs.future.length <= 0 : true

  // const itemsInBulkEdit = editor.bulkEdit.length > 0

  const singleStageActiveClass = activeStage === SINGLE_STAGE ? 'active' : ''
  const tableStageActiveClass = activeStage === TABLE_STAGE ? 'active' : ''

  return (
    <StyledHeader>
      <StyledHeader.Top>
        <StyledHeader.Logo>
          <a href={process.env.REACT_APP_INFOWEB_URL} rel="noreferrer" target='_blank'>
            <Logo />
          </a>
        </StyledHeader.Logo>
        <StyledHeader.TopRight>
          <a href={process.env.REACT_APP_INFOWEB_URL} rel="noreferrer" target="_blank">
            <Button type="plain light">
              Hemsida
            </Button>
          </a>
          <a href={process.env.REACT_APP_INFOWEB_URL + '/support/'} rel="noreferrer" target="_blank">
            <Button type="plain light">
              Kundservice
            </Button>
          </a>
          <CartDesktop />
        </StyledHeader.TopRight>
      </StyledHeader.Top>
      <StyledHeader.Bottom>
        <StyledHeader.Col>
          <Button
            type={'primary-bordered only-icon'}
            handleButtonClick={handleUndoClick}
            disabled={disableUndo}
            icon={'backLeft'}
          />
          <Button
            type={'primary-bordered only-icon'}
            handleButtonClick={handleRedoClick}
            disabled={disableRedo}
            icon={'backRight'}
          />
          <Button
            type={'primary-bordered only-icon light'}
            disabled={!activeItems.length}
            handleButtonClick={() => handleDuplicateClick(activeItems)}
            icon={'duplicate'}
            title='Duplicera skylt eller skyltar'
          >
            Duplicera
          </Button>
          <Button
            type={'primary-bordered only-icon light'}
            disabled={!activeItems.length}
            handleButtonClick={handleDeleteClick}
            icon={'bin'}
          >
            Radera
          </Button>
          <Button
            type={'primary-bordered light'}
            handleButtonClick={handleAddNewClick}
            icon={'addSquare'}
          >
            Ny skylt
          </Button>
          {/* <Button
            type={'primary-bordered light'}
            handleButtonClick={handleShowImportSpreadsheetModalClick}
            icon={'import'}
          >
            Importera kalkylblad
          </Button> */}
          <StyledButtonGroup>
            <Button
              type={'primary-bordered light ' + tableStageActiveClass}
              handleButtonClick={() => handleStageChangeClick(TABLE_STAGE)}
              icon={'tableview'}
            >
              Tabellvy
            </Button>
            <Button
              type={'primary-bordered light ' + singleStageActiveClass}
              handleButtonClick={() => handleStageChangeClick(SINGLE_STAGE)}
              icon={'editorview'}
            >
              Detaljvy
            </Button>
          </StyledButtonGroup>
        </StyledHeader.Col>
        <StyledHeader.Col>
          <AddToCartButton />
        </StyledHeader.Col>
        {/* <StyledHeader.Col>
          <Button
            type={'primary-bordered only-icon'}
            handleButtonClick={handleShowShareModalClick}
            icon={'share'}
          />
        </StyledHeader.Col> */}
      </StyledHeader.Bottom>
    </StyledHeader>
  )
}

export { HeaderDesktop }
