import React from 'react'
import { useSelector } from 'react-redux'
import StyledTable from 'components/common/Table/Table.style'
import StyledSignList from './SignList.style'
import { SIGN_TYPES } from 'constants/Signs'
// import RowContentExpander from './RowContentExpander'
import TextInput from 'components/common/TextInput/index'
import SignTypeIcon from './SignTypeIcon'
import { AddSignRow } from './AddSignRow'

const SignListDesktop = (props) => {
  const {
    categorisedSigns,
    editor,
    activeSign,
    setActiveSign,
    handleInputChange,
    handleCheckboxChange,
    show,
  } = props

  const { focus } = useSelector((state) => {
    return {
      focus: state.editor.focus,
    }
  })

  return (
    <StyledTable show={show}>
      {categorisedSigns.map((category) => {
        const signType = SIGN_TYPES.find(
          (signType) => signType.type === category.type
        )

        return (
          <div key={category.type}>
            <StyledTable.StickyRow>
              <StyledTable.Header width={50}>&nbsp;</StyledTable.Header>
              <StyledTable.Header width={50}>&nbsp;</StyledTable.Header>
              <StyledTable.Header>
                <h1>{signType.name}</h1>
              </StyledTable.Header>
            </StyledTable.StickyRow>

            {/* <RowContentExpander items={category.signs} type={'desktop_sign'}> */}
            {category.signs.map((sign, index) => {
              const isActive = editor.activeItem === sign.values.id
              const isSelected = editor.bulkEdit.indexOf(sign.values.id) >= 0
              const shouldFocus =
                focus.sign && focus.sign.values.id === sign.values.id

              return (
                <StyledTable.Row
                  isActive={isActive}
                  selected={isSelected}
                  onClick={() => setActiveSign(sign)}
                  key={index}
                >
                  <StyledTable.RowSelectBorder
                    isActive={isActive}
                    selected={isSelected}
                    className={'rowSelectBorder'}
                  />
                  <StyledTable.Column
                    width={50}
                    center={true}
                    isActive={isActive}
                  >
                    <input
                      type='checkbox'
                      checked={isSelected}
                      onChange={handleCheckboxChange(
                        sign.values.id,
                        isSelected
                      )}
                    />
                  </StyledTable.Column>
                  <StyledTable.Column
                    width={50}
                    center={true}
                    isActive={isActive}
                  >
                    <StyledTable.RowIcon>
                      <SignTypeIcon sign={sign} />
                    </StyledTable.RowIcon>
                  </StyledTable.Column>
                  <StyledSignList.TextFields>
                    {sign.values.text.map((text, key) => {
                      const focusOnRow =
                        text.rowId === focus.rowId && shouldFocus
                      const lastInRow = key >= sign.values.text.length - 1

                      const {
                        values: { tooWideTextRowIds, textSize },
                      } = sign

                      const getErrorMessage = () => {
                        if (
                          tooWideTextRowIds &&
                          tooWideTextRowIds.includes(text.rowId)
                        ) {
                          return textSize === 'small'
                            ? 'Texten är för lång, förkorta texten'
                            : 'Texten är för lång, gör textstorleken mindre'
                        }

                        return null
                      }

                      return (
                        <StyledTable.Column
                          key={key}
                          isActive={isActive}
                          selected={isSelected}
                          invalid={getErrorMessage()}
                        >
                          <TextInput
                            key={key}
                            focus={focusOnRow}
                            onChange={handleInputChange(activeSign, text.rowId)}
                            value={text.content}
                            errorMessage={getErrorMessage()}
                            inTable
                            lastInRow={lastInRow}
                          />
                        </StyledTable.Column>
                      )
                    })}
                  </StyledSignList.TextFields>
                </StyledTable.Row>
              )
            })}

            {/* </RowContentExpander> */}

            <AddSignRow template={category.signs[category.signs.length - 1]} />
          </div>
        )
      })}
    </StyledTable>
  )
}

export default SignListDesktop
