import React from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'components/common/Modal'
import { hideCart, hideProceedToCheckoutModal } from 'redux/actions/appActions'
import { useHistory } from 'react-router-dom'
import Button from 'components/common/Button'
import StyledProceedToCheckoutModal from './ProceedToCheckoutModal.style'

const ProceedToCheckoutModal = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Modal
      isActive={true}
      header={'Innan du går vidare'}
      rejectHandler={() => {
        dispatch(hideProceedToCheckoutModal())
        dispatch(hideCart())
      }}
      size={{ maxWidth: '290px' }}
    >
      <p>Har du tittat igenom dina skyltar så att allt innehåll fått plats?</p>
      <br />

      <StyledProceedToCheckoutModal.Buttons>
        <Button
          type={'white'}
          handleButtonClick={() => {
            dispatch(hideProceedToCheckoutModal())
            dispatch(hideCart())
          }}
        >
          Nej, tillbaka till verktyget
        </Button>
        <Button
          handleButtonClick={() => {
            dispatch(hideProceedToCheckoutModal())
            dispatch(hideCart())

            history.push(`/checkout`)
          }}
        >
          Ja, gå vidare till kassan
        </Button>
      </StyledProceedToCheckoutModal.Buttons>
    </Modal>
  )
}

export default ProceedToCheckoutModal
