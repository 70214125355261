import {
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_PROP,
  LOGOUT_CUSTOMER,
} from 'redux/actions/customerActions'
import { getData } from 'helpers/storage'

const emptyCustomer = {
  id: 0,
  acceptedMarketing: false,
  couponCode: undefined,
  billingAddress: {
    first_name: '',
    last_name: '',
    address: '',
    company: '',
    postal_code: '',
    city: '',
    email: '',
    phone: '',
  },
  shippingAddress: {
    first_name: '',
    last_name: '',
    address: '',
    company: '',
    postal_code: '',
    city: '',
  }
}

const customer = getData('customer')
const initialState = customer ? customer : emptyCustomer

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_PROP:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case UPDATE_CUSTOMER:
      return {
        ...state,
        ...action.payload,
      }
    case LOGOUT_CUSTOMER:
      return {
        ...state,
        ...emptyCustomer,
      }
    default:
      return state
  }
}

export { customerReducer, initialState }
