import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditorMobileLayout from './EditorMobileLayout'
import EditorDesktopLayout from './EditorDesktopLayout'
import { useResponsive } from 'hooks/useResponsive'
import { TABLE_STAGE, SINGLE_STAGE } from 'constants/Editor'
import { postRemoveCoupons } from 'api/Cart'
import { updateCart } from 'redux/actions/appActions'

const stateSelector = (state) => {
  return {
    activeStage: state.editor.activeStage,
    wcCart: state.cart.wcCart,
  }
}

const Editor = () => {
  const { activeStage, wcCart } = useSelector(stateSelector)
  const dispatch = useDispatch()
  const { breakpoints } = useResponsive()
  const isTableStage = activeStage === TABLE_STAGE
  const isSingleStage = activeStage === SINGLE_STAGE

  useEffect(() => {
    const removeCoupons = async () => {
      if (wcCart?.coupons?.length > 0) {
        const response = await postRemoveCoupons()

        if (response?.cart) {
          dispatch(updateCart(response))
        }
      }
    }
    removeCoupons()
  }, [wcCart?.coupons?.length])

  return (
    <div>
      {!breakpoints.tabletPortraitBelow && (
        <EditorDesktopLayout
          isTableStage={isTableStage}
          isSingleStage={isSingleStage}
        />
      )}
      {breakpoints.tabletPortraitBelow && (
        <EditorMobileLayout
          isTableStage={isTableStage}
          isSingleStage={isSingleStage}
        />
      )}
    </div>
  )
}

export default Editor
