import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { duplicateSigns } from 'redux/actions/signsActions'
import {
  activateBulkEditor,
  deactivateBulkEditor,
} from 'redux/actions/editorActions'
import { showDeleteSignModal } from 'redux/actions/appActions'
import StyledToolbarHeaderMobile from './ToolbarHeaderMobile.style'
import Button from 'components/common/Button'

const ToolbarHeaderMobile = () => {
  const { signs, editor } = useSelector((state) => {
    return {
      signs: state.signs.present.signs,
      editor: state.editor,
    }
  })
  const dispatch = useDispatch()
  const itemsInBulkEdit = editor.bulkEdit.length > 0
  const hasSigns = signs.length > 0

  const handleDuplicateClick = (selectedSigns) => {
    return () => dispatch(duplicateSigns(selectedSigns))
  }

  const handleDeleteClick = () => {
    dispatch(showDeleteSignModal())
  }

  if (!editor.isBulkEditing) {
    return (
      <StyledToolbarHeaderMobile>
        <Button
          type={'plain push-left'}
          disabled={!hasSigns}
          handleButtonClick={() => {
            dispatch(activateBulkEditor())
          }}
        >
          Välj
        </Button>
      </StyledToolbarHeaderMobile>
    )
  }

  return (
    <StyledToolbarHeaderMobile>
      <Button
        type={'plain'}
        disabled={!itemsInBulkEdit}
        handleButtonClick={handleDeleteClick}
      >
        Radera
      </Button>
      <Button
        type={'plain'}
        disabled={!itemsInBulkEdit}
        handleButtonClick={handleDuplicateClick(editor.bulkEdit)}
      >
        Duplicera
      </Button>
      <Button
        type={'plain'}
        handleButtonClick={() => {
          dispatch(deactivateBulkEditor())
        }}
      >
        Klar
      </Button>
    </StyledToolbarHeaderMobile>
  )
}
export { ToolbarHeaderMobile }
