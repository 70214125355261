import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterSignsById } from 'helpers/signs'
import StyledToolbarPane from './ToolbarPane.style'
import { toggleToolbarItemOpen } from 'redux/actions/editorActions'
import PanelVertical from '../Vertical'
import PanelHorizontal from '../Horizontal'
import { useResponsive } from 'hooks/useResponsive'

const ToolbarPane = () => {
  const { breakpoints } = useResponsive()
  const dispatch = useDispatch()
  const { editor, tools, signs } = useSelector((state) => {
    return {
      editor: state.editor,
      tools: state.editor.toolbar,
      signs: state.signs.present.signs,
    }
  })

  const activeSign = signs.filter(filterSignsById(editor.activeItem))[0]

  const handleToolbarClick = (id, closeOthers) => {
    dispatch(toggleToolbarItemOpen(id, closeOthers))
  }

  if (!tools || !activeSign) {
    return <StyledToolbarPane></StyledToolbarPane>
  }

  if (breakpoints.tabletPortraitBelow) {
    return (
      <PanelHorizontal
        editor={editor}
        tools={tools}
        activeSign={activeSign}
        handleToolbarClick={handleToolbarClick}
      />
    )
  }

  return (
    <PanelVertical
      editor={editor}
      tools={tools}
      activeSign={activeSign}
      handleToolbarClick={handleToolbarClick}
    />
  )
}

export default ToolbarPane
