import styled, { css } from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'
import StyledContentExpander from 'components/common/ContentExpander/style'

const StyledPaneVertical = styled.nav`
  background: ${colors.grey01};
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
`

StyledPaneVertical.Panel = styled.div`
  border-bottom: 1px solid ${colors.grey03};
  background: ${colors.grey01};
  color: ${colors.black};

  ${StyledContentExpander} {
    > div:first-child,
    > ul:first-child {
      border-top: 1px solid ${colors.grey03};
    }
  }
`

StyledPaneVertical.PanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.medium};
  cursor: pointer;
`

StyledPaneVertical.PanelContent = styled.div`
  padding: ${spacing.medium};
  //background: ${colors.white};
`

StyledPaneVertical.Summary = styled.div`
  border-top: 1px solid ${colors.grey03};
  background: ${colors.grey01};
  color: ${colors.black};
  padding: ${spacing.medium};
  margin-top: auto;
  display: flex;

  > div {
    &:first-child {
      margin-right: ${spacing.medium};
      flex-shrink: 0;
    }
  }
`

StyledPaneVertical.SummaryText = styled.p`
  ${(props) =>
    props.bold
      ? css`
          ${text.smallBold}
        `
      : css`
          ${text.small}
        `}

  /* > span {
    white-space: nowrap;
  } */
`

export default StyledPaneVertical
