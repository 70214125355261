import { saveAs } from 'file-saver'
import { generateSignPdf } from 'api/Netlify'
import { fetchSingleOrder } from 'api/Order'
import Button from 'components/common/Button'
import { Divider } from 'components/common/Divider'
import { Grid } from 'components/common/Grid'
import { Heading } from 'components/common/Heading'
import Loader from 'components/common/Loader'
import Section from 'components/common/Section'
import SignTypeIcon from 'components/editor/SignList/SignTypeIcon'
import { SIGN_TYPES } from 'constants/Signs'
import { generateExportSVG, renderSignForExport } from 'helpers/fabric'
import {
  cloneSignWithNewType,
  getRenderSize,
  newSignTemplate,
} from 'helpers/signs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import CustomerView from '../CustomerView'
import {
  StyledOrderGrid,
  StyledOrderGridItemHeader,
  StyledOrderGridItem,
} from './style'
import JSZip from 'jszip'
import { signColors } from 'styles/utilities/variables'
import { PrintContainer } from 'components/common/PrintContainer'
import {
  StyledPrintOrder,
  StyledPrintOrderAddress,
  StyledPrintOrderContact,
  StyledPrintOrderInfo,
  StyledPrintOrderProductList,
  StyledPrintOrderTitle,
} from './PrintOrder.style'
import DarkLogo from 'components/common/Logo/DarkLogo'

const Order = () => {
  const { id } = useParams()
  const [order, setOrder] = useState(undefined)
  const [signs, setSigns] = useState([])
  const [customer, setCustomer] = useState(null)
  const [selectedDownloads, setSelectedDownloads] = useState([])
  const wcOrder = order?.wc_order

  const getColorName = (sign, key = 'colorPrimary') =>
    Object.entries(signColors).find(
      ([, value]) => {
        return value === sign.values?.theme?.[key]
      }
    )[0]
  const getFilename = (sign) =>
    `order-${id}_skylt-${sign?.values?.id}_${getColorName(sign)}.pdf`

  /**
   * Get order
   */
  useEffect(() => {
    ;(async () => {
      const orderData = await fetchSingleOrder(id)
      setOrder(orderData)
      setSigns(
        orderData.items.map((item) => {
          const sign = JSON.parse(item.data)
          const newSign = cloneSignWithNewType(newSignTemplate, sign.type)

          return {
            ...newSign,
            values: {
              ...sign,
            },
          }
        })
      )
      setSelectedDownloads(
        orderData.items.reduce(
          (acc, item) => ({ ...acc, [item.id]: false }),
          null,
          {}
        )
      )
      setCustomer(JSON.parse(orderData.customer))
    })()
  }, [id])

  const selectAll = () => {
    const setToSelected = Object.entries(selectedDownloads).every(
      ([, selected]) => selected
    )
    setSelectedDownloads(
      signs.reduce(
        (acc, item) => ({ ...acc, [item.values.id]: !setToSelected }),
        null,
        {}
      )
    )
  }
  const downloadAll = async () => {
    var zip = new JSZip()
    var folder = zip.folder(`order-${id}`)

    signs
      .filter((sign) => selectedDownloads[sign.values.id])
      .map((sign) => folder.file(getFilename(sign), generatePDF(sign)))

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `order-${id}.zip`)
    })
  }

  const downloadPDF = async (sign) => {
    try {
      await generatePDF(sign)
      saveAs(await generatePDF(sign), getFilename(sign))
    } catch (error) {
      console.log('error:', error)
    }
  }

  const generatePDF = async (sign) => {
    try {
      const renderSize = getRenderSize(sign)
      const exportCanvas = renderSignForExport(
        sign,
        renderSize.width,
        renderSize.height
      )
      const svgBlob = generateExportSVG(
        exportCanvas,
        renderSize.width,
        renderSize.height
      )
      return await generateSignPdf(svgBlob)
    } catch (error) {
      console.log('error:', error)
    }
  }

  return (
    <CustomerView>
      <Heading size={1}>Din order</Heading>
      <Divider />

      <Grid col={1}>
        <Section>
          <Heading>Order id: {id}</Heading>
          <Heading>WooCommerce order id: {order?.external_id}</Heading>

          <Divider />
          {signs ? (
            <>
              <span>
                <b>Order total: </b>
                {wcOrder?.total} :-
              </span>
              <Divider />

              <StyledOrderGrid>
                <>
                  <StyledOrderGridItemHeader>
                    <input
                      type={'checkbox'}
                      checked={Object.values(selectedDownloads).every(
                        (selected) => selected
                      )}
                      onChange={selectAll}
                    />
                  </StyledOrderGridItemHeader>
                  <StyledOrderGridItemHeader>Färg</StyledOrderGridItemHeader>
                  <StyledOrderGridItemHeader>Typ</StyledOrderGridItemHeader>
                  <StyledOrderGridItemHeader>
                    Fästmetod(er)
                  </StyledOrderGridItemHeader>
                  <StyledOrderGridItemHeader>Pris</StyledOrderGridItemHeader>
                  <StyledOrderGridItemHeader>Bild</StyledOrderGridItemHeader>
                  <StyledOrderGridItemHeader lastColumn={true}>
                    Actions
                  </StyledOrderGridItemHeader>

                  {signs.map((sign, key) => {
                    const { type } = sign.values
                    const signType = SIGN_TYPES.find(
                      (signType) => signType.type === type
                    )
                    return (
                      <React.Fragment key={key}>
                        <StyledOrderGridItem firstColumn={true}>
                          <input
                            type={'checkbox'}
                            checked={
                              selectedDownloads?.[sign?.values?.id]
                                ? selectedDownloads?.[sign?.values?.id]
                                : false
                            }
                            onChange={(event) =>
                              setSelectedDownloads({
                                ...selectedDownloads,
                                [sign.values.id]: event.target.checked,
                              })
                            }
                          />
                        </StyledOrderGridItem>
                        <StyledOrderGridItem>
                          {getColorName(sign)} -{' '}
                          {getColorName(sign, 'colorSecondary')}
                        </StyledOrderGridItem>
                        <StyledOrderGridItem>
                          {signType.name}
                        </StyledOrderGridItem>
                        <StyledOrderGridItem>
                          {sign.values.addOnProducts.map((addOn, index) => (
                            <span key={index}>
                              {addOn.name}, {addOn.total}:-
                              {index < sign.values.addOnProducts.length - 1 &&
                                '  +'}
                              &nbsp;&nbsp;
                            </span>
                          ))}
                        </StyledOrderGridItem>
                        <StyledOrderGridItem>
                          <p>{sign.values.price.total}:-</p>
                        </StyledOrderGridItem>
                        <StyledOrderGridItem>
                          <div style={{ width: '40px' }}>
                            {sign.values.image ? (
                              <a
                                download={`skylt-${sign.values.id}`}
                                href={sign.values.image}
                              >
                                <img src={sign.values.image} alt={''} />
                              </a>
                            ) : (
                              <SignTypeIcon sign={sign} />
                            )}
                          </div>
                        </StyledOrderGridItem>
                        <StyledOrderGridItem lastColumn={true}>
                          <Button handleButtonClick={() => downloadPDF(sign)}>
                            Ladda ner print PDF
                          </Button>
                        </StyledOrderGridItem>
                      </React.Fragment>
                    )
                  })}
                </>
              </StyledOrderGrid>

              <Divider />
              <Button
                handleButtonClick={downloadAll}
                disabled={Object.values(selectedDownloads).every(
                  (selected) => !selected
                )}
              >
                Ladda ner markerade
              </Button>
            </>
          ) : (
            <Loader />
          )}
        </Section>

        <Section>
          <Heading>Kunduppgifter: </Heading>
          <Divider />
          {customer ? (
            <>
              <b>Namn:</b> {customer.billingAddress.first_name}{' '}
              {customer.billingAddress.last_name}
              <Divider size={'xSmall'} />
              <b>E-post:</b> {customer.billingAddress.email}
              <Divider size={'xSmall'} />
              <b>Telefonnummer:</b> {customer.billingAddress.phone}
              <Divider size={'small'} />
              <b>Gatuaddress:</b> {customer.billingAddress.address}
              <Divider size={'xSmall'} />
              <b>Postkod:</b> {customer.billingAddress.postal_code}
              <Divider size={'xSmall'} />
              <b>Stad:</b> {customer.billingAddress.city}
              <Divider size={'xSmall'} />
              <b>Företag:</b> {customer.billingAddress.company}
              <Divider size={'xSmall'} />
              {customer?.shippingAddress?.address !== null && (
                  <>
                    <Divider />
                    <Heading>Leveransadress: </Heading>
                    <Divider size={'xSmall'} />
                    <b>Förnamn:</b> {customer.shippingAddress.first_name}
                    <Divider size={'xSmall'} />
                    <b>Efternamn:</b> {customer.shippingAddress.last_name}
                    <Divider size={'xSmall'} />
                    <b>Gatuaddress:</b> {customer.shippingAddress.address}
                    <Divider size={'xSmall'} />
                    <b>Postkod:</b> {customer.shippingAddress.postal_code}
                    <Divider size={'xSmall'} />
                    <b>Stad:</b> {customer.shippingAddress.city}
                    <Divider size={'xSmall'} />
                    <b>Företag:</b> {customer.shippingAddress.company}
                    <Divider size={'xSmall'} />
                  </>
                )}
            </>
          ) : (
            <Loader />
          )}
        </Section>
      </Grid>

      <PrintContainer>
        <StyledPrintOrder>
          <DarkLogo />

          <StyledPrintOrderTitle>
            <Heading size={1}>Följesedel</Heading>
          </StyledPrintOrderTitle>
          <div>
            <p>Order ID: {order?.id}</p>
            <p>Orderdatum: {order?.created_at.split(' ')[0]}</p>
            <p>WooCommerce order id: {order?.external_id}</p>
          </div>

          <StyledPrintOrderAddress>
            {customer && (
              <>
                <div>
                  <b><p>Faktureringssadress</p></b>

                  <p>
                    {customer.billingAddress.first_name}{' '}
                    {customer.billingAddress.last_name}
                  </p>
                  <p>{customer.billingAddress.email}</p>
                  <p>{customer.billingAddress.phone}</p>
                  <p>{customer.billingAddress.address}</p>
                  <p>{customer.billingAddress.postal_code} {customer.billingAddress.city}</p>
                  <p>{customer.billingAddress.company}</p>
                </div>

                <div>
                  {customer.shippingAddress.address !== null && (
                    <>
                      <b><p>Leveransadress</p></b>
                      <p>
                        {customer.shippingAddress.first_name}{' '}
                        {customer.shippingAddress.last_name}
                      </p>
                      <p>{customer.shippingAddress.address}</p>
                      <p>{customer.shippingAddress.postal_code} {customer.shippingAddress.city}</p>
                      <p>{customer.shippingAddress.company}</p>
                    </>
                  )}
                </div>
              </>
            )}
          </StyledPrintOrderAddress>

          <StyledPrintOrderInfo>
            <Heading size={4}>Tomolo Skyltar AB</Heading>
            <p>Gamla Kronvägen 12</p>
            <p>433 33 Partille</p>
            <p>Org-nr: 559203-1297</p>
            <p>www.tomolo.se</p>
            <p>info@tomolo.se</p>
          </StyledPrintOrderInfo>

          <StyledPrintOrderProductList>
            <thead>
              <tr>
                <th scope='col'>St</th>
                <th scope='col'>Färg</th>
                <th scope='col'>Produkt</th>
                <th scope='col'>Text</th>
              </tr>
            </thead>

            <tbody>
              {signs.map((sign, key) => {
                const { type, text } = sign.values
                const signType = SIGN_TYPES.find(
                  (signType) => signType.type === type
                )
                const signText = text?.map(({ content }) => content).join(', ')

                return (
                  <React.Fragment key={key}>
                    <tr>
                      <th scope='row'>1</th>
                      <td>
                        {getColorName(sign)} -{' '}
                        {getColorName(sign, 'colorSecondary')}
                      </td>
                      <td>{signType.name}</td>
                      <td>{signText}</td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </StyledPrintOrderProductList>

          <StyledPrintOrderContact>
            <Heading size={4}>Kundtjänst</Heading>
            <p>
              Kontrollera att du fått rätt varor. Om uppgifterna inte stämmer så
              var vänlig och kontakta vår kundtjänst.
            </p>
            <p>info@tomolo.se</p>
          </StyledPrintOrderContact>
        </StyledPrintOrder>
      </PrintContainer>
    </CustomerView>
  )
}

export default Order
