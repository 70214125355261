import { useState, useEffect, useCallback, useRef } from 'react'

const useDivElementSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 })
  const ref = useRef(null)
  const setRef = useCallback(node => {
    if (node) {
      setSize({
        width: node.getBoundingClientRect().width,
        height: node.getBoundingClientRect().height,
      })
    }

    ref.current = node
  }, [])

  const getSize = useCallback(() => {
    return {
      width: ref.current.getBoundingClientRect().width,
      height: ref.current.getBoundingClientRect().height,
    }
  }, [ref])

  useEffect(() => {
    if (ref && ref.current) {
      const newSize = getSize()

      if (size.width !== newSize.width && size.height !== newSize.height) {
        setSize(newSize)
      }
    }
  }, [size.width, size.height, ref, getSize])

  useEffect(() => {
    const handleResize = () => {
      if (ref && ref.current) {
        const size = getSize()
        setSize(size)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref, getSize])

  return [setRef, size]
}

export { useDivElementSize }
