import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

const StyledSection = styled.section`
  padding: 3rem;
  background: ${colors.white};
  margin-bottom: ${spacing.large};
  height: 100%;
`

export default StyledSection
