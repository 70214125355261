import styled from 'styled-components'
import { spacing } from 'styles/utilities/variables'

const StyledDropdown = styled.div`
  position: relative;
`

StyledDropdown.Input = styled.input`
  display: block;
  margin: ${spacing.small} 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`

StyledDropdown.Icon = styled.div`
  position: absolute;
  top: 0.3rem;
  right: 0.6rem;
  pointer-events: none;

  svg {
    stroke-width: 2;
  }
`

export { StyledDropdown }
