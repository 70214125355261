import { css } from 'styled-components'

import { typography, colors } from './variables'

const textBody = css`
  font-family: ${typography.primaryFont};
  font-size: ${typography.sizes.base};
  line-height: ${typography.lineHeight};
  font-weight: initial;
`

const textBodyMobile = css`
  font-size: ${typography.sizes.xLarge};
  line-height: 106%;
  letter-spacing: 0.01em;
`

const textBodyBold = css`
  ${textBody}
  font-weight: bold;
`

const textBodyTableFigures = css`
  ${textBody}
  font-feature-settings: 'tnum' on, 'lnum' on;
`

const textSmall = css`
  font-family: ${typography.primaryFont};
  font-size: ${typography.sizes.small};
  line-height: ${typography.lineHeight};
  font-weight: initial;
`
const textSmallBold = css`
  ${textSmall}
  font-weight: bold;
`

const textLink = css`
  font-family: ${typography.primaryFont};
  font-size: ${typography.sizes.base};
  line-height: ${typography.lineHeight};
  color: ${colors.tealDark};
`
// const smallTabular = css`
//   font-family: ${typography.primaryFont};
//   font-size: ${typography.sizes.small};
//   line-height: 133%;
//   font-feature-settings: 'tnum' on, 'lnum' on;
// `

const bodyTabularMobile = css`
  font-family: ${typography.primaryFont};
  font-size: 1.7rem;
  line-height: 106%;
  font-feature-settings: 'tnum' on, 'lnum' on;
`

export const text = {
  body: textBody,
  bodyTabularMobile: bodyTabularMobile,
  bodyMobile: textBodyMobile,
  bodyBold: textBodyBold,
  bodyTableFigures: textBodyTableFigures,
  small: textSmall,
  smallBold: textSmallBold,
  link: textLink
}
