export const NETLIFY_BASE = '/.netlify/functions'

export const generateSignPdf = async (svgBlob) => {
  const response = await fetch(`${NETLIFY_BASE}/generate-pdf`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: svgBlob,
  })

  return await response.blob()
}