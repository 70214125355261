import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setActiveEditorItem } from 'redux/actions/editorActions'
import {
  cloneSignWithNewText,
  filterSignsById,
  categoriesSignsByType,
  sortCategorisedSigns,
} from 'helpers/signs'
import { setSignText } from 'redux/actions/signsActions'
import { SINGLE_STAGE, EType } from 'constants/Editor'
import {
  addToBulkEdit,
  removeFromBulkEdit,
  setActiveStage,
} from 'redux/actions/editorActions'

import SignListDesktop from './SignListDesktop'
import SignListMobile from './SignListMobile'
import SignListAside from './SignListAside'

const delimiterText = (textArr) => {
  const text = textArr.reduce((acc, text, index) => {
    let added = text.content.length > 0 ? `${acc} · ${text.content}` : `${acc}`
    return index === 0 ? `${text.content}` : added
  }, '')

  return text
}

const capitalizeMounting = (mountingArr) => {
  const mounting = mountingArr.reduce((acc, mounting) => {
    const capitalizedString =
      mounting.type.charAt(0).toUpperCase() + mounting.type.slice(1)
    return `${acc} ${capitalizedString}`
  }, '')

  return mounting
}

const SignList = (props) => {
  const { type, show } = props
  const dispatch = useDispatch()
  const { signs, editor } = useSelector((state) => {
    return {
      editor: state.editor,
      signs: state.signs.present.signs,
    }
  })
  const activeSign = signs.filter(filterSignsById(editor.activeItem))[0]
  const categorisedSigns = categoriesSignsByType(signs)
  const sortedCategories = sortCategorisedSigns(categorisedSigns)

  const setActiveSign = (sign) => {
    dispatch(setActiveEditorItem(sign))
  }

  const goToEditor = () => {
    dispatch(setActiveStage(SINGLE_STAGE))
  }

  const handleInputChange = (activeSign, textId) => {
    return (event) => {
      const newSign = cloneSignWithNewText(
        activeSign,
        event.target.value,
        textId
      )

      dispatch(setSignText(newSign))
    }
  }

  const handleCheckboxChange = (id, isSelected) => {
    return () => {
      if (isSelected) {
        dispatch(removeFromBulkEdit(id))
      } else {
        dispatch(addToBulkEdit(id))
      }
    }
  }

  if (type === EType.desktop) {
    return (
      <SignListDesktop
        signs={signs}
        categorisedSigns={sortedCategories}
        editor={editor}
        activeSign={activeSign}
        setActiveSign={setActiveSign}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
        show={show}
      />
    )
  } else if (type === EType.mobile) {
    return (
      <SignListMobile
        signs={signs}
        categorisedSigns={sortedCategories}
        editor={editor}
        activeSign={activeSign}
        setActiveSign={setActiveSign}
        goToEditor={goToEditor}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
        delimiterText={delimiterText}
        capitalizeMounting={capitalizeMounting}
        show={show}
      />
    )
  } else if (type === EType.aside) {
    return (
      <SignListAside
        signs={signs}
        categorisedSigns={sortedCategories}
        editor={editor}
        activeSign={activeSign}
        setActiveSign={setActiveSign}
        goToEditor={goToEditor}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
        delimiterText={delimiterText}
        capitalizeMounting={capitalizeMounting}
        show={show}
      />
    )
  } else {
    return null
  }
}

export { SignList }
