import { fetchSingleOrder as apiFetchSingleOrder } from 'api/Order'

export const FETCH_SINGLE_ORDER = 'FETCH_SINGLE_ORDER'
export const fetchSingleOrder = (id) => {
  return async (dispatch) => {
    apiFetchSingleOrder(id)
      .then((data) => {
        dispatch(setOrder(data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const SET_ORDER = 'SET_ORDER'
export const setOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER,
      payload: order,
    })
  }
}
