import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleToolbarItemTab } from 'redux/actions/editorActions'
import StyledToolbarTabs from './ToolbarTabs.style'

const ToolbarTabs = (props) => {
  const { tool } = props
  const dispatch = useDispatch()

  if (!tool.tabs) return null

  return (
    <StyledToolbarTabs>
      {tool.tabs.map((tab, index) => {
        return (
          <StyledToolbarTabs.Tab
            key={index}
            isActive={tab.isActive}
            onClick={() => {
              dispatch(toggleToolbarItemTab(tab.id))
            }}
          >
            {tab.text}
          </StyledToolbarTabs.Tab>
        )
      })}
    </StyledToolbarTabs>
  )
}

export default ToolbarTabs
