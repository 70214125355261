import { deepClone, generateTempId } from './helpers'
import { squareCircle } from 'helpers/geometry'
import {
  MOUNTING_TYPES,
  SIGN_EXPORT_SCALE,
  SIGN_TYPES,
  THEMES,
} from 'constants/Signs'

const getRenderSize = (sign) => {
  return {
    width: sign.values.size.width * SIGN_EXPORT_SCALE,
    height: sign.values.size.height * SIGN_EXPORT_SCALE,
  }
}

const filterThemeByType = (type) => {
  return (theme) => {
    return theme.type === type
  }
}

const signTheme = (theme) => {
  return {
    ...THEMES.find(filterThemeByType(theme)),
  }
}

const signMountingRequiresHole = (mounting) => {
  return ['nyckelring', '2-skruvhal', '4-skruvhal'].includes(mounting)
}

const newSignTemplate = {
  values: {
    id: 'TEMPLATE',
    type: SIGN_TYPES[2].type, // Märkskylt
    theme: THEMES[0],
    text: [
      {
        rowId: 0,
        content: '',
      },
      {
        rowId: 1,
        content: '',
      },
      {
        rowId: 2,
        content: '',
      },
      {
        rowId: 3,
        content: '',
      },
      {
        rowId: 4,
        content: '',
      },
    ],
    tooWideTextRowIds: [],
    align: 'center',
    textSize: 'medium',
    size: {
      type: 'fixed',
      width: 300,
      height: 150,
    },
    shape: 'rect',
    mounting: [],
    price: { price: 0, tax: 0, total: 0 },
    addOnProducts: [],
  },

  settings: {
    fontSizes: {
      small: 16,
      medium: 20,
      large: 24,
    },
    plate: {
      shapeChoices: [],
      cornerRadius: 8,
      margin: 10,
      screwHoleOffset: 0,
    },
    sizeFixed: true, // Overrides both sizeCustomAllowed and sizeChoices if set to true
    sizeCustomAllowed: false,
    sizeChoices: [],
    mountingChoices: [
      MOUNTING_TYPES[1], // 2 skruvhål
      MOUNTING_TYPES[3], // Tejp
      MOUNTING_TYPES[7], // Ingen
    ],
  },
}

const signType = (type, sign = null) => {
  switch (type) {
    case SIGN_TYPES[0].type: // Nyckelbricka rund
      return {
        values: {
          text: [sign.values.text[0]],
          size: {
            type: 'fixed',
            width: 25,
            height: 25,
          },
          shape: 'circle',
          image: null,
        },

        settings: {
          fontSizes: {
            small: 12,
            medium: 20,
            large: 32,
          },
          plate: {
            shapeChoices: ['circle'],
            cornerRadius: 0,
            screwHoleRadius: 0,
            screwHoleOffset: 0,
            keyHoleRadius: 6,
            keyHoleOffset: 10,
            padding: 10,
          },
          sizeFixed: true, // Overrides both sizeCustomAllowed and sizeChoices if set to true
          sizeCustomAllowed: false,
          sizeChoices: [
            {
              type: 'fixed',
              width: 25,
              height: 25,
            },
          ],
          mountingChoices: [
            MOUNTING_TYPES[0], // Nyckelring (1 skruvhål)
            MOUNTING_TYPES[7], // Ingen
          ],
        },
      }
    case SIGN_TYPES[1].type: // Nyckelbricka rektangulär
      return {
        values: {
          text: [...sign.values.text.slice(0, 2)],
          size: {
            type: 'fixed',
            width: 45,
            height: 25,
          },
          shape: 'rect',
          image: null,
        },

        settings: {
          fontSizes: {
            small: 12,
            medium: 16,
            large: 24,
          },
          plate: {
            shapeChoices: ['rect'],
            cornerRadius: 8,
            screwHoleRadius: 0,
            screwHoleOffset: 0,
            keyHoleRadius: 6,
            keyHoleOffset: 10,
            padding: 10,
          },
          sizeFixed: false, // Overrides both sizeCustomAllowed and sizeChoices if set to true
          sizeCustomAllowed: false,
          sizeChoices: [
            {
              type: 'fixed',
              width: 45,
              height: 25,
            },
            {
              type: 'fixed',
              width: 50,
              height: 20,
            },
          ],
          mountingChoices: [
            MOUNTING_TYPES[0], // Nyckelring (1 skruvhål)
            MOUNTING_TYPES[7], // Ingen
          ],
        },
      }
    // case SIGN_TYPES[2].type: // Namnbricka - oval
    //   return {
    //     values: {
    //       text: [...sign.values.text.slice(0, 3)],
    //       size: {
    //         type: 'fixed',
    //         width: 59,
    //         height: 34,
    //       },
    //       shape: 'ellipse',
    //       image: null,
    //     },

    //     settings: {
    //       plate: {
    //         shapeChoices: ['ellipse'],
    //         cornerRadius: 8,
    //         screwHoleRadius: 0,
    //         screwHoleOffset: 0,
    //         keyHoleRadius: 0,
    //         keyHoleOffset: 0,
    //         padding: 10,
    //       },
    //       sizeFixed: false, // Overrides both sizeCustomAllowed and sizeChoices if set to true
    //       sizeCustomAllowed: false,
    //       sizeChoices: [
    //         {
    //           type: 'fixed',
    //           width: 59,
    //           height: 34,
    //         },
    //       ],
    //       mountingChoices: [
    //         MOUNTING_TYPES[5], // Magnet
    //         MOUNTING_TYPES[6], // Nål
    //         MOUNTING_TYPES[7], // Ingen
    //       ],
    //     },
    //   }
    // case SIGN_TYPES[3].type: // Namnbricka, rektangulär
    //   return {
    //     values: {
    //       text: [...sign.values.text.slice(0, 3)],
    //       size: {
    //         type: 'fixed',
    //         width: 60,
    //         height: 30,
    //       },
    //       shape: 'rect',
    //       image: null,
    //     },

    //     settings: {
    //       plate: {
    //         shapeChoices: ['rect'],
    //         cornerRadius: 8,
    //         screwHoleRadius: 0,
    //         screwHoleOffset: 0,
    //         keyHoleRadius: 0,
    //         keyHoleOffset: 0,
    //         padding: 10,
    //       },
    //       sizeFixed: false, // Overrides both sizeCustomAllowed and sizeChoices if set to true
    //       sizeCustomAllowed: false,
    //       sizeChoices: [
    //         {
    //           type: 'fixed',
    //           width: 60,
    //           height: 30,
    //         },
    //         {
    //           type: 'fixed',
    //           width: 70,
    //           height: 30,
    //         },
    //       ],
    //       mountingChoices: [
    //         MOUNTING_TYPES[5], // Magnet
    //         MOUNTING_TYPES[6], // Nål
    //         MOUNTING_TYPES[7], // Ingen
    //       ],
    //     },
    //   }
    // case SIGN_TYPES[4].type: // Namnbricka med militärklämma
    //   return {
    //     values: {
    //       text: [...sign.values.text.slice(0, 3)],
    //       size: {
    //         type: 'fixed',
    //         width: 80,
    //         height: 25,
    //       },
    //       shape: 'rect',
    //       image: null,
    //     },

    //     settings: {
    //       plate: {
    //         shapeChoices: ['rect'],
    //         cornerRadius: 8,
    //         screwHoleRadius: 0,
    //         screwHoleOffset: 0,
    //         keyHoleRadius: 0,
    //         keyHoleOffset: 0,
    //         padding: 10,
    //       },
    //       sizeFixed: true, // Overrides both sizeCustomAllowed and sizeChoices if set to true
    //       sizeCustomAllowed: false,
    //       sizeChoices: [
    //         {
    //           type: 'fixed',
    //           width: 80,
    //           height: 25,
    //         },
    //       ],
    //       mountingChoices: [
    //         MOUNTING_TYPES[4], // Militärklämma
    //         MOUNTING_TYPES[7], // Ingen
    //       ],
    //     },
    //   }
    // case SIGN_TYPES[5].type: // Märkskylt
    //   return {
    //     values: {
    //       text: [...sign.values.text],
    //       size: {
    //         type: 'fixed',
    //         width: 150,
    //         height: 100,
    //       },
    //       shape: 'rect',
    //       image: null,
    //     },

    //     settings: {
    //       fontSizes: {
    //         small: 22,
    //         medium: 28,
    //         large: 34,
    //       },
    //       plate: {
    //         shapeChoices: ['rect'],
    //         cornerRadius: 8,
    //         screwHoleRadius: 6,
    //         screwHoleOffset: 17,
    //         keyHoleRadius: 0,
    //         keyHoleOffset: 0,
    //         padding: 20,
    //       },
    //       sizeFixed: true, // Overrides both sizeCustomAllowed and sizeChoices if set to true
    //       sizeCustomAllowed: false,
    //       sizeChoices: [
    //         {
    //           type: 'fixed',
    //           width: 150,
    //           height: 100,
    //         },
    //       ],
    //       mountingChoices: [
    //         MOUNTING_TYPES[1], // 2 skruvhål
    //         MOUNTING_TYPES[2], // 4 skruvhål
    //         MOUNTING_TYPES[3], // Tejp
    //         MOUNTING_TYPES[7], // Ingen
    //       ],
    //     },
    //   }
    case SIGN_TYPES[2].type: // Brevlådeskylt
      return {
        values: {
          text: [...sign.values.text.slice(0, 3)],
          size: {
            type: 'fixed',
            width: 80,
            height: 30,
          },
          shape: 'rect',
          image: null,
        },

        settings: {
          plate: {
            shapeChoices: ['rect'],
            cornerRadius: 8,
            screwHoleRadius: 6,
            screwHoleOffset: 14,
            keyHoleRadius: 0,
            keyHoleOffset: 0,
            padding: 10,
          },
          sizeFixed: false, // Overrides both sizeCustomAllowed and sizeChoices if set to true
          sizeCustomAllowed: false,
          sizeChoices: [
            {
              type: 'fixed',
              width: 80,
              height: 30,
            },
            {
              type: 'fixed',
              width: 100,
              height: 40,
            },
            {
              type: 'fixed',
              width: 110,
              height: 50,
            },
          ],
          mountingChoices: [
            MOUNTING_TYPES[1], // 2 skruvhål
            MOUNTING_TYPES[3], // Tejp
            MOUNTING_TYPES[7], // Ingen
          ],
        },
      }
    // case SIGN_TYPES[4].type: // Generell skylt
    //   return {
    //     values: {
    //       text: [...sign.values.text],
    //       size: {
    //         type: 'custom',
    //         width: 450,
    //         height: 250,
    //       },
    //       shape: 'rect',
    //       image: null,
    //     },

    //     settings: {
    //       plate: {
    //         shapeChoices: ['rect'],
    //         cornerRadius: 8,
    //         screwHoleOffset: 10,
    //       },
    //       sizeFixed: false, // Overrides both sizeCustomAllowed and sizeChoices if set to true
    //       sizeCustomAllowed: true,
    //       sizeChoices: [],
    //       mountingChoices: [
    //         // signMounting(MOUNTING_TYPES[1].type), // 2 hål m. skruv
    //         // signMounting(MOUNTING_TYPES[2].type), // 4 hål m. skruv
    //         // signMounting(MOUNTING_TYPES[3].type), // Självhäftande
    //         // signMounting(MOUNTING_TYPES[5].type), // Magnet
    //         // signMounting(MOUNTING_TYPES[7].type), // Ingen
    //         MOUNTING_TYPES[1], // 2 skruvhål
    //         MOUNTING_TYPES[2], // 4 skruvhål
    //         MOUNTING_TYPES[3], // Tejp
    //         MOUNTING_TYPES[5], // Magnet
    //         MOUNTING_TYPES[7], // Ingen
    //       ],
    //     },
    //   }
    default:
      return signType(SIGN_TYPES[0].key)
  }
}

const categoriesSignsByType = (signs) => {
  return signs.reduce((acc, sign) => {
    const found = acc.findIndex((cat) => cat.type === sign.values.type)
    const index = found >= 0 ? found : acc.length
    if (!acc[index]) {
      acc[index] = { type: sign.values.type, signs: [] }
    }
    acc[index].signs.push(sign)
    return acc
  }, [])
}

const sortCategorisedSigns = (categorisedSigns) => {
  return [...categorisedSigns].sort(function compare(a, b) {
    const typeA = a.type.toLowerCase()
    const typeB = b.type.toLowerCase()
    if (typeA < typeB) {
      return -1
    }
    if (typeA > typeB) {
      return 1
    }
    return 0
  })
}

const cloneSignWithNewText = (sign, text, textId) => {
  const clone = {
    ...sign,
    values: {
      ...sign.values,
      text: sign.values.text.map((textItem) => {
        if (textId !== textItem.rowId) {
          return textItem
        }
        return {
          ...textItem,
          content: text,
        }
      }),
    },
  }
  return clone
}

const cloneSignWithNewSize = (sign, size, prices = null) => {
  const clone = deepClone(sign)

  // Right size
  const updatedSign = {
    ...clone,
    values: {
      ...clone.values,
      size: size,
    },
  }

  const price = getSignPrice(updatedSign, prices)

  // Return with right price
  return {
    ...updatedSign,
    values: {
      ...updatedSign.values,
      price: price,
    },
  }
}

const cloneSignWithNewType = (sign, type, prices = null) => {
  const typeOverrides = signType(type, sign)
  const clone = {
    ...sign,
    values: {
      ...sign.values,
      type: type,
      ...typeOverrides.values,
      text: [...typeOverrides.values.text],
      size: {
        ...sign.values.size,
        ...typeOverrides.values.size,
      },
    },

    settings: {
      ...sign.settings,
      ...typeOverrides.settings,
      plate: {
        ...sign.settings.plate,
        ...typeOverrides.settings.plate,
      },
      sizeFixed: typeOverrides.settings.sizeFixed,
      sizeCustomAllowed: typeOverrides.settings.sizeCustomAllowed,
      sizeChoices: [...typeOverrides.settings.sizeChoices],
      mountingChoices: [...typeOverrides.settings.mountingChoices],
    },
  }

  const price = getSignPrice(clone, prices)

  return {
    ...clone,
    values: {
      ...clone.values,
      price: price,
    },
  }
}

const clearText = (textRow) => {
  return {
    ...textRow,
    content: '',
  }
}

const clearTextRows = (sign) => {
  return {
    ...sign,
    values: {
      ...sign.values,
      text: sign.values.text.map(clearText),
    },
  }
}

const filterBySignIds = (ids) => {
  return (sign) => ids.indexOf(sign.values.id) >= 0
}

const addTempIdToSigns = (sign) => {
  return {
    ...sign,
    values: {
      ...sign.values,
      id: generateTempId([]),
    },
  }
}

const duplicateSignInList = (ids, list) => {
  const targets = list.filter(filterBySignIds(ids))
  const newSigns = targets.map(addTempIdToSigns).map((sign) => {
    delete sign.item_id
    return sign
  })
  return [...list, ...newSigns]
}

const duplicateSignInListWithoutText = (ids, list) => {
  const targets = list.filter(filterBySignIds(ids))
  const newSigns = targets.map(addTempIdToSigns).map(clearTextRows)
  return [...list, ...newSigns]
}

const duplicateSign = (template) => {
  const duplicate = deepClone(template)
  duplicate.values.id = generateTempId([])
  return duplicate
}

const calculateSignScale = ({
  containerWidth,
  containerHeight,
  signWidth,
  signHeight,
}) => {
  const scale = Math.min(
    containerWidth / signWidth,
    containerHeight / signHeight
  )

  return scale
}

const filterSignsById = (id) => {
  return (sign) => {
    return sign.values.id === id
  }
}

const signInnerDimensions = (sign) => {
  const { width, height } = sign.values.size
  const { padding } = sign.settings.plate
  const shape = sign.values.shape

  if (shape === 'circle') {
    return {
      width: squareCircle(width),
      height: squareCircle(width),
    }
  }

  return {
    width: width - padding * 2,
    height: height - padding * 2,
  }
}

/**
 * Get sign price
 * @param {*} sign
 * @returns
 */
export const getSignPrice = (sign, prices = null) => {
  if (!prices) return sign.values.price

  const {
    values: {
      type,
      size: { width: signWidth, height: signHeight, type: signSizeType },
    },
  } = sign

  const priceNode = prices[type] ? prices[type] : null

  if (!priceNode) return sign.values.price

  let signPrice = 0
  let signTax = 0
  let signTotal = 0
  let signPriceId = 0

  if (priceNode) {
    // Base price
    signPrice = priceNode.price
    signTax = priceNode.tax
    signTotal = priceNode.total
    signPriceId = priceNode.id

    // Variations?
    if (priceNode.variations && priceNode.variations.length) {
      // Calc variation price...
      priceNode.variations.forEach((variation) => {
        const variationWidth = parseInt(variation.dimensions.width)
        const variationHeight = parseInt(variation.dimensions.height)

        if (signSizeType === 'fixed') {
          if (signHeight === variationHeight && signWidth === variationWidth) {
            signTotal = variation.total
            signTax = variation.tax
            signPrice = variation.price
            signPriceId = variation.id
          }
        } else {
          // @TODO, right now picking the highest price below
          // Adjust later on?
          if (
            (signHeight >= variationHeight || signWidth >= variationWidth) &&
            variation.total > signTotal
          ) {
            signTotal = variation.total
            signTax = variation.tax
            signPrice = variation.price
            signPriceId = variation.id
          }
        }
      })
    }
  }

  return {
    price: signPrice,
    tax: signTax,
    total: signTotal,
    id: signPriceId,
  }
}

/**
 * Get sign addon products total price
 * @param {*} sign
 * @returns
 */
export const getSignAddonProductsPrice = (sign) => {
  const {
    values: { addOnProducts },
  } = sign

  if (addOnProducts && addOnProducts.length > 0) {
    return addOnProducts.reduce((acc, addOnProduct) => {
      return (acc += addOnProduct.total)
    }, 0)
  }

  return 0
}

export {
  getRenderSize,
  signTheme,
  signMountingRequiresHole,
  signType,
  signInnerDimensions,
  newSignTemplate,
  cloneSignWithNewType,
  cloneSignWithNewText,
  cloneSignWithNewSize,
  duplicateSignInList,
  duplicateSignInListWithoutText,
  duplicateSign,
  calculateSignScale,
  filterSignsById,
  categoriesSignsByType,
  sortCategorisedSigns,
}
