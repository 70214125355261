import { Form } from 'components/common/Form'
import { Grid } from 'components/common/Grid'
import TextInput from 'components/common/TextInput'
import { useResponsive } from 'hooks/useResponsive'
import { useEffect, useState } from 'react'
import * as yup from 'yup'

let validation = {
  shipping: yup.boolean(),
  first_name: yup.string().required('Du måste fylla i förnamn'),
  last_name: yup.string().required('Du måste fylla i efternamn'),
  address: yup.string().required('Du måste fylla i en adress'),
  company: yup.string(),
  postal_code: yup
    .number()
    .typeError('Du måste fylla i ett giltigt postnummer')
    .required('Du måste fylla i ett postnummer'),
  city: yup.string().required('Du måste fylla i en stad'),
  email: yup
    .string()
    .email('Du måste fylla i en giltig e-post')
    .when('shipping', {
      is: undefined,
      then: yup.string().required('Du måste fylla i en e-post'),
    }),
  phone: yup.string().when('shipping', {
    is: undefined,
    then: yup
      .string()
      .min(8, 'Fyll i ett giltigt telefonnummer telefonummer')
      .max(12, 'Fyll i ett giltigt telefonnummer telefonummer')
      .required('Du måste fylla i ett telefonnummer'),
  }),
}

const CustomerAddressForm = ({
  address: customerAddress,
  handleChange,
  shipping,
  onValid,
}) => {
  const { breakpoints } = useResponsive()
  const [errors, setErrors] = useState({})
  const {
    first_name,
    last_name,
    address,
    company,
    postal_code,
    city,
    email,
    phone,
  } = customerAddress
  const validateField = (field, value) => {
    validation[field]
      .validate(value)
      .then(setErrors({ ...errors, [field]: undefined }))
      .catch((error) => setErrors({ ...errors, [field]: error.errors }))
  }
  const validateAll = () => {
    try {
      return !!yup
        .object(validation)
        .validateSync({ ...customerAddress, shipping })
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    onValid(validateAll())
  }, [customerAddress])

  return (
    <>
      <Grid
        col={breakpoints.mobileDown ? 1 : 2}
        rowGap={breakpoints.mobileDown ? 'none' : undefined}
      >
        <Form.Group>
          <label>Förnamn</label>
          <TextInput
            defaultValue={first_name}
            errorMessage={errors.first_name && errors.first_name[0]}
            onBlur={() => validateField('first_name', first_name)}
            onChange={(e) => {
              validateField('first_name', e.target.value)
              handleChange('first_name', e.target.value)
            }}
          />
        </Form.Group>
        <Form.Group>
          <label>Efternamn</label>
          <TextInput
            defaultValue={last_name}
            errorMessage={errors.last_name && errors.last_name[0]}
            onBlur={() => validateField('last_name', last_name)}
            onChange={(e) => {
              validateField('last_name', e.target.value)
              handleChange('last_name', e.target.value)
            }}
          />
        </Form.Group>
      </Grid>
      <Grid
        col={breakpoints.mobileDown ? 1 : 2}
        rowGap={breakpoints.mobileDown ? 'none' : undefined}
      >
        <Form.Group>
          <label>Adress</label>
          <TextInput
            defaultValue={address}
            errorMessage={errors.address && errors.address[0]}
            onBlur={() => validateField('address', address)}
            onChange={(e) => {
              validateField('address', e.target.value)
              handleChange('address', e.target.value)
            }}
          />
        </Form.Group>
        <Form.Group>
          <label>Företagsnamn (valfritt)</label>
          <TextInput
            defaultValue={company}
            errorMessage={errors.company && errors.company[0]}
            onBlur={() => validateField('company', company)}
            onChange={(e) => {
              validateField('company', e.target.value)
              handleChange('company', e.target.value)
            }}
          />
        </Form.Group>
      </Grid>

      <Grid
        col={breakpoints.mobileDown ? 1 : 2}
        rowGap={breakpoints.mobileDown ? 'none' : undefined}
      >
        <Form.Group>
          <label>Postnummer</label>
          <TextInput
            defaultValue={postal_code}
            errorMessage={errors.postal_code && errors.postal_code[0]}
            onBlur={() => validateField('postal_code', postal_code)}
            onChange={(e) => {
              validateField('postal_code', e.target.value)
              handleChange('postal_code', e.target.value)
            }}
          />
        </Form.Group>
        <Form.Group>
          <label>Stad</label>
          <TextInput
            defaultValue={city}
            errorMessage={errors.city && errors.city[0]}
            onBlur={() => validateField('city', city)}
            onChange={(e) => {
              validateField('city', e.target.value)
              handleChange('city', e.target.value)
            }}
          />
        </Form.Group>
      </Grid>
      {!shipping && (
        <>
          <Grid
            col={breakpoints.mobileDown ? 1 : 2}
            rowGap={breakpoints.mobileDown ? 'none' : undefined}
          >
            <Form.Group>
              <label>E-postadress</label>
              <TextInput
                defaultValue={email}
                errorMessage={errors.email && errors.email[0]}
                onBlur={() => validateField('email', email)}
                onChange={(e) => {
                  validateField('email', e.target.value)
                  handleChange('email', e.target.value)
                }}
                hideErrorOnFocus
              />
            </Form.Group>
            <Form.Group>
              <label>Telefonnummer</label>
              <TextInput
                defaultValue={phone}
                errorMessage={errors.phone && errors.phone[0]}
                onBlur={() => validateField('phone', phone)}
                onChange={(e) => {
                  validateField('phone', e.target.value)
                  handleChange('phone', e.target.value)
                }}
                hideErrorOnFocus
              />
            </Form.Group>
          </Grid>
        </>
      )}
    </>
  )
}

export default CustomerAddressForm
