import styled from 'styled-components'

const StyledStage = styled.div`
  //transform: scale(${({ scale }) => scale});
  .canvas-container {
    margin: 0 auto;
  }
  canvas {
    //border-radius: ${({ borderRadius }) => borderRadius};
    //box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.35);
  }
`

export default StyledStage
