import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'

const StyledEmptyListMessage = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  font-size: 2.2rem;
  color: ${colors.grey05};
  text-align: center;
  padding: ${spacing.medium};
  min-height: 80vh;
`

StyledEmptyListMessage.Title = styled.h2`
  width: 100%;
  ${text.bodyBold}
  font-size: inherit;
  margin-bottom: ${spacing.small};
`

StyledEmptyListMessage.Message = styled.p`
  width: 100%;
`

export default StyledEmptyListMessage
