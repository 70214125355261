import styled, { css } from "styled-components"
import {
  colors,
  spacing,
  radius,
  mq,
} from "styles/utilities/variables"

const StyledForm = styled.form`
  max-width: 350px;
  width:100%;
  ${props => props.center && css`
    max-width: 350px;
    margin: 0 auto;
  `}

  a {
    color:${colors.alertRed};
  }
`


StyledForm.Group = styled.div`
  margin-bottom: ${spacing.large};
  input {
      margin-bottom:0;
  }
  @media ${mq.mobileDown} {
    margin-bottom: ${spacing.medium};
    &:first-child:not(:last-child) {
      margin-bottom:0;
    }
  }
  ${props => props.hidden && css`
      display:none;
  `}

`


StyledForm.Section = styled.div`
  background: ${colors.lightestGrey};
  padding: ${spacing.large} ${spacing.xxLarge};
  border-radius: ${radius.medium};
  margin-bottom: ${spacing.large};
  z-index: ${props => props.top ? 1 : 0};
  position: relative;
  @media ${mq.tabletPortraitBelow} {
    padding: ${spacing.large} ${spacing.large};
  }
  @media ${mq.mobileDown} {
    padding: ${spacing.large} ${spacing.medium};
    width: calc(100vw);
    transform: translateX(-${spacing.medium});
    border-radius:0;
  }
`

export default StyledForm