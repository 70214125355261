import styled from 'styled-components'
// import { animated } from 'react-spring/renderprops'

const StyledSignList = styled.section`
  min-height: 80vh;
  overflow-y: scroll;
`

// StyledSignList.ExpandWrapper = styled(animated.div)`
//   overflow: hidden;
// `

StyledSignList.TextFields = styled.div`
  overflow: scroll;
  position: relative;
  display: flex;

  & > div {
    flex-grow: 1;
    min-width: 150px;
  }
`

export default StyledSignList
