import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toggleSignMounting } from 'redux/actions/signsActions'
import StyledMountingPanel from './MountingPanel.style'
import { MOUNTING_TYPES } from 'constants/Signs'

import StyledOptionButtonList from 'components/common/OptionButton/OptionButtonList.style'
import OptionButton from 'components/common/OptionButton/OptionButton'
import Icon from 'components/common/Icon'

const MountingPanel = (props) => {
  // const { panel, activeSign, activeTab } = props
  const { activeSign } = props
  const dispatch = useDispatch()
  const { prices } = useSelector((state) => {
    return {
      prices: state.app.prices,
    }
  })

  const activeMountingTypes =
    activeSign && activeSign.values.mounting ? activeSign.values.mounting : []

  const mountingChoices = MOUNTING_TYPES.filter((mounting) => {
    let mountingChoicesStrings = activeSign.settings.mountingChoices.map(
      (choice) => choice.type
    )
    return mountingChoicesStrings.indexOf(mounting.type) > -1
  })

  const options = mountingChoices.map((option) => {
    return {
      ...option,
      isActive: activeMountingTypes.reduce((acc, item) => {
        if (option.type === item.type) {
          acc = true
        }
        return acc
      }, false),
      clickCallback: () => {
        dispatch(toggleSignMounting(activeSign, option))
      },
    }
  })

  if (!activeSign) {
    return null
  }

  return (
    <StyledMountingPanel>
      <StyledMountingPanel.Actions>
        <StyledOptionButtonList>
          {options.map((option, key) => {
            const priceNode = prices[option.type] ? prices[option.type] : null
            return (
              <OptionButton
                key={key}
                isActive={option.isActive}
                name={option.name}
                additionalText={priceNode ? `${priceNode.total} kr` : null}
                clickCallback={option.clickCallback}
              >
                <Icon name={option.icon} />
              </OptionButton>
            )
          })}
        </StyledOptionButtonList>
      </StyledMountingPanel.Actions>
    </StyledMountingPanel>
  )
}

export default MountingPanel
