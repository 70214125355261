import { css } from 'styled-components'
import { colors, mq, spacing, typography } from 'styles/utilities/variables'

export const formStyles = css`
  input[type='number'],
  textarea,
  input[type='text'] {
    background: ${colors.white};
    border: 1px solid ${colors.grey03};
    border-radius: 0.3rem;
    padding: ${spacing.xSmall} ${spacing.small};
    width: 100%;
    font-size: ${typography.sizes.medium};

    @media ${mq.tablet} {
      font-size: ${typography.sizes.large};
    }
  }

  ::-webkit-input-placeholder {
    color: ${colors.grey04};
  }

  :-ms-input-placeholder {
    color: ${colors.grey04};
  }

  ::placeholder {
    color: ${colors.grey04};
  }

  // Perhaps move into select-component later on

  select {
    width: 100%;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: 1.2rem ${spacing.small};
    outline: none;
    background: ${colors.white};
    border: 1px solid ${colors.grey03};
    border-radius: 0.3rem;
    background-image: url('data:image/svg+xml;utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="%23222222"><path d="M6 10L12 16L18 10"></path></svg>');
    background-repeat: no-repeat;
    background-position: center right 1rem;
    background-size: 1.8rem;

    &:focus {
    }
  }

  select::-ms-expand {
    display: none; // Hide the default arrow in ie10 and ie11
  }
`
