import styled from 'styled-components'
import { colors, spacing, radius } from 'styles/utilities/variables'

const StyledDropdownList = styled.ul`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: -${spacing.xSmall};
  transform: translate(0, 100%);
  background: white;
  border-radius: ${radius.small};
  margin: 0;
  padding: ${spacing.small} 0;
  list-style: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  color: ${colors.black};
  border: 1px solid ${colors.grey04};

  width: ${({ width }) => (width ? width : '100%')};

  li {
    button {
      padding: 0.6rem ${spacing.medium};
      text-decoration: none;
      color: ${colors.black};
      background: ${colors.white};

      //Button resets
      width: 100%;
      border: 0;
      text-align: left;

      &:focus,
      &:active {
        color: ${colors.black};
        outline: 0;
      }

      &:hover {
        color: ${colors.black};
        background: ${colors.grey02};
      }
    }
  }
`

// const StyledButton = styled.button`
//   ${text.bodyBold}
//   border: none;
//   border-radius: ${radius.small};
//   padding: ${spacing.xSmall} ${spacing.small};
//   margin: 0 5px 0 0;
//   min-height: 3rem;

//   &.primary {
//     background: ${colors.primary};
//     color: white;
//   }
//   &.white {
//     color: ${colors.primary};
//     background: white;
//   }
//   &.primary-bordered {
//     background: ${colors.blueLight};
//     color: white;
//     border: 1px solid ${colors.blueDark};

//     &:hover,
//     &.active {
//       background: ${colors.blueDark};

//       &:disabled {
//         background: ${colors.blueLight};
//       }
//     }
//   }

//   &.plain {
//     border: 0;
//     background: transparent;
//     color: inherit;
//   }

//   &.only-icon {
//     border: 0;
//     background: transparent;
//     color: inherit;
//     padding: 0;
//     margin: 0;

//     .svg-wrapper {
//       margin: 0;
//     }

//     &.primary-bordered {
//       padding: ${spacing.xSmall} ${spacing.small};
//       margin: 0 5px 0 0;
//       background: ${colors.blueLight};
//       color: white;
//       border: 1px solid ${colors.blueDark};

//       &:hover,
//       &.active {
//         background: ${colors.blueDark};

//         &:disabled {
//           background: ${colors.blueLight};
//         }
//       }
//     }
//   }

//   &.light {
//     ${text.body}
//   }
//   &:focus {
//     outline: none;
//   }

//   &.push-left {
//     margin-left: auto;
//   }

//   &.push-right {
//     margin-right: auto;
//   }

//   &:disabled {
//     opacity: 0.5;
//   }
// `

// StyledButton.Inner = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .svg-wrapper {
//     margin-right: ${spacing.small};
//   }

//   div:last-child {
//     .svg-wrapper {
//       margin-right: 0;
//     }
//   }
// `

// StyledButton.Text = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-right: ${spacing.xSmall};
// `

// const StyledButtonGroup = styled.div`
//   margin: 0 5px 0 0;

//   .button {
//     margin: 0;
//     border-radius: 0;

//     &:first-child {
//       border-top-left-radius: ${radius.small};
//       border-bottom-left-radius: ${radius.small};
//     }
//     &:last-child {
//       border-top-right-radius: ${radius.small};
//       border-bottom-right-radius: ${radius.small};
//     }
//   }
// `

export { StyledDropdownList }
