const STORAGE_PREFIX = 'skylt-'
export const STORAGE_KEY_TOKEN = 'token'

export const setData = (key, data) => {
  return localStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(data))
}
export const removeData = (key) => {
  return localStorage.removeItem(STORAGE_PREFIX + key)
}
export const getData = (key) => {
  const data = localStorage.getItem(STORAGE_PREFIX + key)
  if (!data) return null
  return JSON.parse(data || '')
}

export const setToken = (token) => {
  setData(STORAGE_KEY_TOKEN, token)
}

export const removeToken = () => {
  removeData(STORAGE_KEY_TOKEN)
}
export const getToken = () => {
  return getData(STORAGE_KEY_TOKEN)
}
