import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'

const StyledSegmentedControl = styled.div`
  .button {
    ${text.small}
    background: transparent;
    color: ${colors.black};
    border: 1px solid ${colors.grey04};
    min-width: 8rem;
    min-height: ${spacing.large};

    &.active {
      background: ${colors.primary};
      color: ${colors.white};
      border-color: ${colors.primary};
    }
  }
`

export default StyledSegmentedControl
