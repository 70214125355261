import React from 'react'

const icons = {
  square: (theme) => {
    return `<rect y="5" x="1" width="22" height="15" rx="1" fill="${theme.colorPrimary}" stroke="${theme.colorSecondary}" stroke-width="2" stroke-opacity="0.3" paint-order="stroke"/>
      <path opacity="0.5" d="M4 11H20" stroke="${theme.colorSecondary}" stroke-width="3"/>
      <path opacity="0.5" d="M4 15H20" stroke="${theme.colorSecondary}"/>`
  },
  circle: (theme) => {
    return `<circle cx="12" cy="12" r="9" fill="${theme.colorPrimary}" stroke="${theme.colorSecondary}" stroke-width="2" stroke-opacity="0.3" paint-order="stroke"/>
      <path opacity="0.5" d="M6 12H18" stroke="${theme.colorSecondary}" stroke-width="3"/>`
  },
  ellipse: (theme) => {
    return `<ellipse cx="12" cy="12" rx="11" ry="8" fill="${theme.colorPrimary}" stroke="${theme.colorSecondary}" stroke-width="2" stroke-opacity="0.3" paint-order="stroke"/>
      <path opacity="0.5" d="M6 12H18" stroke="${theme.colorSecondary}" stroke-width="3"/>`
  },
}

const SignTypeIcon = (props) => {
  const { sign } = props
  const iconContent = () => {
    let icon = ''

    switch (sign.values.shape) {
      case 'rect':
        icon = icons['square'](sign.values.theme)
        break
      case 'circle':
        icon = icons['circle'](sign.values.theme)
        break
      case 'ellipse':
        icon = icons['ellipse'](sign.values.theme)
        break
      default:
        icon = icons['square'](sign.values.theme)
    }

    return icon
  }

  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      dangerouslySetInnerHTML={{ __html: iconContent() }}
    />
  )
}

export default SignTypeIcon
