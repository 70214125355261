import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { compose } from 'redux'
import Modal from 'components/common/Modal'
import Button from 'components/common/Button'
import { hideShareModal } from 'redux/actions/appActions'
import StyledShareModal from './ShareModal.style'

const closeModal = (dispatch) => {
  return function reject() {
    dispatch(hideShareModal())
  }
}

const copyToClipboard = (inputEl) => {
  return function copy() {
    inputEl.current.focus()
    inputEl.current.select()
    document.execCommand('copy')
  }
}

const ShareModal = () => {
  const dispatch = useDispatch()
  const [copied, setCopied] = useState(false)
  const inputEl = useRef(null)
  const showSuccessMessage = () => setCopied(true)

  const handleCopyClick = compose(showSuccessMessage, copyToClipboard(inputEl))

  const handleRejectClick = closeModal(dispatch)
  const shareUrl = 'http://www.lixon-skyltar.se/234gkldfökg'

  return (
    <StyledShareModal>
      <Modal isActive={true} header={'Dela'} rejectHandler={handleRejectClick}>
        <div>
          <p>
            Med hjälp av delalänken kan mottagaren titta men inte ändra på
            projektet.
          </p>
          <p>
            <input ref={inputEl} type='text' value={shareUrl} readOnly={true} />
          </p>
          <StyledShareModal.Actions>
            <Button type={'plain'} handleButtonClick={closeModal(dispatch)}>
              Avbryt
            </Button>
            <Button handleButtonClick={handleCopyClick}>Kopiera länk</Button>
            <StyledShareModal.SuccessMessage>
              {copied && <span>Länken är kopierad!</span>}
            </StyledShareModal.SuccessMessage>
          </StyledShareModal.Actions>
        </div>
      </Modal>
    </StyledShareModal>
  )
}

export default ShareModal
