import styled from 'styled-components'
import { colors, mq } from 'styles/utilities/variables'

const StyledConfirmation = styled.div`
  padding: 3rem 0;
  background: ${colors.grey02};
  flex-grow: 1;
`
export default StyledConfirmation

export const StyledOrderInfo = styled.div`
  display: flex;
  justify-content: start;
  max-width: 100%;
  @media (min-width: ${mq.mobileDown}px) {
    max-width: 50%;
  }
`

export const StyledOrderInfoItem = styled.div`
  flex-grow: 1;
`

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`
