import {
  UPDATE_OFFLINE_STATE,
  SHOW_SHARE_MODAL,
  HIDE_SHARE_MODAL,
  SHOW_NEW_SIGN_MODAL,
  HIDE_NEW_SIGN_MODAL,
  SHOW_DELETE_SIGN_MODAL,
  HIDE_DELETE_SIGN_MODAL,
  SHOW_IMPORT_SPREADSHEET_MODAL,
  HIDE_IMPORT_SPREADSHEET_MODAL,
  SET_ERROR_POPUP_MESSAGE,
  SHOW_ERROR_POPUP,
  HIDE_ERROR_POPUP,
  SHOW_CART,
  HIDE_CART,
  UPDATE_CART_HASH,
  UPDATE_PRICES,
  SHOW_PROCEED_TO_CHECKOUT_MODAL,
  HIDE_PROCEED_TO_CHECKOUT_MODAL,
} from 'redux/actions/appActions'
import {
  SET_CUSTOMER_TOKEN,
  REMOVE_CUSTOMER_TOKEN,
  LOGOUT_CUSTOMER,
  LOGIN_CUSTOMER,
} from 'redux/actions/customerActions'
import { getData, getToken } from 'helpers/storage'
import Cookies from 'js-cookie'

const customer = getData('customer')
const cartHash = getData('cart-hash')
const token = getToken()

const initialState = {
  isOffline: false,
  isShareModalActive: false,
  isNewSignModalActive: !Cookies.get('stage_type'),
  isProceedToCheckoutModalActive: false,
  isDeleteSignModalActive: false,
  isImportSpreadsheetModalActive: false,
  isErrorPopupActive: false,
  errorPopupMessage: 'Lorem Ipsum',
  isCartActive: false,
  isLoggedIn: customer ? true : false,
  token: token ? token : '',
  cartHash: cartHash ? cartHash : 0,
  prices: [],
  shipping: [],
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OFFLINE_STATE:
      return {
        ...state,
        isOffline: action.payload,
      }
    case UPDATE_CART_HASH:
      return {
        ...state,
        cartHash: action.payload,
      }
    case SHOW_SHARE_MODAL:
      return {
        ...state,
        isShareModalActive: true,
      }
    case HIDE_SHARE_MODAL:
      return {
        ...state,
        isShareModalActive: false,
      }
    case UPDATE_PRICES:
      return {
        ...state,
        prices: action.payload,
      }
    case SHOW_NEW_SIGN_MODAL:
      return {
        ...state,
        isNewSignModalActive: true,
      }
    case HIDE_NEW_SIGN_MODAL:
      return {
        ...state,
        isNewSignModalActive: false,
      }
    case SHOW_PROCEED_TO_CHECKOUT_MODAL:
      return {
        ...state,
        isProceedToCheckoutModalActive: true,
      }
    case HIDE_PROCEED_TO_CHECKOUT_MODAL:
      return {
        ...state,
        isProceedToCheckoutModalActive: false,
      }
    case SHOW_DELETE_SIGN_MODAL:
      return {
        ...state,
        isDeleteSignModalActive: true,
      }
    case HIDE_DELETE_SIGN_MODAL:
      return {
        ...state,
        isDeleteSignModalActive: false,
      }
    case SHOW_IMPORT_SPREADSHEET_MODAL:
      return {
        ...state,
        isImportSpreadsheetModalActive: true,
      }
    case HIDE_IMPORT_SPREADSHEET_MODAL:
      return {
        ...state,
        isImportSpreadsheetModalActive: false,
      }
    case SET_ERROR_POPUP_MESSAGE:
      return {
        ...state,
        errorPopupMessage: action.payload,
      }
    case SHOW_ERROR_POPUP:
      return {
        ...state,
        isErrorPopupActive: true,
      }
    case HIDE_ERROR_POPUP:
      return {
        ...state,
        isErrorPopupActive: false,
      }
    case SHOW_CART:
      return {
        ...state,
        isCartActive: true,
      }
    case HIDE_CART:
      return {
        ...state,
        isCartActive: false,
      }
    case LOGIN_CUSTOMER:
      return {
        ...state,
        isLoggedIn: true,
      }
    case LOGOUT_CUSTOMER:
      return {
        ...state,
        isLoggedIn: false,
      }
    case SET_CUSTOMER_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case REMOVE_CUSTOMER_TOKEN:
      return {
        ...state,
        token: '',
      }
    default:
      return state
  }
}

export { appReducer, initialState }
