import React from 'react'
import StyledSingleView from './SignSingleView.style'
import { Canvas } from 'components/editor/Sign'
import {
  calculateSignScale,
  filterSignsById,
  getRenderSize,
} from 'helpers/signs'
import { useDivElementSize } from 'hooks/useDivElementSize'
import { useDispatch } from 'react-redux'
import { setActiveStage } from 'redux/actions/editorActions'
import { TABLE_STAGE } from 'constants/Editor'
import { useResponsive } from 'hooks/useResponsive'
import Loader from 'components/common/Loader'

const SignSingleView = (props) => {
  const { signs, editor } = props
  const { breakpoints } = useResponsive()
  const dispatch = useDispatch()
  const [divRef, stageSize] = useDivElementSize()
  const activeSign = signs.filter(filterSignsById(editor.activeItem))[0]
  const padding = breakpoints.mobileDown ? 5 : 20
  const maxStageWidth = breakpoints.mobileDown ? 250 : 350
  const maxStageHeight = breakpoints.mobileDown ? 250 : 350

  if (!activeSign) {
    if (breakpoints.mobileDown && signs.length === 0) {
      dispatch(setActiveStage(TABLE_STAGE))
    }

    return (<StyledSingleView><Loader /></StyledSingleView>)
  }

  const renderSize = getRenderSize(activeSign)

  const signScale = calculateSignScale({
    containerWidth: Math.min(stageSize.width, maxStageWidth),
    containerHeight: Math.min(stageSize.height, maxStageHeight),
    signWidth: activeSign && renderSize.width + padding,
    signHeight: activeSign && renderSize.height + padding,
  })

  const canvasWidth = Math.max(
    renderSize.width + padding,
    Math.min(stageSize.width, maxStageWidth)
  )
  const canvasHeight = Math.max(
    renderSize.height + padding,
    Math.min(stageSize.height, maxStageHeight)
  )

  return (
    <StyledSingleView>
      <StyledSingleView.Inner ref={divRef}>
        {divRef && (
          <Canvas
            canvasId={'SignSingleViewCanvas'}
            sign={activeSign}
            scale={signScale}
            width={canvasWidth}
            height={canvasHeight}
            padding={padding}
          />
        )}
      </StyledSingleView.Inner>
    </StyledSingleView>
  )
}

export default SignSingleView
