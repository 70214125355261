import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'

const StyledButtonRound = styled.button`
  ${text.bodyBold}
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-height: 3rem;
  background: ${colors.secondary};
  color: ${colors.white};
`

export { StyledButtonRound }
