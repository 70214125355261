import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

const StyledEditor = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  background: ${colors.grey02};
  overflow: hidden;
  height: 100vh;
`

StyledEditor.Header = styled.div`
  width: 100%;
`
StyledEditor.Toolbar = styled.div`
  width: 100%;
  height: ${spacing.xLarge};
  overflow: hidden;
`

StyledEditor.SplitLayout = styled.div`
  display: flex;
  width: 100%;
`

StyledEditor.Stage = styled.div`
  width: 100%;
  height: calc(100vh - 96px);
  overflow: scroll;
`

StyledEditor.Aside = styled.div`
  min-width: ${({ width }) => (width ? width + 'rem' : '29rem')};
  max-width: ${({ width }) => (width ? width + 'rem' : '29rem')};
  // min-width: 29rem;
  // max-width: 29rem;
  height: calc(100vh - 96px);
  overflow: scroll;
  border-left: ${props =>
    props.right ? `1px solid ${colors.grey03}` : 'none'};
  border-right: ${props =>
    props.left ? `1px solid ${colors.grey03}` : 'none'};
  background: ${colors.grey01};

  display: ${props => (props.show ? 'block' : 'none')};
`

export default StyledEditor
