import React from 'react'
import { useDispatch } from 'react-redux'

import { setSignShape } from 'redux/actions/signsActions'
import StyledShapePanel from './ShapePanel.style'
import { SHAPE_TYPES } from 'constants/Signs'

import StyledOptionButtonList from 'components/common/OptionButton/OptionButtonList.style'
import OptionButton from 'components/common/OptionButton/OptionButton'
import Icon from 'components/common/Icon'

const ShapePanel = (props) => {
  // const { panel, activeSign, activeTab } = props
  const { activeSign } = props
  const dispatch = useDispatch()
  const activeShape =
    activeSign && activeSign.values.shape ? activeSign.values.shape : ''

  const shapeChoices = SHAPE_TYPES.filter((shape) => {
    return activeSign.settings.plate.shapeChoices.indexOf(shape.type) > -1
  })

  const options = shapeChoices.map((option) => {
    return {
      ...option,
      isActive: option.type === activeShape,
      clickCallback: () => {
        dispatch(setSignShape(activeSign, option.type))
      },
    }
  })

  if (!activeSign) {
    return null
  }

  return (
    <StyledShapePanel>
      <StyledShapePanel.Actions>
        <StyledOptionButtonList>
          {options.map((option, key) => {
            return (
              <OptionButton
                key={key}
                isActive={option.isActive}
                name={option.name}
                clickCallback={option.clickCallback}
              >
                <Icon
                  name={option.icon}
                  width={option.iconWidth}
                  height={option.iconHeight}
                />
              </OptionButton>
            )
          })}
        </StyledOptionButtonList>
      </StyledShapePanel.Actions>
    </StyledShapePanel>
  )
}

export default ShapePanel
