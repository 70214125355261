import styled from 'styled-components'
import { spacing, radius } from 'styles/utilities/variables'

const StyledModal = styled.div`
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

StyledModal.Modal = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: white;
  max-width: 85vw;
  width: auto;
  max-height: 90vh;
  overflow: auto;
  padding: ${(props) => (props.padding ? props.padding : spacing.large)};
  border-radius: ${radius.small};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  line-height: 1.3;

  width: ${(props) =>
    props.size && props.size.width ? `${props.size.width}vw` : 'auto'};
  height: ${(props) =>
    props.size && props.size.height ? `${props.size.height}vh` : 'auto'};
  max-height: ${(props) =>
    props.size && props.size.maxHeight ? props.size.maxHeight : 'auto'};
  max-width: ${(props) =>
    props.size && props.size.maxWidth ? props.size.maxWidth : 'auto'};
`

StyledModal.Header = styled.div`
  font-weight: bold;
  //text-align: center;
  margin-bottom: ${spacing.large};
`
StyledModal.Content = styled.div`
  //margin-bottom: ${spacing.xLarge};
  margin: auto 0;
  height: ${(props) => (props.height ? props.height : 'initial')};
`
StyledModal.Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing.medium};

  button + button {
    margin-left: ${spacing.small};
  }
`

export default StyledModal
