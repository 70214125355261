import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { media } from 'styles/utilities/functions'

const StyledToolbarHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 0 ${spacing.medium};
  min-height: ${spacing.xLarge};
  background: ${colors.grey01};
  border-bottom: 1px solid ${colors.grey03};

  ${media.tabletPortraitBelow`
    padding: 0;
  `}
`

export default StyledToolbarHeader
