import styled from 'styled-components'
import { spacing, colors, radius } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'
import StyledIcon from '../Icon/Icon.style'
import { media } from 'styles/utilities/functions'

const StyledOptionButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
`

StyledOptionButton.Checkmark = styled.div`
  position: absolute;
  content: '';
  top: 4px;
  right: 4px;

  ${media.tabletPortraitBelow`
    right: 8px;
  `}

  ${StyledIcon} svg {
    stroke: unset !important;
  }
`

StyledOptionButton.Children = styled.div`
  background: ${({ isActive, transparent }) => (isActive || transparent ? 'transparent' : colors.white)};
  border: 1px solid ${(props) => (props.isActive ? colors.blueMid : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.small};
  height: 72px;
  width: 72px;
`

StyledOptionButton.Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primary};
  width: ${spacing.xLarge};
  height: ${spacing.xLarge};
  border-radius: 50%;
  color: ${colors.white};
`

StyledOptionButton.Text = styled.div`
  ${text.small}
  width: 100%;
  text-align: center;
  margin-top: ${spacing.small};
  white-space: pre-wrap;
  line-height: 1.3;
`

export default StyledOptionButton
