import { StyledButton } from 'components/common/Button/Button.style'
import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'

export const StyledCookieBar = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 300px;
  left: 16px;
  bottom: 16px;
  border-radius: 4px;
  padding: 24px;
  background-color: ${colors.primary};
  color: ${colors.white};
  font-size: 16px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

  ${StyledButton} {
    margin-right: unset;
    height: 49px;
  }
`
