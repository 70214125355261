import React from 'react'
import { StyledSelect } from './Select.style'

const Select = (props) => {
  const { options, currentValue, handleOptionChange } = props
  return (
    <StyledSelect
      value={currentValue}
      onChange={(e) => handleOptionChange(e.target.value)}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  )
}

export default Select
