import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

const StyledIconLink  = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    color: ${colors.blueDark}
  }
  .svg-wrapper {
    margin-right: ${spacing.small};
    color: ${colors.black};
  }
`

export default StyledIconLink
