import { getData } from 'helpers/storage'
import { API_BASE } from './Api'
import { headers } from './Customer'

const PAYMENT_ENDPOINT = 'payments'

const checkStatus = (response) => {
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}

export const createPayment = async (customer) => {
  const cartHash = getData('cart-hash')
  return await await fetch(`${API_BASE}/${PAYMENT_ENDPOINT}/${cartHash}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
    body: JSON.stringify({ customer })
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const fetchPayment = async (paymentId) => {
  return await await fetch(`${API_BASE}/${PAYMENT_ENDPOINT}/${paymentId}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export const fetchCurrentPaymentStatus = async (paymentId) => {
  return await await fetch(`${API_BASE}/${PAYMENT_ENDPOINT}/current-payment`, {
    body: JSON.stringify({
      id: paymentId,
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}
