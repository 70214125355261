import React from 'react'
import { useDispatch } from 'react-redux'

import StyledThemePanel from './ThemePanel.style'
import {
  setSignTheme,
  setCustomSignColors,
  setCustomSignFont,
} from 'redux/actions/signsActions'
import { useResponsive } from 'hooks/useResponsive'
import { THEMES, COLOR_COMBINATIONS, FONTS } from 'constants/Signs'
import { ColorPickerList } from 'components/common/ColorPicker'
import Dropdown from 'components/common/Dropdown'
import Select from 'components/common/Select'
import ThemeBadgeList from 'components/common/ThemeBadge/ThemeBadgeList'

const ThemePanel = (props) => {
  // const { panel, activeSign, activeTab } = props
  const { activeSign, activeTab } = props
  const dispatch = useDispatch()
  const { breakpoints } = useResponsive()

  const activeThemeType =
    activeSign && activeSign.values.theme.type
      ? activeSign.values.theme.type
      : ''

  const themeOptions = THEMES.map((option) => {
    return {
      ...option,
      isActive: activeThemeType === option.type,
      clickCallback: () => {
        dispatch(setSignTheme(activeSign, option.type))
      },
    }
  })

  const activeThemeColorPrimary =
    activeSign && activeSign.values.theme.colorPrimary
      ? activeSign.values.theme.colorPrimary
      : ''
  const activeThemeColorSecondary =
    activeSign && activeSign.values.theme.colorSecondary
      ? activeSign.values.theme.colorSecondary
      : ''

  const colorOptions = COLOR_COMBINATIONS.map((option) => {
    return {
      ...option,
      isActive:
        activeThemeColorPrimary === option.colorPrimary &&
        activeThemeColorSecondary === option.colorSecondary,
      clickCallback: () => {
        dispatch(
          setCustomSignColors(
            activeSign,
            option.colorPrimary,
            option.colorSecondary
          )
        )
      },
    }
  })

  const activeThemeFont =
    activeSign && activeSign.values.theme.fontFamily
      ? activeSign.values.theme.fontFamily
      : ''

  const fontOptions = FONTS.map((font) => {
    return {
      label: font.name,
      value: font.fontFamily,
    }
  })

  const handleFontChange = (fontFamily) => {
    dispatch(setCustomSignFont(activeSign, fontFamily))
  }

  if (!activeSign) {
    return null
  }

  return (
    <StyledThemePanel>
      {activeTab === 0 && (
        <StyledThemePanel.Actions>
          <StyledThemePanel.Action>
            <ThemeBadgeList options={themeOptions} />
          </StyledThemePanel.Action>
        </StyledThemePanel.Actions>
      )}

      {activeTab === 1 && (
        <StyledThemePanel.Actions>
          <StyledThemePanel.Action>
            <p>Välj färg</p>
            <ColorPickerList options={colorOptions} />
          </StyledThemePanel.Action>

          <StyledThemePanel.Action>
            <p>Välj typsnitt</p>

            {!breakpoints.tabletPortraitBelow && (
              <Dropdown
                options={fontOptions}
                currentValue={activeThemeFont}
                handleOptionChange={handleFontChange}
              />
            )}

            {breakpoints.tabletPortraitBelow && (
              <StyledThemePanel.SelectWrapper>
                <Select
                  options={fontOptions}
                  currentValue={activeThemeFont}
                  handleOptionChange={handleFontChange}
                />
              </StyledThemePanel.SelectWrapper>
            )}
          </StyledThemePanel.Action>
        </StyledThemePanel.Actions>
      )}
    </StyledThemePanel>
  )
}

export default ThemePanel
