import {
  SET_ORDER,
  FETCH_SINGLE_ORDER,
} from 'redux/actions/orderActions'

const initialState = {
  cart_id: undefined,
  created_at: undefined,
  customer: undefined,
  external_id: undefined,
  id: undefined,
  items: [],
  payment_id: undefined,
  updated_at: undefined,
  isLoading: true,
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SINGLE_ORDER:
      return {
        isLoading: true,
        ...state
      }
    case SET_ORDER:
      const items = action.payload.items.map((item) => {
        item.values = JSON.parse(item.data)
        return item
      })

      return {
        isLoading: false,
        ...action.payload,
        items: [...items],
      }
    default:
      return state
  }
}

export { orderReducer, initialState }
