import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from 'components/common/Modal'

import { hideDeleteSignModal, updateCart } from 'redux/actions/appActions'
import { categoriesSignsByType, sortCategorisedSigns } from 'helpers/signs'
import { deleteSignsAndSetActive } from 'redux/actions/signsActions'
import { useResponsive } from 'hooks/useResponsive'
import { setActiveStage } from 'redux/actions/editorActions'
import { TABLE_STAGE } from 'constants/Editor'
import { getActiveItemSigns, removeFromCart } from 'helpers/cart'

const DeleteSignModal = () => {
  const dispatch = useDispatch()
  const { breakpoints } = useResponsive()
  const [isLoading, setIsLoading] = useState(false)

  const { signs, editor, activeItem, wcCart, cartSigns } = useSelector((state) => {
    return {
      signs: state.signs.present.signs,
      editor: state.editor,
      activeItem: state.editor.activeItem,
      wcCart: state.cart.wcCart,
      cartSigns: state.cart.signs,
    }
  })

  const activeItems =
    editor.bulkEdit.length > 0 ? editor.bulkEdit : [activeItem]

  const categorisedSigns = categoriesSignsByType(signs)
  const sortedCategories = sortCategorisedSigns(categorisedSigns)

  const closeModalAndDeleteSigns = async () => {
    setIsLoading(true)

    let nextToSelect

    if (activeItems.length === 1) {
      sortedCategories.forEach((category, categoryIndex) => {
        category.signs.forEach((sign, signIndex) => {
          if (activeItems.indexOf(sign.values.id) >= 0) {
            // Is next sign in current category available?
            if (signIndex < category.signs.length - 1) {
              nextToSelect = category.signs[signIndex + 1]
            } else {
              // Do we have previous signs in current category?
              if (signIndex > 0) {
                nextToSelect = category.signs[signIndex - 1]
              } else {
                // Is next category available?
                if (categoryIndex < sortedCategories.length - 1) {
                  nextToSelect = sortedCategories[categoryIndex + 1].signs[0]
                } else {
                  // Is previous category avaliable?
                  if (categoryIndex > 0) {
                    nextToSelect =
                      sortedCategories[categoryIndex - 1].signs[
                        sortedCategories[categoryIndex - 1].signs.length - 1
                      ]
                  }
                }
              }
            }
          }
        })
      })
    } else {
      const remainingSigns = signs.filter(
        (sign) => activeItems.indexOf(sign.values.id) === -1
      )
      const remainingCategorisedSigns = categoriesSignsByType(remainingSigns)
      const remainingSortedCategories = sortCategorisedSigns(
        remainingCategorisedSigns
      )
      if (remainingSortedCategories[0] !== undefined)
        nextToSelect = remainingSortedCategories[0].signs[0]
    }

    const activeSigns = getActiveItemSigns(signs, activeItems, cartSigns)
    const response = await removeFromCart(activeSigns, wcCart, cartSigns)

    if (response?.cart) {
      dispatch(updateCart(response))
    }

    dispatch(deleteSignsAndSetActive(activeItems, nextToSelect))
    dispatch(hideDeleteSignModal())
    if (breakpoints.mobileDown) {
      dispatch(setActiveStage(TABLE_STAGE))
    }

    setIsLoading(false)
  }

  return (
    <Modal
      isActive={true}
      header={'Är du säker på att du vill radera skylten/arna?'}
      rejectText={'Avbryt'}
      rejectHandler={() => dispatch(hideDeleteSignModal())}
      acceptText={'Radera'}
      acceptHandler={closeModalAndDeleteSigns}
      size={{ maxWidth: '340px' }}
      isLoading={isLoading}
    >
      <p>Skylten/arna du markerat kommer att raderas permanent.</p>
    </Modal>
  )
}

export default DeleteSignModal
