import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'

const StyledHeaderMobile = styled.header`
  overflow-y: scroll;
  overflow-x: hidden;
  background: ${colors.primary};
  color: ${colors.white};
`
StyledHeaderMobile.Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: ${spacing.xLarge};
  padding: 0;
  border-bottom: 1px solid ${colors.blueDark};
`

StyledHeaderMobile.Col = styled.div`
  display: flex;
  align-items: center;
`

StyledHeaderMobile.Logo = styled.header`
  ${text.bodyBold}
`

export default StyledHeaderMobile
