import styled from 'styled-components'

import { colors, spacing } from 'styles/utilities/variables.js'

const StyledLoadBar = styled.div`
  background: red;
  position: relative;
  margin: ${spacing.xSmall} 0;
`

StyledLoadBar.Track = styled.div`
  height: 3px;
  background: ${colors.grey03};
`

StyledLoadBar.Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background: ${colors.primary};
  width: ${(props) => (props.width ? `${props.width}%` : '0%')};
`

export default StyledLoadBar
