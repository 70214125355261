import styled from 'styled-components'
import { spacing } from 'styles/utilities/variables'

const StyledContainer = styled.div(({ width = 1400 }) => `
  max-width: ${width}px;
  margin: 0 auto;
  width: 100%;
  padding: 0 ${spacing.medium};
  position: relative;
`)

export default StyledContainer
