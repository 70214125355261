import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'

const StyledCheckout = styled.div`
  padding: 3rem 0;
  background: ${colors.grey02};
  form {
      max-width:100%;
  }
`
export default StyledCheckout

export const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`
