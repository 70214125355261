import React from 'react'
import StyledEmptyListMessage from './EmptyListMessage.style'

const EmptyListMessage = (props) => {
  const { title, message } = props
  return (
    <StyledEmptyListMessage>
      <StyledEmptyListMessage.Title>{title}</StyledEmptyListMessage.Title>
      <StyledEmptyListMessage.Message>{message}</StyledEmptyListMessage.Message>
    </StyledEmptyListMessage>
  )
}

export default EmptyListMessage
