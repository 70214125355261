import styled from 'styled-components'
import { media } from 'styles/utilities/functions'
import { spacing } from 'styles/utilities/variables'

const StyledOptionButtonList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 24rem;
  margin: 0 auto;
  gap: ${spacing.small};

  ${media.tabletPortraitBelow`
    display: flex;
    max-width: initial;
    flex-wrap: nowrap;
    overflow-x: scroll;

    // Fix for not beeing able to add right margin to last child due to flex on parent
    &:after {
      content: '';
      border-right: ${spacing.small} solid transparent;
    }

      > div {
        min-width: 8rem;
        margin-left: ${spacing.small};
      }
   `}
`

export default StyledOptionButtonList
