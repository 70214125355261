import React from 'react'
import StyledThemeBadge from './ThemeBadge.style'

const ThemeBadge = (props) => {
  const { color, background, fontFamily, isActive = false, clickCallback, name } = props
  return (
    <StyledThemeBadge onClick={clickCallback}>
      <StyledThemeBadge.Badge
        color={color}
        background={background}
        fontFamily={fontFamily}
      >
        <StyledThemeBadge.Text>Aa</StyledThemeBadge.Text>
        {isActive &&
          <StyledThemeBadge.Active />
        }
      </StyledThemeBadge.Badge>
      <StyledThemeBadge.Name>{ name }</StyledThemeBadge.Name>
    </StyledThemeBadge>
  )
}

export default ThemeBadge
