import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { StyledCookieBar } from './style'
import Button from 'components/common/Button'

const CookieBar = () => {
  const [agreedToCookies, setAgreedToCookies] = useState(Cookies.get('agreed_to_cookies'))

  if (agreedToCookies) {
    return null
  }

  return (
    <StyledCookieBar>
      <b>Cookies</b>

      <p>
        Den här webbplatsen använder cookies för att fungera. Du godkänner
        cookies genom att fortsätta använda webbplatsen. Du kan ändra
        cookieinställningar i din webbläsares inställningar.
      </p>

      <Button 
        type={'teal-light light'} size='large'
        handleButtonClick={() => {
          Cookies.set('agreed_to_cookies', true)
          setAgreedToCookies(true)
        }}
      >
        OK
      </Button>
    </StyledCookieBar>
  )
}

export default CookieBar
