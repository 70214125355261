import React from 'react'
import { StyledButtonRound } from './ButtonRound.style'
import Icon from 'components/common/Icon'

const ButtonRound = (props) => {
  const { icon, clickHandler } = props
  return (
    <StyledButtonRound onClick={clickHandler}>
      <Icon name={icon} size={24} />
    </StyledButtonRound>
  )
}

export default ButtonRound
