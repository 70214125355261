import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

import { media } from 'styles/utilities/functions'

const StyledSingleView = styled.section`
  background: ${colors.grey02};
  padding: ${spacing.medium};
  height: 100%;
  overflow: hidden;
`

StyledSingleView.Inner = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    ${media.tabletPortraitBelow`align-items: flex-start;`}
  }

  ${media.tabletPortraitBelow`align-items: flex-start;`}
`

export default StyledSingleView
