import React from 'react'
import StyledPaneHorizontal from './PanelHorizontal.style'
import TextPanel from 'components/editor/Panels/Text'
import ThemePanel from 'components/editor/Panels/Theme'
import MountingPanel from 'components/editor/Panels/Mounting'
import SizePanel from 'components/editor/Panels/Size'
import ShapePanel from 'components/editor/Panels/Shape'
import ToolbarTabs from 'components/editor/Toolbar/Tabs'
import Icon from 'components/common/Icon'

const PanelHorizontal = (props) => {
  const { tools, activeSign, handleToolbarClick } = props

  const shapeChoicesAvailable = () => {
    return activeSign && activeSign.settings.plate.shapeChoices.length
  }

  return (
    <StyledPaneHorizontal>
      <StyledPaneHorizontal.Panels>
        {tools.map((tool, key) => {
          if (!tool.isActive) {
            return null
          }

          if (tool.type === 'shape' && !shapeChoicesAvailable()) {
            return null
          }

          const activeTab =
            tool.tabs.length > 0
              ? tool.tabs.reduce(
                  (acc, tab, tabIndex) => (tab.isActive ? tabIndex : acc),
                  0
                )
              : 0

          return (
            <React.Fragment key={key}>
              {tool.isActive && tool.tabs.length > 0 && (
                <ToolbarTabs tool={tool} />
              )}

              <StyledPaneHorizontal.Panel
                key={key}
                // onClick={props.handleToolbarClick(tool.id)}
              >
                {tool.type === 'text' && (
                  <TextPanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}
                {tool.type === 'theme' && (
                  <ThemePanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}

                {tool.type === 'mounting' && (
                  <MountingPanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}

                {tool.type === 'size' && (
                  <SizePanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}

                {tool.type === 'shape' && (
                  <ShapePanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}
              </StyledPaneHorizontal.Panel>
            </React.Fragment>
          )
        })}
      </StyledPaneHorizontal.Panels>
      <StyledPaneHorizontal.Menu>
        {tools.map((tool, key) => {
          if (tool.type === 'shape' && !shapeChoicesAvailable()) {
            return null
          }

          return (
            <StyledPaneHorizontal.MenuItem
              key={key}
              isActive={tool.isActive}
              onClick={() => {
                handleToolbarClick(tool.id, true)
              }}
            >
              <StyledPaneHorizontal.MenuIcon>
                <Icon name={tool.icon} />
              </StyledPaneHorizontal.MenuIcon>

              <StyledPaneHorizontal.MenuText>
                {tool.text}
              </StyledPaneHorizontal.MenuText>

              {tool.type === 'text' && activeSign.values.tooWideTextRowIds.length > 0 &&
                <StyledPaneHorizontal.Warning>
                  <Icon name={'errorTriangle'} size={10} />
                </StyledPaneHorizontal.Warning>
              }
            </StyledPaneHorizontal.MenuItem>
          )
        })}
      </StyledPaneHorizontal.Menu>
    </StyledPaneHorizontal>
  )
}

export default PanelHorizontal
