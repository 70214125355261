import styled from 'styled-components'
import { colors, spacing, typography } from 'styles/utilities/variables'

const StyledErrorPopup = styled.p`
  position: absolute;
  top: ${(props) => `calc(${props.y}px - 10px)`};
  left: ${(props) => `${props.x}px`};
  transform: translate(-50%, -100%);
  width: 200px;
  background: ${colors.black};
  color: ${colors.white};
  font-size: ${typography.sizes.small};
  line-height: 1.3;
  padding: ${spacing.small};
  margin: 0;
  z-index: 9999;
  pointer-events: none;
`

export default StyledErrorPopup
