import { TABLE_STAGE } from 'constants/Editor'
import Cookies from 'js-cookie'

import {
  EDITOR_SET_ACTIVE_ITEM,
  EDITOR_TOGGLE_TOOLBAR_ITEM_OPEN,
  EDITOR_TOGGLE_TOOLBAR_ITEM_TAB,
  EDITOR_SET_ACTIVE_STAGE,
  EDITOR_ADD_TO_BULK_EDIT,
  EDITOR_REMOVE_FROM_BULK_EDIT,
  EDITOR_ACTIVATE_BULK_EDITOR,
  EDITOR_DEACTIVATE_BULK_EDITOR,
  EDITOR_FOCUS_TABLE_TEXT_INPUT,
} from 'redux/actions/editorActions'

import { DELETE_SIGNS, DELETE_ALL_SIGNS } from 'redux/actions/signsActions'

const initialState = {
  activeItem: false,
  activeStage: Cookies.get('stage_type') || TABLE_STAGE, //TABLE_STAGE,
  isBulkEditing: false,
  focus: {
    sign: null,
    rowId: null,
  },
  bulkEdit: [],
  toolbar: [
    {
      id: 2,
      text: 'Text',
      type: 'text',
      icon: 'text',
      actions: [],
      isActive: true,
      tabs: [],
    },
    {
      id: 3,
      text: 'Tema',
      type: 'theme',
      icon: 'palett',
      actions: [],
      isActive: false,
      tabs: [
        {
          id: 1,
          text: 'Favoriter',
          isActive: true,
        },
        {
          id: 2,
          text: 'Anpassad',
          isActive: false,
        },
      ],
    },
    {
      id: 4,
      text: 'Storlek',
      type: 'size',
      icon: 'maximize',
      actions: [],
      isActive: false,
      tabs: [],
    },
    {
      id: 6,
      text: 'Fästmetod',
      type: 'mounting',
      icon: 'tape',
      actions: [],
      isActive: false,
      tabs: [],
    },
  ],
}

const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDITOR_FOCUS_TABLE_TEXT_INPUT:
      return {
        ...state,
        focus: {
          ...action.payload,
        },
      }
    case EDITOR_ACTIVATE_BULK_EDITOR:
      return {
        ...state,
        isBulkEditing: true,
      }
    case EDITOR_DEACTIVATE_BULK_EDITOR:
      return {
        ...state,
        isBulkEditing: false,
      }
    case EDITOR_SET_ACTIVE_STAGE:
      return {
        ...state,
        activeStage: action.payload,
      }
    case EDITOR_SET_ACTIVE_ITEM:
      if (action.payload === undefined) {
        return {
          ...state,
          activeStage: TABLE_STAGE,
          activeItem: !!action.payload && action.payload.values.id,
        }
      } else {
        return {
          ...state,
          activeItem: !!action.payload && action.payload.values.id,
        }
      }
    case EDITOR_REMOVE_FROM_BULK_EDIT:
      return {
        ...state,
        bulkEdit: state.bulkEdit.filter((id) => id !== action.payload),
      }

    case DELETE_SIGNS:
      return {
        ...state,
        bulkEdit: state.bulkEdit.filter((id) => action.payload.indexOf(id) < 0),
      }

    case DELETE_ALL_SIGNS:
      return {
        ...state,
        activeItem: 0,
      }

    case EDITOR_ADD_TO_BULK_EDIT:
      return {
        ...state,
        bulkEdit: [...state.bulkEdit, action.payload],
      }
    case EDITOR_TOGGLE_TOOLBAR_ITEM_OPEN:
      return {
        ...state,
        toolbar: state.toolbar.map((item) => {
          const newItem = { ...item }
          if (action.payload.id === item.id) {
            newItem.isActive = !newItem.isActive
          } else {
            if (action.payload.closeOthers) newItem.isActive = false
          }
          return newItem
        }, []),
      }
    case EDITOR_TOGGLE_TOOLBAR_ITEM_TAB:
      return {
        ...state,
        toolbar: state.toolbar.map((item) => {
          const newItem = { ...item }
          if (newItem.isActive) {
            newItem.tabs = item.tabs.map((tab) => {
              const newTab = { ...tab }
              newTab.isActive = action.payload === tab.id
              return newTab
            })
          }
          return newItem
        }, []),
      }
    default:
      return { ...state }
  }
}

export { editorReducer, initialState }
