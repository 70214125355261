import React from 'react'
import StyledOptionButton from './OptionButton.style'
import Icon from '../Icon'

const OptionButton = (props) => {
  const { icon, children, name, additionalText, clickCallback, isActive, transparent = false } =
    props

  const text = additionalText ? `${name}\n${additionalText}` : name

  return (
    <StyledOptionButton onClick={() => clickCallback()} isActive={isActive} transparent={transparent}>
      {isActive && <StyledOptionButton.Checkmark><Icon name={'checkmark'} size={16} /></StyledOptionButton.Checkmark>}
      {icon && <StyledOptionButton.Icon>{icon}</StyledOptionButton.Icon>}
      {!icon &&
        <StyledOptionButton.Children isActive={isActive}>
          {children}
        </StyledOptionButton.Children>
      }
      <StyledOptionButton.Text>{text}</StyledOptionButton.Text>
    </StyledOptionButton>
  )
}

export default OptionButton
