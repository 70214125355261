import React from 'react'
import { StyledButton } from './Button.style'
import Icon from 'components/common/Icon'

const Button = (props) => {
  const {
    type,
    icon,
    iconSize,
    className,
    children,
    handleButtonClick,
    disabled,
    usePolygonDown,
    usePolygonUp,
    notification,
    title,
  } = props

  const typeClass = type ? type : 'primary'
  const size = iconSize ? iconSize : 24

  return (
    <StyledButton
      className={`${className} button ${typeClass}`}
      onClick={handleButtonClick}
      disabled={disabled}
      title={title}
    >
      <StyledButton.Inner>
        {notification && (
          <StyledButton.Notification>{notification}</StyledButton.Notification>
        )}
        {icon && <Icon name={icon} size={size} />}
        {children && (
          <StyledButton.Text withMargin={usePolygonUp || usePolygonDown}>
            {children}
          </StyledButton.Text>
        )}
        {usePolygonDown && <Icon name={'polygonDown'} />}
        {usePolygonUp && <Icon name={'polygonUp'} />}
      </StyledButton.Inner>
    </StyledButton>
  )
}

export default Button
