import styled from 'styled-components'

const StyledProceedToCheckoutModal = styled.div``

StyledProceedToCheckoutModal.Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export default StyledProceedToCheckoutModal
