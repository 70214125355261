import styled from 'styled-components'
import { colors, spacing, radius } from 'styles/utilities/variables'
import { media } from 'styles/utilities/functions'

const Toast = styled.div`
  position: fixed;
  bottom: ${({ offset }) => (offset ? 64 * offset + 20 + 'px' : '20px')};
  left: ${spacing.small};
  min-width: 300px;
  background: ${colors.secondary};
  color: ${colors.white};
  padding: ${spacing.medium};
  text-align: center;
  box-shadow: 1px 1px 25px 15px #011b1724;
  border-radius: ${radius.medium};
  z-index: 10;

  ${media.tabletPortraitBelow`
    left: ${spacing.small};
    right: ${spacing.small};
  `}

  &.info {
    background: ${colors.primary};
  }

  &.warning {
    background: ${colors.warning};
  }

  &.success {
    background: ${colors.secondary};
  }
`
export default Toast
