import React from 'react'
import StyledSection from './StyledSection.style'

const Section = React.forwardRef((props, ref) => {
  return (
    <StyledSection ref={ref}>
      {props.children}
    </StyledSection>
  )
})

export default Section
