import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SINGLE_STAGE } from 'constants/Editor'
import { Canvas } from 'components/editor/Sign'
import { calculateSignScale, getRenderSize } from 'helpers/signs'
import StyledPreviewPanel from './PreviewPanel.style'

import { setActiveStage } from 'redux/actions/editorActions'

const PreviewPanel = (props) => {
  const { activeSign } = props
  const dispatch = useDispatch()

  const canvasWrapperRef = useRef(null)

  const maxHeight = 220

  const [canvasWrapperWidth, setCanvasWrapperWidth] = useState(273)
  const [canvasWrapperHeight, setCanvasWrapperHeight] = useState(maxHeight)

  const recalculatePanelSize = () => {
    if (canvasWrapperRef && canvasWrapperRef.current) {
      const boundingRect = canvasWrapperRef.current.getBoundingClientRect()
      const canvasWrapperWidth = boundingRect.width
      const canvasWrapperHeight = Math.min(boundingRect.height, maxHeight)
      setCanvasWrapperWidth(canvasWrapperWidth)
      setCanvasWrapperHeight(canvasWrapperHeight)
    }
  }

  useEffect(() => {
    recalculatePanelSize()
    window.addEventListener('resize', () => recalculatePanelSize())
    return () => {
      window.removeEventListener('resize', () => recalculatePanelSize())
    }
  }, [])

  if (!activeSign) {
    return null
  }
  const renderSize = getRenderSize(activeSign)
  const signScale = calculateSignScale({
    containerWidth: canvasWrapperWidth,
    containerHeight: canvasWrapperHeight,
    signWidth: renderSize.width,
    signHeight: renderSize.height,
  })

  const handleStageChangeClick = (stage) => {
    dispatch(setActiveStage(stage))
  }

  return (
    <StyledPreviewPanel>
      <StyledPreviewPanel.Header>
        <StyledPreviewPanel.Label>Förhandsvisning</StyledPreviewPanel.Label>
        <StyledPreviewPanel.Button
          type={'plain'}
          handleButtonClick={() => handleStageChangeClick(SINGLE_STAGE)}
          icon={'editorview'}
          iconSize={18}
        >
          Se större version
        </StyledPreviewPanel.Button>
      </StyledPreviewPanel.Header>

      <StyledPreviewPanel.CanvasWrapper ref={canvasWrapperRef}>
        <Canvas
          sign={activeSign}
          scale={signScale - 0.1}
          width={canvasWrapperWidth}
          height={canvasWrapperHeight}
        />
      </StyledPreviewPanel.CanvasWrapper>
    </StyledPreviewPanel>
  )
}

export default PreviewPanel
