import { cloneSignWithNewType, newSignTemplate } from 'helpers/signs'
import { UPDATE_CART } from 'redux/actions/appActions'
import { EMPTY_CART } from 'redux/actions/cartActions'
import { DELETE_SIGNS } from 'redux/actions/signsActions'

const initialState = {
  signs: [],
  itemCount: 0,
  wcCart: undefined,
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CART:
      return {
        ...state,
        signs: action.payload.cart.items
          .filter((item) => !!item.signs)
          .flatMap((item) =>
            item.signs.map((sign) => ({
              id: item.id,
              ...cloneSignWithNewType(newSignTemplate, sign.type),
              ...{ values: sign },
              cartItem: item,
            }))
          ),
        itemCount: action.payload.cart.items.reduce(
          (acc, item) =>
            Array.isArray(item?.signs) ? acc + item.quantity : acc,
          0
        ),
        wcCart: { ...action.payload.cart },
      }
    case DELETE_SIGNS:
      return {
        ...state,
        signs: state.signs.filter(
          (sign) => !action.payload.includes(sign.values.id)
        ),
        itemCount: state.itemCount - action.payload.length,
      }
    case EMPTY_CART:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export { cartReducer, initialState }
