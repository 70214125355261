import React from 'react'
import ColorPickerButton from './ColorPickerButton'
import StyledColorPickerList from './ColorPickerList.style'

const ColorPickerList = (props) => {
  const { options } = props
  return (
    <StyledColorPickerList>
      {options.map((option, index) => {
        return (
          <ColorPickerButton
            key={index}
            clickCallback={option.clickCallback}
            isActive={option.isActive}
            colorPrimary={option.colorPrimary}
            colorSecondary={option.colorSecondary}
          />
        )
      })}
    </StyledColorPickerList>
  )
}

export default ColorPickerList
