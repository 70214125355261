import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setSignText,
  setSignTextSize,
  setSignTextAlignment,
} from 'redux/actions/signsActions'
import { cloneSignWithNewText } from 'helpers/signs'
import StyledTextPanel from './TextPanel.style'
import TextInput from 'components/common/TextInput'
import { TEXT_SIZES, TEXT_ALIGNMENT } from 'constants/Signs'
import SegmentedControl from 'components/common/SegmentedControl'
import { EMOJI_REGEX, TABLE_STAGE } from 'constants/Editor'
import { SIGN_TYPES } from 'constants/Signs'

const TextPanel = (props) => {
  // const { panel, activeSign, activeTab } = props
  const { activeSign } = props
  const placeholderTexts = activeSign ? SIGN_TYPES.find(
    ({ type }) => type === activeSign.values.type
  ).placeholderTexts : []

  const dispatch = useDispatch()
  const { activeStage } = useSelector((state) => {
    return {
      activeStage: state.editor.activeStage,
    }
  })

  if (!activeSign) {
    return null
  }

  const {
    values: { tooWideTextRowIds },
  } = activeSign

  const handleInputChange = (value, activeSign, textId) => {
    const newSign = cloneSignWithNewText(activeSign, value, textId)

    dispatch(setSignText(newSign))
  }

  const handleTextSizeButtonClick = (activeSign, size) => {
    dispatch(setSignTextSize(activeSign, size))
  }

  const handleTextAlignmentButtonClick = (activeSign, alignment) => {
    dispatch(setSignTextAlignment(activeSign, alignment))
  }

  const hideTextInputs = activeStage === TABLE_STAGE
  const currentTextSize =
    activeSign && activeSign.values.textSize ? activeSign.values.textSize : ''
  const currentTextAlignment =
    activeSign && activeSign.values.align ? activeSign.values.align : ''
  const segmentControls = TEXT_SIZES.map((size) => {
    return {
      ...size,
      isActive: size.type === currentTextSize,
    }
  })

  const alignmentControls = TEXT_ALIGNMENT.map((alignment) => {
    return {
      ...alignment,
      isActive: alignment.type === currentTextAlignment,
    }
  })

  return (
    <StyledTextPanel>
      {activeSign.values.text.map((textRow, key) => {
        if (hideTextInputs) {
          return null
        }

        const bottomSpace = key >= activeSign.values.text.length - 1

        const getErrorMessage = () => {
          if (textRow.content.match(EMOJI_REGEX)) {
            return 'Texten innehåller ogiltiga tecken'
          }
          if (tooWideTextRowIds && tooWideTextRowIds.includes(textRow.rowId)) {
            return currentTextSize === 'small'
              ? 'Texten är för lång, förkorta texten'
              : 'Texten är för lång, gör textstorleken mindre'
          }

          return null
        }

        return (
          <TextInput
            key={key}
            placeholder={placeholderTexts[key]}
            name={'search' + key}
            label={`Rad ${key + 1}`}
            onChange={(e) => handleInputChange(e.target.value, activeSign, textRow.rowId)}
            value={textRow.content}
            bottomSpace={bottomSpace}
            errorMessage={getErrorMessage()}
            autocomplete="off"
          />
        )
      })}

      <StyledTextPanel.Controls>
        <p>Textstorlek</p>
        <SegmentedControl
          buttons={segmentControls}
          handleClick={(size) => handleTextSizeButtonClick(activeSign, size)}
        />
      </StyledTextPanel.Controls>

      {activeSign.values.shape !== 'ellipse' && ( // Always centered text on ellipse sign
        <StyledTextPanel.Controls>
          <p>Justering</p>
          <SegmentedControl
            buttons={alignmentControls}
            handleClick={(alignment) =>
              handleTextAlignmentButtonClick(activeSign, alignment)
            }
          />
        </StyledTextPanel.Controls>
      )}
    </StyledTextPanel>
  )
}

export default TextPanel
