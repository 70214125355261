import React from 'react'
import Modal from 'components/common/Modal'
import { SIGN_TYPES } from 'constants/Signs'
import { Heading } from 'components/common/Heading'
import StyledNewSignModal from './NewSignModal.style'
import Button from 'components/common/Button'

// const SHAPES = SHAPE_TYPES.reduce(
//   (acc, type) => ({ ...acc, [type.type]: { ...type } }),
//   {}
// )

const NewSignModalDesktop = (props) => {
  const {
    onModalReject,
    closeModalAndCreateSign,
    selectedSignType,
    setSelectedSignType,
    selectedShape,
    setSelectedShape,
  } = props

  return (
    <Modal
      size={{ width: '90', maxWidth: '600px', maxHeight: '90vh' }}
      contentHeight={'100%'}
      isActive={true}
      padding={'0'}
      rejectHandler={onModalReject}
    >
      <StyledNewSignModal>
        <StyledNewSignModal.Header>
          <Heading>Välj skylttyp</Heading>
        </StyledNewSignModal.Header>

        <StyledNewSignModal.Section>
          {SIGN_TYPES.map((signType, index) => (
            <StyledNewSignModal.Row
              selected={signType === selectedSignType}
              onClick={() => {
                setSelectedShape(null)
                setSelectedSignType(signType)
              }}
              key={index}
            >
              <span>{signType.name}</span>
            </StyledNewSignModal.Row>
          ))}
        </StyledNewSignModal.Section>
        {/*
        <StyledNewSignModal.Section>
          {sign &&
            sign.settings.plate.shapeChoices.map((shape, index) => (
              <StyledNewSignModal.Row
                isActive={false}
                selected={shape === selectedShape}
                onClick={() => setSelectedShape(shape)}
                key={index}
              >
                <span>{SHAPES[shape].name}</span>
              </StyledNewSignModal.Row>
            ))}
        </StyledNewSignModal.Section> */}

        <StyledNewSignModal.Section>
          {!(selectedSignType && selectedShape) && (
            <StyledNewSignModal.Description>
              <p>Välj skylttyp och form för att visa mer information</p>
            </StyledNewSignModal.Description>
          )}
          {selectedSignType && selectedShape && (
            <StyledNewSignModal.Description>
              <img src={`${process.env.PUBLIC_URL}/${selectedSignType.type}.png`} alt={selectedSignType.name} />
              <div><selectedSignType.desc /></div>
              {/* {sign.settings.sizeChoices.length ? (
                <p>
                  {sign.settings.sizeChoices
                    .map((size) => `${size.width} x ${size.height} mm`)
                    .join(', ')}
                </p>
              ) : (
                <p>{`${sign.values.size.width} x ${sign.values.size.height} mm`}</p>
              )} */}
            </StyledNewSignModal.Description>
          )}
        </StyledNewSignModal.Section>

        <StyledNewSignModal.Footer>
          <Button handleButtonClick={onModalReject} type={'white light'}>
            Stäng
          </Button>
          <Button
            handleButtonClick={() => closeModalAndCreateSign()}
            type={'primary light'}
            disabled={!selectedSignType || !selectedShape}
          >
            Skapa
          </Button>
        </StyledNewSignModal.Footer>
      </StyledNewSignModal>
    </Modal>
  )
}

export default NewSignModalDesktop
