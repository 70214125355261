import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/common/Button'
import { StyledCart } from './Cart.styles'
import { hideCart, showCart } from 'redux/actions/appActions'
import Cart from './Cart'

const CartDesktop = () => {
  const dispatch = useDispatch()

  const { wcCart, signs, isCartActive, shipping } = useSelector((state) => {
    return {
      signs: state.cart.signs,
      isCartActive: state.app.isCartActive,
      shipping: state.app.shipping,
      wcCart: state.cart.wcCart,
    }
  })

  const total = wcCart ? parseInt(wcCart.totals.total_price) / 100 : 0

  const node = useRef(null)

  const handleButtonClick = () => {
    isCartActive ? dispatch(hideCart()) : dispatch(showCart())
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return
      }
      // outside click
      dispatch(hideCart())
    }

    if (isCartActive) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [node, isCartActive, dispatch])

  return (
    <StyledCart.DesktopContainer ref={node}>
      <Button
        type={'plain light'}
        handleButtonClick={handleButtonClick}
        icon={'cart'}
        usePolygonDown={!isCartActive}
        usePolygonUp={isCartActive}
      >
        {total} kr
      </Button>

      {isCartActive && (
        <StyledCart.DesktopDropDown>
          <Cart signs={signs} total={total} shipping={shipping} />
        </StyledCart.DesktopDropDown>
      )}
    </StyledCart.DesktopContainer>
  )
}

export default CartDesktop
