import React from 'react'
import StyledPaneVertical from './PanelVertical.style'
import TextPanel from 'components/editor/Panels/Text'
import ThemePanel from 'components/editor/Panels/Theme'
import MountingPanel from 'components/editor/Panels/Mounting'
import SizePanel from 'components/editor/Panels/Size'
import ShapePanel from 'components/editor/Panels/Shape'
import PreviewPanel from 'components/editor/Panels/Preview'
import ToolbarTabs from 'components/editor/Toolbar/Tabs'
import Icon from 'components/common/Icon'
import ContentExpander from 'components/common/ContentExpander'
import { TABLE_STAGE } from 'constants/Editor'
import { useResponsive } from 'hooks/useResponsive'

const PanelVertical = (props) => {
  const { tools, activeSign, editor, handleToolbarClick } = props
  const { breakpoints } = useResponsive()
  const shouldShowPreview =
    editor.activeStage === TABLE_STAGE && !breakpoints.tabletPortraitBelow

  const shapeChoicesAvailable = () => {
    return activeSign && activeSign.settings.plate.shapeChoices.length
  }
  // const topTools = tools.filter((tool) => tool.type === 'signType')
  // const bottomTools = tools.filter((tool) => tool.type !== 'signType')

  const getTotalPrice = () => {
    let totalPrice = 0
    if (activeSign) {
      totalPrice += activeSign.values.price.total

      if (
        activeSign.values.addOnProducts &&
        activeSign.values.addOnProducts.length > 0
      ) {
        activeSign.values.addOnProducts.forEach((addOnProduct) => {
          totalPrice += addOnProduct.total
        })
      }
    }

    return totalPrice
  }

  return (
    <StyledPaneVertical>
      {shouldShowPreview && <PreviewPanel activeSign={activeSign} />}
      {tools.map((tool, key) => {
        if (tool.type === 'shape' && !shapeChoicesAvailable()) {
          return null
        }

        const activeTab =
          tool.tabs.length > 0
            ? tool.tabs.reduce(
                (acc, tab, tabIndex) => (tab.isActive ? tabIndex : acc),
                0
              )
            : 0

        return (
          <StyledPaneVertical.Panel key={key}>
            <StyledPaneVertical.PanelHeader
              isActive={tool.isActive}
              onClick={() => {
                handleToolbarClick(tool.id, false)
              }}
            >
              <span>{tool.text}</span>
              {tool.isActive ? (
                <Icon name={'chevronUp'} />
              ) : (
                <Icon name={'chevronDown'} />
              )}
            </StyledPaneVertical.PanelHeader>

            <ContentExpander open={tool.isActive}>
              {tool.tabs.length > 0 && <ToolbarTabs tool={tool} />}

              <StyledPaneVertical.PanelContent>
                {tool.type === 'text' && (
                  <TextPanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}
                {tool.type === 'theme' && (
                  <ThemePanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}

                {tool.type === 'mounting' && (
                  <MountingPanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}

                {tool.type === 'size' && (
                  <SizePanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}

                {tool.type === 'shape' && (
                  <ShapePanel
                    panel={tool}
                    activeSign={activeSign}
                    activeTab={activeTab}
                  />
                )}
              </StyledPaneVertical.PanelContent>
            </ContentExpander>
          </StyledPaneVertical.Panel>
        )
      })}

      {activeSign && (
        <StyledPaneVertical.Summary>
          <div>
            <StyledPaneVertical.SummaryText bold>
              Vald skylt
            </StyledPaneVertical.SummaryText>
          </div>
          <div>
            <StyledPaneVertical.SummaryText>
              <span>Skylt: {activeSign.values.price.total} kr</span>
              {activeSign.values.addOnProducts &&
                activeSign.values.addOnProducts.length > 0 && (
                  <>
                    {activeSign.values.addOnProducts.map(
                      (addOnProduct, index) => {
                        let str = ` · ${addOnProduct.name}: ${addOnProduct.total} kr`
                        return <span key={index}>{str}</span>
                      }
                    )}
                  </>
                )}
            </StyledPaneVertical.SummaryText>
            <StyledPaneVertical.SummaryText bold>
              Total: {getTotalPrice()} kr
            </StyledPaneVertical.SummaryText>
          </div>
        </StyledPaneVertical.Summary>
      )}
    </StyledPaneVertical>
  )
}

export default PanelVertical
