import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setErrorPopupMessage,
  showErrorPopup,
  hideErrorPopup,
} from 'redux/actions/appActions'
import StyledTextInput from './TextInput.style'

import Icon from 'components/common/Icon'

const TextInput = (props) => {
  const {
    label,
    value,
    defaultValue,
    onChange,
    onBlur,
    placeholder,
    focus,
    bottomSpace,
    type = 'text',
    errorMessage = null,
    inTable = false,
    hideErrorOnFocus = false,
  } = props
  const dispatch = useDispatch()
  const [hasFocus, setHasFocus] = useState(false)

  const inputEl = useRef(null)

  useEffect(() => {
    if (focus) {
      inputEl.current.focus()
    }
  }, [focus])

  useEffect(() => {
    if (!errorMessage) {
      dispatch(setErrorPopupMessage(errorMessage))
      dispatch(hideErrorPopup())
    }
  }, [errorMessage])

  return (
    <StyledTextInput bottomSpace={bottomSpace} invalid={errorMessage}>
      <StyledTextInput.LabelInputWrapper>
        {label && <StyledTextInput.Label>{label}</StyledTextInput.Label>}
        <StyledTextInput.InputWrapper>
          <StyledTextInput.Input
            ref={inputEl}
            type={type}
            // hack to prevent autocomplete in safari
            name={'search'}
            autocomplete="off"
            placeholder={placeholder}
            defaultValue={defaultValue}
            onFocus={(e) => setHasFocus(true)}
            onChange={(e) => onChange(e)}
            onBlur={(e) => {
              setHasFocus(false)
              if (typeof onBlur === 'function') return onBlur(e)
            }}
            value={value}
            invalid={errorMessage}
            inTable={inTable}
          />
          {errorMessage && !(hideErrorOnFocus && hasFocus) && (
            <StyledTextInput.ErrorIcon
              onMouseEnter={() => {
                if (inTable) {
                  dispatch(setErrorPopupMessage(errorMessage))
                  dispatch(showErrorPopup())
                }
              }}
              onMouseLeave={() => {
                if (inTable) dispatch(hideErrorPopup())
              }}
              inTable={inTable}
            >
              <Icon name={'errorTriangle'} size={inTable ? 20 : 14} />
            </StyledTextInput.ErrorIcon>
          )}
        </StyledTextInput.InputWrapper>
      </StyledTextInput.LabelInputWrapper>

      {errorMessage && !inTable && !(hideErrorOnFocus && hasFocus) && (
        <StyledTextInput.ErrorMessage>
          {errorMessage}
        </StyledTextInput.ErrorMessage>
      )}
    </StyledTextInput>
  )
}

export default TextInput
