import styled from 'styled-components'

import { spacing } from 'styles/utilities/variables.js'

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.noMargin ? 0 : spacing.large} 0;
`

export default StyledLoader
