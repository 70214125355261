import { createGlobalStyle } from 'styled-components'
import { colors, typography, spacing, mq } from '../utilities/variables'
import { text } from '../utilities/mixins'
import { formStyles } from 'styles/base/form'

const GlobalStyle = createGlobalStyle`
  ${formStyles}

  html,
  body {
    position: fixed;
    overflow: hidden;
    height: 100%;

    @media print {
      overflow: initial;
      position: initial;
    }
  }

  .App {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */

    @media print {
      width: initial;
      height: initial;
      overflow: initial;
      position: initial;
    }

    > *:not(#print-portal) {
      @media print {
        display: none;
      }
    }
  }

  body {
    color: ${colors.black};
    ${text.body}
  }
  input{
    font-size: ${typography.sizes.base};
  }

  p {
    margin-bottom: ${spacing.small};
  }

  #print-portal {
    display: none;

    @media print {
      display: initial;
    }
  }

  feedbucket-app {
    @media print {
      display: none;
    }
  }
`


export const H1 = `
  font-size: ${typography.sizes.xxLarge};
  line-height: 1.1;
  @media ${mq.tablet} {
    font-size: ${typography.sizes.large};
  }
`
export const H2 = `
  font-size: ${typography.sizes.xLarge};
  line-height: 1.1;
  @media ${mq.tablet} {
    font-size: ${typography.sizes.medium};
  }
`
export const H3 = `
  font-size: ${typography.sizes.large};
`
export const H4 = `
  font-size: ${typography.sizes.medium};
`
export const H5 = `
  font-size: ${typography.sizes.base};
`
export const H6 = `
  font-size: ${typography.sizes.small};
`


export { GlobalStyle }
