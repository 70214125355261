import styled, { css } from 'styled-components'
import { spacing, colors } from 'styles/utilities/variables'

const StyledDivider = styled.div`
  height: 0;
  width: 100%;
  margin: 0;
  padding-top: ${(props) =>
    props.size ? spacing[props.size] : spacing.medium};
  padding-bottom: ${(props) =>
    props.size ? spacing[props.size] : spacing.medium};
  display: block;
  position: relative;
  background: transparent;

  ${(props) =>
    props.border &&
    css`
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        height: 1px;
        //background: ${colors.borderColor};
        background: ${props => props.light ? colors.borderColor : colors.black};
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`

export default StyledDivider
