import React, { useState } from 'react'
import StyledContentExpander from './style'

const ContentExpander = (props) => {
  const { children, open, duration = 0.35 } = props
  const [overflowHidden, setOverflowHidden] = useState(!open)

  return (
    <StyledContentExpander
      animate={{ height: open ? 'auto' : 0 }}
      initial={false}
      transition={{ duration: duration }}
      open={open}
      onAnimationStart={() => {
        if (!open) setOverflowHidden(true)
      }}
      onAnimationComplete={() => {
        setOverflowHidden(!open)
      }}
      overflowHidden={overflowHidden}
    >
      {children}
    </StyledContentExpander>
  )
}

export default ContentExpander
