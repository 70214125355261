import React from 'react'
import { colors } from 'styles/utilities/variables'
import StyledLoader from './Loader.style'
import ClipLoader from 'react-spinners/ClipLoader'

const Loader = ({ size = 50, noMargin = false }) => {
  return (
    <StyledLoader noMargin={noMargin}>
      <ClipLoader
        color={colors.tealDark}
        // loading={loading}
        // css={override}
        size={size}
      />
    </StyledLoader>
  )
}

export default Loader
