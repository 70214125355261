import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

const StyledMobileEditor = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  background: ${colors.grey02};
  overflow: hidden;
  height: 100vh;
  overflow: hidden;
`

StyledMobileEditor.Header = styled.div`
  width: 100%;
  overflow: hidden;
`
StyledMobileEditor.Toolbar = styled.div`
  width: 100%;
  height: 4.8rem;
  overflow: hidden;
  background: ${colors.primary};
`

StyledMobileEditor.Stage = styled.div`
  width: 100%;
  height: calc(100vh - 11.6rem);
`

StyledMobileEditor.ToolbarPane = styled.div`
  display: flex;
  align-items: flex-end;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* min-height: 9.6rem; */
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.16);
  > div {
    width: 100%;
  }
`

StyledMobileEditor.AddButton = styled.div`
  position: fixed;
  right: ${spacing.large};
  bottom: ${spacing.large};
  cursor: pointer;
  // display: flex;
  // width: 100%;
  // justify-content: flex-end;
  // padding-right: ${spacing.large};
`

export default StyledMobileEditor
