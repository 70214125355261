import styled, { css } from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

const StyledTable = styled.section`
  //overflow-y: scroll;
  background: ${colors.white};
  padding-bottom: ${spacing.xLarge};

  display: ${(props) => (props.show ? 'block' : 'none')};
`

StyledTable.StickyRow = styled.div`
  position: sticky;
  top: 0%;
  display: flex;
  background: ${colors.grey01};
  border-bottom: 1px solid ${colors.grey03};
  z-index: 1;
  > div {
    flex-grow: 1;
  }
`

StyledTable.Row = styled.div`
  position: relative;
  display: flex;
  background: ${(props) => (props.isActive ? colors.grey01 : 'transparent')};
  border-bottom: 1px solid ${colors.grey03};
  > div {
    flex-grow: 1;
  }

  ${(props) =>
    props.selected || props.isActive
      ? css`
          /* border-bottom: 1px solid ${colors.primary}; */

          & + & {
            .rowSelectBorder {
              /* border-top: none; */
            }
          }
        `
      : null};
`

StyledTable.RowSelectBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* border: 1px solid ${colors.primary}; */
  border-top: 1px solid ${colors.primary};
  border-bottom: 1px solid ${colors.primary};
  display: ${(props) => (props.selected || props.isActive ? 'block' : 'none')};
`

StyledTable.Header = styled.div`
  min-width: ${(props) => (props.width ? `${props.width}px` : 'initial')};
  max-width: ${(props) => (props.width ? `${props.width}px` : 'initial')};
  padding: ${spacing.medium};
  background: ${colors.grey01};

  &:first-child {
    border-right: 1px solid ${colors.grey03};
  }
`

StyledTable.Column = styled.div`
  min-width: ${(props) => (props.width ? `${props.width}px` : 'initial')};
  max-width: ${(props) => (props.width ? `${props.width}px` : 'initial')};
  border: 1px solid transparent;
  border-right-color: ${colors.grey03};
  margin: 1px 0;

  ${(props) =>
    props.center
      ? `
    display: flex;
    justify-content: center;
    align-items: center;
  `
      : ''};

  input[type='text'] {
    margin: 0;
    padding: 0 ${spacing.medium};
    border: 0;
    line-height: 1.4;
    min-height: ${spacing.xLarge};
    width: 100%;
    background: transparent;
  }

  &:last-child {
    border-right-color: transparent;
  }

  ${(props) =>
    props.invalid &&
    css`
      border-color: ${colors.warning} !important;
    `}
`

StyledTable.RowIcon = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`
export default StyledTable
