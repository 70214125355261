import { css } from 'styled-components'
import { mq } from 'styles/utilities/variables'

// Iterate through the media queries and create a media template
const media = Object.keys(mq).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${mq[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export { media }
