import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'

const List = styled.section`
  height: calc(100vh - 96px);
  overflow: scroll;

  display: ${(props) => (props.show ? 'block' : 'none')};
`

List.Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  // padding: ${(props) => (props.small ? spacing.small : spacing.medium)}
  //   ${spacing.medium};
  padding: ${spacing.medium} ${spacing.medium};
  background: ${colors.grey01};
  border-bottom: 1px solid ${colors.grey03};
  ${(props) => (props.small ? text.body : text.bodyMobile)};

  display: flex;
  align-items: center;
  justify-content: space-between;
`

List.Row = styled.div`
  position: relative;
  display: flex;
  padding: ${spacing.medium} ${spacing.medium} ${spacing.medium} 0;
  border-bottom: 1px solid ${colors.grey03};
  background: ${(props) => (props.active ? colors.grey02 : colors.white)};

  &:after {
    content: '';
    position: absolute;
    ${(props) => 
      `background-color: ${props.active ? colors.blueLight : 'transparent'};`
    }
    top: 1px;
    bottom: 1px;
    width: 3px;
    left: 1px;
  }
`

List.RowTitle = styled.div`
  margin-bottom: ${spacing.xSmall};
  ${(props) => (props.small ? text.small : text.bodyMobile)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => (props.width ? props.width : 'auto')};
`

List.RowAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: max-width 0.1s ease-in-out;
  max-width: 0;
  width: 36px;
  overflow: hidden;

  ${(props) => (props.open ? 'max-width: 36px' : '')}
`

List.RowIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  overflow: hidden;
  margin-left: ${spacing.medium};
  margin-right: -0.6rem;

  svg {
    width: 24px;
    height: 24px;
  }
`

List.RowContent = styled.div`
  position: relative;
  padding-left: ${spacing.medium};
  width: 100%;
`

List.RowMeta = styled.div`
  color: ${colors.grey05};
  ${(props) => (props.small ? text.small : text.body)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => (props.width ? props.width : 'auto')};
`

List.RowError = styled.div`
  color: ${colors.warning};
  ${(props) => (props.small ? text.small : text.body)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin: ${spacing.xSmall} 0;
`

List.RowErrorIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

export default List
