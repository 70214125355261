export const API_BASE = process.env.REACT_APP_API_PATH
export const API_AUTH_BASE = process.env.REACT_APP_AUTH_PATH

export const fetchResources = async (url) => {
  const response = await fetch(`${API_BASE}/${url}`)
  const result = await response.json()

  if (result.results) {
    return result.results
  }

  return result
}
// const fetchAllResources = async (url) => {
//   const response = await fetch(url, requestHeaders)
//   const result = await response.json()
//
//   if (!result.next) {
//     return result.results
//   }
//
//   const nextBatch = await fetchAllResources(result.next)
//
//   return [...result.results, ...nextBatch]
// }
