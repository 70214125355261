import React from 'react'
import StyledLoadBar from './LoadBar.style'

const LoadBar = (props) => {
  const { percentage } = props
  return (
    <StyledLoadBar>
      <StyledLoadBar.Track />
      <StyledLoadBar.Bar width={percentage} />
    </StyledLoadBar>
  )
}

export default LoadBar
