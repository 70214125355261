import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'

const StyledToolbarTabs = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid ${colors.grey03};
`

StyledToolbarTabs.Tab = styled.li`
  flex: 1;
  padding: 15px;
  text-align: center;
  background: ${props =>
    props.isActive ? colors.grey01 : 'rgba(196, 196, 196, 0.3)'};
  font-weight: ${props => (props.isActive ? 'bold' : 'normal')};
  cursor: ${props => (props.isActive ? 'auto' : 'pointer')};

  border-right: 1px solid ${colors.grey03};

  &:last-child {
    border-right: none;
  }
`

export default StyledToolbarTabs
