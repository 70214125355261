import styled from 'styled-components'
import { colors } from 'styles/utilities/variables'

const StyledShareModal = styled.div``

StyledShareModal.Actions = styled.div`
  display: flex;
`

StyledShareModal.SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.success};
  margin-left: auto;
`

export default StyledShareModal
