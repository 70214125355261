import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSignSize } from 'redux/actions/signsActions'
import { cloneSignWithNewSize } from 'helpers/signs'
import { SIGN_TYPES } from 'constants/Signs'
import StyledSizePanel from './SizePanel.style'
import TextInput from 'components/common/TextInput'
import Dropdown from 'components/common/Dropdown'
import { useResponsive } from 'hooks/useResponsive'
import Select from 'components/common/Select'

const SizePanel = (props) => {
  const { breakpoints } = useResponsive()
  // const { panel, activeSign, activeTab } = props
  const [selectedSignSize, setSelectedSignSize] = useState(0)
  const { activeSign } = props
  const dispatch = useDispatch()
  const { prices } = useSelector((state) => {
    return {
      prices: state.app.prices,
    }
  })

  useEffect(() => {
    setSelectedSignSize(
      activeSign
        ? activeSign.settings.sizeChoices.findIndex(
            (size) =>
              size.width === activeSign.values.size.width &&
              size.height === activeSign.values.size.height
          )
        : 0
    )
  }, [activeSign])

  if (!activeSign) {
    return null
  }

  const signType = SIGN_TYPES.filter((signType) => {
    return activeSign.values.type === signType.type
  })[0]

  const matchSizeAgainstSizeChoices = (size) => {
    activeSign.settings.sizeChoices.forEach((sizeChoice) => {
      if (size.width === sizeChoice.width && size.height === sizeChoice.height)
        size.type = sizeChoice.type
    })

    return size
  }

  const handleInputChange = (e, activeSign, property) => {
    const input = parseInt(e.target.value)
    let size = {
      type: 'custom',
      width: property === 'width' ? input : activeSign.values.size.width,
      height: property === 'height' ? input : activeSign.values.size.height,
    }

    size = matchSizeAgainstSizeChoices(size)

    const newSign = cloneSignWithNewSize(activeSign, size, prices)
    dispatch(setSignSize(newSign))
  }

  const handleSignSizeButtonClick = (sizeIndex) => {
    setSelectedSignSize(sizeIndex)

    const newSign = cloneSignWithNewSize(
      activeSign,
      activeSign.settings.sizeChoices[sizeIndex],
      prices
    )
    dispatch(setSignSize(newSign))
  }

  const SelectComponent = breakpoints.tabletPortraitBelow ? Select : Dropdown

  return (
    <StyledSizePanel>
      {activeSign.settings.sizeFixed && (
        <StyledSizePanel.Message>
          {signType.name_plural} har en fast storlek på{' '}
          {activeSign.values.size.width}x{activeSign.values.size.height} mm.
        </StyledSizePanel.Message>
      )}

      {!activeSign.settings.sizeFixed &&
        activeSign.settings.sizeChoices.length > 0 &&
        (
          <StyledSizePanel.Controls>
            <SelectComponent
              options={activeSign.settings.sizeChoices.map((size, index) => ({
                label: `${size.width} x ${size.height} mm`,
                value: index,
              }))}
              currentValue={selectedSignSize}
              handleOptionChange={(value) => handleSignSizeButtonClick(value)}
            />
          </StyledSizePanel.Controls>
        )}

      {activeSign.settings.sizeCustomAllowed && (
        <StyledSizePanel.Actions>
          <StyledSizePanel.Action>
            <TextInput
              label={'Bredd'}
              value={activeSign.values.size.width}
              onChange={(e) => handleInputChange(e, activeSign, 'width')}
            />
          </StyledSizePanel.Action>

          <StyledSizePanel.Action>
            <TextInput
              label={'Höjd'}
              onChange={(e) => handleInputChange(e, activeSign, 'height')}
              value={activeSign.values.size.height}
            />
          </StyledSizePanel.Action>
        </StyledSizePanel.Actions>
      )}
    </StyledSizePanel>
  )
}

export default SizePanel
