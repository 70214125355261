import React from 'react'
import StyledColorPickerButton from './ColorPickerButton.style'

const ColorPickerButton = (props) => {
  const { clickCallback, isActive, colorPrimary, colorSecondary } = props
  return (
    <StyledColorPickerButton
      onClick={() => clickCallback()}
      isActive={isActive}
      color={colorSecondary}
      background={colorPrimary}
    >
      <StyledColorPickerButton.Text>A</StyledColorPickerButton.Text>
    </StyledColorPickerButton>
  )
}

export default ColorPickerButton
