export const TABLE_STAGE = 'tableStage'
export const SINGLE_STAGE = 'singleStage'

export const EType = {
  aside: 'aside',
  desktop: 'desktop',
  mobile: 'mobile',
}

export const EMOJI_REGEX = /\p{Extended_Pictographic}/ug
