import { addToastWithTimer } from 'redux/actions/toastActions'
import { fetchCart, fetchPrices } from 'api/Cart'
import { getData, removeData, setData } from 'helpers/storage'

export const UPDATE_OFFLINE_STATE = 'UPDATE_OFFLINE_STATE'
export const updateOfflineState = (state) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_OFFLINE_STATE,
      payload: state,
    })
  }
}

export const updateOfflineStateAndAddToast = (state, message) => {
  return async (dispatch) => {
    message.id = Math.floor(Math.random() * 100000 + 1)
    await dispatch(updateOfflineState(state))
    await dispatch(addToastWithTimer(message, 4000))
  }
}

export const SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL'
export const showShareModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_SHARE_MODAL,
    })
  }
}

export const HIDE_SHARE_MODAL = 'HIDE_SHARE_MODAL'
export const hideShareModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: HIDE_SHARE_MODAL,
    })
  }
}

export const SHOW_NEW_SIGN_MODAL = 'SHOW_NEW_SIGN_MODAL'
export const showNewSignModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_NEW_SIGN_MODAL,
    })
  }
}

export const HIDE_NEW_SIGN_MODAL = 'HIDE_NEW_SIGN_MODAL'
export const hideNewSignModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: HIDE_NEW_SIGN_MODAL,
    })
  }
}

export const SHOW_PROCEED_TO_CHECKOUT_MODAL = 'SHOW_PROCEED_TO_CHECKOUT_MODAL'
export const showProceedToCheckoutModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_PROCEED_TO_CHECKOUT_MODAL,
    })
  }
}

export const HIDE_PROCEED_TO_CHECKOUT_MODAL = 'HIDE_PROCEED_TO_CHECKOUT_MODAL'
export const hideProceedToCheckoutModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: HIDE_PROCEED_TO_CHECKOUT_MODAL,
    })
  }
}

export const SHOW_DELETE_SIGN_MODAL = 'SHOW_DELETE_SIGN_MODAL'
export const showDeleteSignModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_DELETE_SIGN_MODAL,
    })
  }
}

export const HIDE_DELETE_SIGN_MODAL = 'HIDE_DELETE_SIGN_MODAL'
export const hideDeleteSignModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: HIDE_DELETE_SIGN_MODAL,
    })
  }
}

export const SHOW_IMPORT_SPREADSHEET_MODAL = 'SHOW_IMPORT_SPREADSHEET_MODAL'
export const showImportSpreadsheetModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_IMPORT_SPREADSHEET_MODAL,
    })
  }
}

export const HIDE_IMPORT_SPREADSHEET_MODAL = 'HIDE_IMPORT_SPREADSHEET_MODAL'
export const hideImportSpreadsheetModal = () => {
  return async (dispatch) => {
    await dispatch({
      type: HIDE_IMPORT_SPREADSHEET_MODAL,
    })
  }
}

export const SET_ERROR_POPUP_MESSAGE = 'SET_ERROR_POPUP_MESSAGE'
export const setErrorPopupMessage = (message) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_ERROR_POPUP_MESSAGE,
      payload: message,
    })
  }
}

export const SHOW_ERROR_POPUP = 'SHOW_ERROR_POPUP'
export const showErrorPopup = () => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_ERROR_POPUP,
    })
  }
}

export const HIDE_ERROR_POPUP = 'HIDE_ERROR_POPUP'
export const hideErrorPopup = () => {
  return async (dispatch) => {
    await dispatch({
      type: HIDE_ERROR_POPUP,
    })
  }
}

export const SHOW_CART = 'SHOW_CART'
export const showCart = () => {
  return async (dispatch) => {
    await dispatch({
      type: SHOW_CART,
    })
  }
}

export const HIDE_CART = 'HIDE_CART'
export const hideCart = () => {
  return async (dispatch) => {
    await dispatch({
      type: HIDE_CART,
    })
  }
}

export const FETCH_ERROR = 'FETCH_ERROR'
export const fetchError = () => {
  return async (dispatch) => {
    await dispatch({
      type: FETCH_ERROR,
    })
  }
}

export const fetchErrorWithToast = (message) => {
  return async (dispatch) => {
    await dispatch(fetchError())
    await dispatch(
      addToastWithTimer(
        {
          id: Math.floor(Math.random() * 10000 + 1),
          message: message,
          type: 'warning',
        },
        9000
      )
    )
  }
}

export const UPDATE_CART_HASH = 'UPDATE_CART_HASH'
export const updateCartHash = (hash) => {
  if (hash) {
    setData('cart-hash', hash)
  } else {
    removeData('cart-hash')
    removeData('wc-cart-token')
    removeData('wc-cart-nonce')
  }

  return async (dispatch) => {
    await dispatch({
      type: UPDATE_CART_HASH,
      payload: hash,
    })
  }
}

export const UPDATE_CART = 'UPDATE_CART'
export const updateCart = (payload) => {
  setData('wc-cart-token', payload.token)
  setData('wc-cart-nonce', payload.nonce)
  setData('cart-hash', payload.cart_hash)

  return async (dispatch) => {
    await dispatch({
      type: UPDATE_CART,
      payload: payload,
    })
  }
}

export const FETCH_CART_ITEMS = 'FETCH_CART_ITEMS'
export const fetchCartItems = () => {
  return async (dispatch) => {
    await dispatch({
      type: FETCH_CART_ITEMS,
    })

    try {
      const cartHash = getData('cart-hash')
      const cartResponse = await fetchCart(cartHash)

      if (cartResponse.cart_hash) {
        dispatch(updateCart(cartResponse))
      }
    } catch (err) {
      console.log('🚀 ~ file: appActions.js:238 ~ err:', err)

      await dispatch(
        fetchErrorWithToast('Något gick fel när vi försökte hämta din varukorg')
      )
    }
  }
}

export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const addCartItem = (sign) => {

  return async (dispatch) => {
    await dispatch({
      type: ADD_CART_ITEM,
      payload: { sign },
    })
  }
}

export const UPDATE_PRICES = 'UPDATE_PRICES'
export const updatePrices = (prices) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_PRICES,
      payload: prices,
    })
  }
}

export const FETCH_PRICES = 'FETCH_PRICES'
export const fetchPricesData = () => {
  return async (dispatch) => {
    await dispatch({
      type: FETCH_PRICES,
    })

    try {
      const prices = await fetchPrices()
      dispatch(updatePrices(prices))
    } catch {
      await dispatch(
        fetchErrorWithToast('Något gick fel när vi försökte hämta priser')
      )
    }
  }
}
