import { useState, useEffect } from 'react'
import { mq } from 'styles/utilities/variables'

const calculateBreakpoints = (width) => {
  const breakpoints = { ...mq }
  Object.entries(mq).forEach(([key, val]) => {
    breakpoints[key] = val >= width
  })
  return breakpoints
}

const getWindowSize = () => {
  if (!window) {
    return { width: 0, height: 0 }
  }

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

const useResponsive = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize)
  const [breakpoints, setBreakpoints] = useState(
    calculateBreakpoints(windowSize.width)
  )

  useEffect(() => {
    const handleResize = () => {
      const windowSize = getWindowSize()
      setWindowSize(windowSize)
      setBreakpoints(calculateBreakpoints(windowSize.width))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    windowSize,
    breakpoints,
  }
}

export { useResponsive }
