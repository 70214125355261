import { setSignTextTooWide } from 'redux/actions/signsActions'
import {
  ENGRAVING_COLOR,
  CUT_COLOR,
  CUT_STROKE_WIDTH,
  MOUNTING_HOLE_CUTTING_WIDTH,
} from 'constants/Signs'
import { center } from 'helpers/geometry'
// import { signInnerDimensions, signMountingRequiresHole } from './signs'
import { getRenderSize, signMountingRequiresHole } from './signs'
import { fabric } from 'fabric'
import { EMOJI_REGEX } from 'constants/Editor'

/**
 * Remove all fabric controls
 */
export const removeAllControls = (obj) => {
  obj.lockMovementY = true
  obj.lockMovementX = true
  obj.lockScalingX = true
  obj.lockRotation = true
  obj.hasControls = false
  obj.hasBorders = false
  obj.hoverCursor = 'arrow'
}

const alignMainGroup = (align, canvas, mainGroup, scale) => {
  if (!canvas) return null

  mainGroup.scaleX = scale
  mainGroup.scaleY = scale

  if (align === 'center') {
    mainGroup.top = center(mainGroup.height * scale, canvas.height)
    mainGroup.left = center(mainGroup.width * scale, canvas.width)
  }

  if (align === 'top') {
    mainGroup.top = 0
    mainGroup.left = center(mainGroup.width * scale, canvas.width)
  }
}

const isRectKeyBadgeWithMounting = (sign) => {
  return sign.values.type === 'nyckelbricka-rektangular' &&
    sign.values.mounting.some((mounting) => mounting.type === 'nyckelring')
}

const getMaxTextWidth = (sign) => {
  const width = getRenderSize(sign).width
  const {
    settings: {
      plate: {
        padding,
        screwHoleOffset,
        screwHoleRadius,
        keyHoleOffset,
        keyHoleRadius,
      },
    },
  } = sign

  return isRectKeyBadgeWithMounting(sign)
    ? width -
      (keyHoleOffset +
        MOUNTING_HOLE_CUTTING_WIDTH +
        (keyHoleRadius * 2) +
        (padding * 2)
      )
    : width -
      (padding +
        screwHoleOffset +
        MOUNTING_HOLE_CUTTING_WIDTH +
        (screwHoleRadius * 2)) *
        2
}

const alignTexts = (
  canvas,
  sign,
  textGroup,
  focusedTextbox = null,
  editingTextId = null
) => {
  if (!canvas) return null

  const {
    values: { align },
  } = sign

  const { width, height } = getRenderSize(sign)
  const padding = (width - getMaxTextWidth(sign)) / 2

  textGroup.width = width
  textGroup.height = height

  let texts = textGroup.getObjects()

  // Vertical positioning
  let verticalSpace = 5
  let totalTextHeight = texts.reduce((acc, text) => acc + text.height, 0)
  totalTextHeight += (texts.length - 1) * verticalSpace
  let yPosition = -totalTextHeight / 2

  texts.forEach((text) => {
    text.top = yPosition
    yPosition += text.height + verticalSpace

    // Horizontal positioning
    let xPosition = 0

    if (align === 'left') {
      xPosition = 0 - width / 2 + padding
    } else if (align === 'center') {
      xPosition = 0 - text.width / 2
    } else if (align === 'right') {
      xPosition = width / 2 - padding - text.width
    }

    text.left = xPosition
  })

  // Update input text bounding box
  if (focusedTextbox && editingTextId !== null) {
    if (texts[editingTextId]) {
      // Match visual
      let visualWidth = texts[editingTextId].width
      let visualTop = texts[editingTextId].top
      let visualLeft = texts[editingTextId].left

      focusedTextbox.set('width', visualWidth)
      focusedTextbox.set('top', visualTop)
      focusedTextbox.set('left', visualLeft)
    }
  }
}

const createHoles = (mounting, fill, width, height, shape, isExport, plate) => {
  const screwHoleOffset = plate.screwHoleOffset
  const radius =
    mounting === 'nyckelring'
      ? plate.keyHoleRadius
      : plate.screwHoleRadius
  const config = {
    radius,
    fill,
    ...(isExport
      ? {
          stroke: CUT_COLOR,
          strokeWidth: CUT_STROKE_WIDTH,
        }
      : {}),
  }

  let holes = []

  switch (mounting) {
    case 'nyckelring':
      // Centered top
      if (shape === 'circle') {
        holes.push(
          new fabric.Circle({
            top: plate.keyHoleOffset + MOUNTING_HOLE_CUTTING_WIDTH,
            left: width / 2 - radius,
            ...config,
          })
        )
      }

      // Top left
      if (shape === 'rect') {
        holes.push(
          new fabric.Circle({
            top: height / 2 - radius,
            left: plate.keyHoleOffset + MOUNTING_HOLE_CUTTING_WIDTH,
            ...config,
          })
        )
      }

      break

    case '2-skruvhal':
      holes.push(
        new fabric.Circle({
          top: height / 2 - radius,
          left: screwHoleOffset + MOUNTING_HOLE_CUTTING_WIDTH,
          ...config,
        })
      )
      holes.push(
        new fabric.Circle({
          top: height / 2 - radius,
          left: width - (radius * 2 + MOUNTING_HOLE_CUTTING_WIDTH + screwHoleOffset),
          ...config,
        })
      )
      break

    case '4-skruvhal':
      holes.push(
        new fabric.Circle({
          top: screwHoleOffset + MOUNTING_HOLE_CUTTING_WIDTH,
          left: screwHoleOffset + MOUNTING_HOLE_CUTTING_WIDTH,
          ...config,
        })
      )
      holes.push(
        new fabric.Circle({
          top: screwHoleOffset + MOUNTING_HOLE_CUTTING_WIDTH,
          left: width - (radius * 2 + MOUNTING_HOLE_CUTTING_WIDTH + screwHoleOffset),
          ...config,
        })
      )
      holes.push(
        new fabric.Circle({
          top: height - (radius * 2 + MOUNTING_HOLE_CUTTING_WIDTH + screwHoleOffset),
          left: screwHoleOffset + MOUNTING_HOLE_CUTTING_WIDTH,
          ...config,
        })
      )
      holes.push(
        new fabric.Circle({
          top: height - (radius * 2 + MOUNTING_HOLE_CUTTING_WIDTH + screwHoleOffset),
          left: width - (radius * 2 + MOUNTING_HOLE_CUTTING_WIDTH + screwHoleOffset),
          ...config,
        })
      )
      break

    default:
      break
  }

  return holes
}

const addMountingHolesToGroup = (sign, group, isExport) => {
  const { mounting, shape } = sign.values
  const { plate } = sign.settings
  const size = getRenderSize(sign)

  mounting
    .filter((mounting) => signMountingRequiresHole(mounting.type))
    .forEach((mounting) => {
      let fill =
        sign.values.theme.colorPrimary === '#ffffff' ? '#D0D0D0' : '#ffffff'
      fill = isExport ? 'transparent' : fill

      createHoles(
        mounting.type,
        fill,
        size.width,
        size.height,
        shape,
        isExport,
        plate,
      ).forEach((hole) => {
        group.addWithUpdate(hole)
      })
    })
}

const createBackground = (sign, isExport) => {
  const { width, height } = getRenderSize(sign)
  const { cornerRadius = 0 } = sign.settings ? sign.settings.plate : false
  const rx = cornerRadius
  const ry = cornerRadius
  const fill = isExport ? 'transparent' : sign.values.theme.colorPrimary
  const exportConfig = isExport
    ? {
        stroke: CUT_COLOR,
        strokeWidth: CUT_STROKE_WIDTH,
      }
    : {}

  switch (sign.values.shape) {
    case 'rect':
      return new fabric.Rect({ width, height, fill, rx, ry, ...exportConfig })

    case 'circle':
      return new fabric.Circle({ radius: width / 2, fill, ...exportConfig })

    case 'ellipse':
      return new fabric.Ellipse({
        width: width / 2,
        height: height / 2,
        fill,
        rx: width / 2,
        ry: height / 2,
        ...exportConfig,
      })

    default:
      break
  }
}


// ändring

const addTextsToGroup = (sign, group, isExport, dispatch) => {
  const { colorSecondary, fontFamily } = sign.values.theme
  const { textSize, tooWideTextRowIds, shape } = sign.values
  const width = getRenderSize(sign).width
  const {
    fontSizes,
    plate: { padding = 0 },
  } = sign.settings

  // const container = signInnerDimensions(sign)

  const numLinesWithContent = sign.values.text.filter(
    (text) => text.content
  ).length
  let atIndexWithContent = 0
  // const hasMountingHoles = sign.values.mounting.some(
  //   (mounting) =>
  //     mounting.type === '2-skruvhal' || mounting.type === '4-skruvhal'
  // )

  let maxWidth = getMaxTextWidth(sign)

  sign.values.text.forEach((text) => {
    const { content, rowId } = text
    let tooWide = false
    let textBox = null

    if (content) {
      // const textBox = new fabric.Textbox(content, {
      textBox = new fabric.IText('', {
        left: isRectKeyBadgeWithMounting(sign) ? (width - maxWidth) / 2 - padding : 0,
        lineHeight: 1,
        fontWeight: 400,
        editable: true,
        selectable: true,
        editingBorderColor: '#ff0000',
        fill: isExport ? ENGRAVING_COLOR : colorSecondary,
        charSpacing: fontFamily === 'Libre Baskerville' ? 20 : 0,
        // textAlign: align,
        fontSize: fontSizes[textSize],
        fontFamily: fontFamily,
        // width: container.width,
        // backgroundColor: '#800080', // for dev
        cursorWidth: 3,
        // cursorDelay: 1000,
        // cursorDuration: 1000,
      })

      let lineMaxWidth = maxWidth

      // Lines limit case for ellipse sign
      if (shape === 'ellipse') {
        // A bit shorter lines (90%) on both lines if there are two lines
        if (numLinesWithContent === 2) {
          lineMaxWidth = maxWidth * 0.9
        }

        // Shorter lines (60%) on first and last line if there are three lines
        if (numLinesWithContent === 3) {
          lineMaxWidth =
            atIndexWithContent === 0 ||
            atIndexWithContent === numLinesWithContent - 1
              ? maxWidth * 0.6
              : maxWidth
        }
      }

      // Loop through text and check width
      let len = content.length
      let cleanContent = content.replaceAll(EMOJI_REGEX, '')

      for (let i = 0; i < len; i++) {
        let str = cleanContent.substr(0, i + 1)
        textBox.set('text', str)
        tooWide = textBox.width > lineMaxWidth

        if (tooWide) {
          // Remove last character
          let allowedStr = str.slice(0, -1)
          textBox.set('text', allowedStr)
          if (dispatch && !tooWideTextRowIds.includes(rowId)) {
            dispatch(setSignTextTooWide(sign, rowId, true))
          }

          break
        }
      }

      atIndexWithContent++
    }

    if (dispatch && !tooWide && tooWideTextRowIds.includes(rowId)) {
      dispatch(setSignTextTooWide(sign, rowId, false))
    }

    if (textBox) group.addWithUpdate(textBox)
  })
}

const drawDebugLines = (sign, backgroundGroup) => {
  // console.log('🚀 ~ file: fabric.js:391 ~ sign:', sign)
  const makeLine = (coords, color = 'red') => {
    return new fabric.Line(coords, {
      fill: color,
      stroke: color,
      strokeWidth: .3,
      selectable: false,
      evented: false,
    });
  }
  const plate = sign.settings.plate

  const { width, height } = getRenderSize(sign)

  const holeOffset =
    (isRectKeyBadgeWithMounting(sign)
      ? plate.keyHoleOffset
      : plate.screwHoleOffset) +
    (sign.settings.mountingChoices.some(
      (mounting) =>
        mounting.type.includes('skruvhal') ||
        mounting.type.includes('nyckelring')
    )
      ? MOUNTING_HOLE_CUTTING_WIDTH
      : 0)

  if (isRectKeyBadgeWithMounting(sign)) {
    const holeEnd = holeOffset + plate.keyHoleRadius * 2
    const withPadding = holeEnd + plate.padding

    backgroundGroup.addWithUpdate(makeLine([ holeOffset, 0, holeOffset, height ], 'hotpink'))
    backgroundGroup.addWithUpdate(makeLine([ holeEnd, 0, holeEnd, height ], 'tomato'))
    backgroundGroup.addWithUpdate(makeLine([ withPadding, 0, withPadding, height ], 'lime'))
    backgroundGroup.addWithUpdate(makeLine([ width - plate.padding, 0, width - plate.padding, height ], 'lime'))
  } else {
    const holeEnd = holeOffset + plate.screwHoleRadius * 2
    const withPadding = holeEnd + plate.padding

    backgroundGroup.addWithUpdate(makeLine([ holeOffset, 0, holeOffset, height ], 'hotpink'))
    backgroundGroup.addWithUpdate(makeLine([ holeEnd, 0, holeEnd, height ], 'tomato'))
    backgroundGroup.addWithUpdate(makeLine([ withPadding, 0, withPadding, height ], 'lime'))
    backgroundGroup.addWithUpdate(makeLine([ width - withPadding, 0, width - withPadding, height ], 'lime'))
    backgroundGroup.addWithUpdate(makeLine([ width - holeEnd, 0, width - holeEnd, height ], 'tomato'))
    backgroundGroup.addWithUpdate(makeLine([ width - holeOffset, 0, width - holeOffset, height ], 'hotpink'))
  }
}

export const renderSignToCanvas = (
  sign,
  canvas,
  canvasWidth,
  canvasHeight,
  scale = 1,
  beforeRenderCallback,
  isExport = false,
  dispatch = null,
  focusedTextbox = null,
  editingTextId = null,
  isMobile = false,
) => {
  const coordinates = {
    left: 0,
    top: 0,
  }
  const textGroup = new fabric.Group(null, coordinates)
  const backgroundGroup = new fabric.Group(null, coordinates)
  const mainGroup = new fabric.Group(null, coordinates)

  backgroundGroup.addWithUpdate(createBackground(sign, isExport))

  if (process.env.NODE_ENV === 'development') {
    drawDebugLines(sign, backgroundGroup)
  }

  addMountingHolesToGroup(sign, backgroundGroup, isExport)
  addTextsToGroup(sign, textGroup, isExport, dispatch)

  removeAllControls(mainGroup)

  canvas.clear()
  canvas.setWidth(canvasWidth)
  canvas.setHeight(canvasHeight)

  mainGroup.addWithUpdate(backgroundGroup)
  mainGroup.addWithUpdate(textGroup)

  alignTexts(canvas, sign, textGroup, focusedTextbox, editingTextId)
  alignMainGroup(isMobile ? 'top' : 'center', canvas, mainGroup, scale)

  canvas.add(mainGroup)

  if (beforeRenderCallback) {
    beforeRenderCallback()
  }

  canvas.requestRenderAll()
}

export const createVirtualCanvas = (id) => {
  let canvasEl = document.createElement('canvas')
  canvasEl.id = id
  return new fabric.Canvas(canvasEl.id, {
    selection: false,
  })
}

export const renderSignForExport = (sign, canvasWidth, canvasHeight) => {
  const canvas = createVirtualCanvas('export-canvas')

  renderSignToCanvas(
    sign,
    canvas,
    canvasWidth,
    canvasHeight,
    1,
    undefined,
    true
  )
  // canvas.dispose()
  return canvas
}

export const generateExportSVG = (canvas, width, height) => {
  const svg = canvas.toSVG({
    suppressPreamble: true,
    viewBox: {
      x: 0,
      y: 0,
      width: width,
      height: height,
    },
    width: width,
    height: height,
  })

  let svgBlob = new Blob([svg], {
    type: 'image/svg+xml;charset=utf-8',
  })

  return svgBlob
}

export const generatePNG = (canvas) => {
  const dataURL = canvas.toDataURL({
    format: 'png',
    multiplier: 2,
  })

  return dataURL
}
