import { postUpdateCartItem } from 'api/Cart'
import {
  duplicateSignInList,
  duplicateSignInListWithoutText,
  signType,
} from 'helpers/signs'
import { UPDATE_CART } from 'redux/actions/appActions'

import {
  SET_SIGN_TEXT,
  SET_SIGN_TEXT_SIZE,
  SET_SIGN_THEME,
  SET_CUSTOM_SIGN_COLORS,
  SET_CUSTOM_SIGN_FONT,
  SET_SIGN_SIZE,
  SET_SIGN_SHAPE,
  SET_SIGN_TEXT_TOO_WIDE,
  DUPLICATE_SIGNS,
  DUPLICATE_SIGNS_NO_TEXT,
  SET_SIGN_TYPE,
  SET_SIGN_TEXT_ALIGNMENT,
  TOGGLE_SIGN_MOUNTING,
  RECALCULATE_SIGN_COORDINATES,
  ADD_SIGN,
  ADD_SIGNS,
  GENERATE_SIGN_IMAGE,
  DELETE_SIGNS,
  DELETE_ALL_SIGNS,
} from 'redux/actions/signsActions'

const initialState = {
  signs: [],
}

const signsReducer = (state = initialState, action) => {
  let newSigns
  switch (action.type) {
    case SET_SIGN_THEME:
    case SET_CUSTOM_SIGN_COLORS:
    case SET_CUSTOM_SIGN_FONT:
    case SET_SIGN_SIZE:
    case SET_SIGN_TYPE:
    case RECALCULATE_SIGN_COORDINATES:
    case SET_SIGN_TEXT_SIZE:
    case SET_SIGN_TEXT_ALIGNMENT:
    case SET_SIGN_TEXT:
    case SET_SIGN_SHAPE:
    case SET_SIGN_TEXT_TOO_WIDE:
    case TOGGLE_SIGN_MOUNTING:
      return {
        ...state,
        signs: state.signs.map((item) => {
          if (action.payload.values.id !== item.values.id) {
            return item
          }

          const updatedItem = {
            ...item,
            ...action.payload,
          }

          if (updatedItem.item_id) {
            postUpdateCartItem(updatedItem)
          }

          return updatedItem
        }),
      }
    case UPDATE_CART:
      const signs = state.signs.map(sign => {
        const cartSign = action.payload.cart.items
          .flatMap((item) => item.signs ?? [])
          .find((cartSign) => cartSign.id === sign.values.id)

        return {
          ...cartSign,
          ...sign,
        }
      })

      const createSigns = action.payload.cart.items
        .flatMap(({ signs }) => signs)
        .filter((sign) => !!sign)
        .filter(
          (sign) =>
            !state.signs.some((stateSign) => stateSign.values.id === sign.id)
        )
        .map((sign) => ({
          ...signType(sign.type, { values: sign }),
          values: {
            ...sign,
            text: sign.text.map(text => ({
              ...text,
              content: text.content ?? ''}))
          },
        }))

      return {
        ...state,
        signs: [...signs, ...createSigns]
      }
    case DELETE_ALL_SIGNS:
      return {
        ...state,
        signs: [],
      }
    case ADD_SIGN:
      newSigns = [...state.signs, action.payload]
      return {
        ...state,
        signs: newSigns,
      }
    case ADD_SIGNS:
      newSigns = [...state.signs, ...action.payload]
      return {
        ...state,
        signs: newSigns,
      }
    case GENERATE_SIGN_IMAGE:
      newSigns = [
        ...state.signs.filter(
          (sign) => sign.values.id !== action.payload.values.id
        ),
        action.payload,
      ]
      return {
        ...state,
        signs: newSigns,
      }
    case DUPLICATE_SIGNS:
      newSigns = duplicateSignInList(action.payload, state.signs)
      return {
        ...state,
        signs: newSigns,
      }
    case DUPLICATE_SIGNS_NO_TEXT:
      newSigns = duplicateSignInListWithoutText(action.payload, state.signs)
      return {
        ...state,
        signs: newSigns,
      }
    case DELETE_SIGNS:
      newSigns = state.signs.filter(
        (sign) => action.payload.indexOf(sign.values.id) < 0
      )
      return {
        ...state,
        signs: newSigns,
      }
    default:
      return state
  }
}

export { signsReducer, initialState }
