import { postOrder } from 'api/Order'
import { createPayment, fetchCurrentPaymentStatus, fetchPayment } from 'api/Payment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCart, updateCartHash } from 'redux/actions/appActions'
import { updateCustomerProp } from 'redux/actions/customerActions'
import { useHistory, useParams } from 'react-router-dom'
import Loader from 'components/common/Loader'
import Button from 'components/common/Button'
import { Heading } from 'components/common/Heading'
import Section from 'components/common/Section'
import { Divider } from 'components/common/Divider'
import { Form } from 'components/common/Form'
import { Grid } from 'components/common/Grid'
import Cart from 'components/common/Cart/Cart'
import CustomerView from '../CustomerView'
import { useResponsive } from 'hooks/useResponsive'
import { SpaceBetweenDiv } from './style'
import IconLink from 'components/common/IconLink'
import { deleteAllSigns, generateImageForAllSigns } from 'redux/actions/signsActions'
import CustomerAddressForm from './CustomerAddressForm'
import { emptyCart } from 'redux/actions/cartActions'
import TextInput from 'components/common/TextInput'
import { postApplyCoupon } from 'api/Cart'

const stateSelector = (state) => {
  return {
    customer: state.customer,
    signs: JSON.parse(JSON.stringify(state.cart.signs)),
    wcCart: state.cart.wcCart,
    cartHash: state.cart.cartHash,
    shipping: state.app.shipping,
  }
}

const Checkout = () => {
  const { breakpoints } = useResponsive()
  let paymentSectionRef = useRef(null)
  const { wcCart, signs, cartHash, customer } = useSelector(stateSelector)
  const {
    customer_note,
    customShippingAddress,
    billingAddress,
    shippingAddress,
  } = customer
  const dispatch = useDispatch()
  const history = useHistory()
  const [billingAddressIsValid, setBillingAddressIsValid] = useState(false)
  const [shippingAddressIsValid, setShippingAddressIsValid] = useState(false)
  const [agreedToToS, setAgreedToToS] = useState(false)
  const [coupon, setCoupon] = useState()
  const [invalidCouponMessage, setInvalidCouponMessage] = useState()

  // Handle checkout data
  const [paymentData, setPaymentData] = useState(null)
  // Handle done payment
  const [paymentId] = useState(null)
  const [loadingPaymentWindow, setLoadingPaymentWindow] = useState(false)
  const { paymentId: paymentIdParam } = useParams()

  const applyCoupon = async () => {
    const response = await postApplyCoupon(coupon)

    if (response.cart) {
      dispatch(updateCart(response))
      setInvalidCouponMessage(undefined)
    } else {
      setInvalidCouponMessage(response.message)
    }
  }

  useEffect(() => {
    dispatch(updateCustomerProp('couponCode', undefined))
  }, [])

  const handleOrderWithoutPayment = async (e) => {
    e.preventDefault()

    await dispatch(generateImageForAllSigns())

    const order = await postOrder({
      payment_id: '123456',
      hash: cartHash,
      customer: customer,
      tomolo_payment_id: paymentIdParam,
    })

    if (order.id) {
      dispatch(deleteAllSigns())
      dispatch(emptyCart())
      dispatch(updateCartHash(undefined))

      history.push(`/confirmation/${order.id}`)
    }
  }

  useEffect(() => {
    if (!paymentData?.paymentOrder?.id) {
      return
    }

    const interval = setInterval(async () => {
      // Check payment status
      const { order, currentPayment, payment } = await fetchCurrentPaymentStatus(paymentData?.paymentOrder?.id)

      // Payment is complete, redirect to confirmation
      if (
        currentPayment?.payment?.transactions?.transactionList?.length > 0 &&
        order?.id &&
        payment.status === 'COMPLETE'
      ) {
        dispatch(deleteAllSigns())
        dispatch(emptyCart())
        dispatch(updateCartHash(undefined))

        history.push(`/confirmation/${order.id}`)
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [paymentData?.paymentOrder?.id])

  /**
   * Handle Payment
   */
  const handlePayment = async (e) => {
    setLoadingPaymentWindow(true)
    e.preventDefault()
    if (breakpoints.tabletPortraitBelow) {
      paymentSectionRef.current.scrollIntoView()
    }
    await dispatch(generateImageForAllSigns())
    const response = await createPayment(customer)

    if (response?.couponCodeIsValid) {
    } else {
      setPaymentData(response)
    }

    setLoadingPaymentWindow(false)
  }

  /**
   * If a payment id is in the url params we fetch the payment details
   */
  useEffect(() => {
    if (paymentIdParam) {
      setLoadingPaymentWindow(true)
      ;(async function () {
        try {
          const paymentResponse = await fetchPayment(paymentIdParam)
          const { paymentData, order, payment } = paymentResponse
          switch (payment?.status) {
            case 'COMPLETE':
              history.push(`/confirmation/${order.id}`)

              break;
            case 'FAILED':
              console.log('🚀 ~ file: index.js:147 ~ order:', order)
              console.log('🚀 ~ file: index.js:147 ~ paymentData:', paymentData)
              console.log('FAILED')

              break;
            default:
              break;
          }

          setPaymentData(paymentData)
        } catch (error) {
          console.log(error)
        }

        setLoadingPaymentWindow(false)
      })()
    }
  }, [paymentIdParam, history])

  /**
   * Create iframe
   */
  useEffect(() => {
    if (paymentData) {
      let script = document.createElement('script')

      const operation = paymentData.operations.find(function (o) {
        return o.rel === 'view-paymentorder'
      })
      script.setAttribute('src', operation.href)
      script.onload = function () {
        window.payex.hostedView
          .paymentMenu({
            container: 'payment-menu',
            culture: 'sv-SE',
            onPaymentCompleted: async (paymentCompletedEvent) => {
              console.log('🚀 ~ file: index.js:171 ~ paymentCompletedEvent:', paymentCompletedEvent)
            },
            onPaymentFailed: (paymentFailedEvent) => {
              console.log(paymentFailedEvent)
            },
            onPaymentCreated: (paymentCreatedEvent) => {
              console.log(paymentCreatedEvent)
            },
            onPaymentToS: (event) => {
              window.open(event.openUrl, '_blank')
            },
            onPaymentMenuInstrumentSelected: (
              paymentMenuInstrumentSelectedEvent
            ) => {
              console.log(paymentMenuInstrumentSelectedEvent)
            },
            onError: (error) => {
              console.error(error)
            },
          })
          .open()
      }
      var head = document.getElementsByTagName('head')[0]
      head.appendChild(script)

      return function () {
        script.remove(script)
      }
    }
  }, [paymentData])

  const customerAddressIsValid =
    billingAddressIsValid &&
    (customShippingAddress ? shippingAddressIsValid : true)

  return (
    <CustomerView>
      <Heading size={1}>Kassa</Heading>
      <Divider size={'small'} />

      {!paymentId ? (
        <>
          <SpaceBetweenDiv>
            {/* <IconLink
              to={'/login'}
              text={'Redan kund? Logga in här'}
              icon={'user'}
            /> */}
            <IconLink
              to={'/'}
              text={'Ändra något? Återgå till verktyget'}
              icon={'pen'}
            />
          </SpaceBetweenDiv>
          <Divider size={'small'} />
          <Section>
            <Heading size={2}>Summering</Heading>
            <Divider />
            <Cart
              signs={signs}
              checkout={true}
            />
          </Section>

          <Grid col={breakpoints.tabletPortraitBelow ? 1 : 2}>
            <Section>
              <div>
                <Heading size={2}>Faktureringsadress</Heading>
                <Divider />
                <Form onSubmit={handlePayment}>
                  <CustomerAddressForm
                    address={billingAddress}
                    customerNote={customer_note}
                    onValid={(isValid) => setBillingAddressIsValid(isValid)}
                    onCustomerNoteChange={(e) =>
                      dispatch(
                        updateCustomerProp('customer_note', e.target.value)
                      )
                    }
                    handleChange={(key, value) => {
                      let newBillingAddress = {
                        ...billingAddress,
                        [key]: value,
                      }
                      dispatch(
                        updateCustomerProp('billingAddress', newBillingAddress)
                      )
                    }}
                  />

                  <Form.Group>
                    <label htmlFor="custom-shipping-address">Leverera till en annan address?</label>
                    <input
                      type={'checkbox'}
                      id="custom-shipping-address"
                      onChange={(e) =>
                        dispatch(
                          updateCustomerProp(
                            'customShippingAddress',
                            e.target.checked
                          )
                        )
                      }
                    />
                  </Form.Group>

                  {customShippingAddress && (
                    <>
                      <Divider size={'xSmall'} />
                      <Heading size={2}>Leveransadress</Heading>
                      <Divider />

                      <CustomerAddressForm
                        address={shippingAddress}
                        shipping
                        onValid={(isValid) =>
                          setShippingAddressIsValid(isValid)
                        }
                        handleChange={(key, value) => {
                          let newShippingAddress = {
                            ...shippingAddress,
                            [key]: value,
                          }
                          dispatch(
                            updateCustomerProp(
                              'shippingAddress',
                              newShippingAddress
                            )
                          )
                        }}
                      />
                    </>
                  )}

                  <Form.Group>
                    <input
                      type={'checkbox'}
                      id="acceptedMarketing"
                      onChange={(e) =>
                        dispatch(
                          updateCustomerProp(
                            'acceptedMarketing',
                            e.target.checked
                          )
                        )
                      }
                    />
                    <label htmlFor="acceptedMarketing">
                      Jag vill ta emot nyhetsbrev och annan marknadsföring
                    </label>
                    <br/>

                    <input
                      type={'checkbox'}
                      id="tos-agreement"
                      onChange={(e) =>
                        setAgreedToToS(e.target.checked)
                      }
                    />
                    <label htmlFor="tos-agreement">
                      Jag godkänner <a href={process.env.REACT_APP_INFOWEB_URL + '/vara-kopvillkor/'}>köpvillkoren</a></label>
                  </Form.Group>


                  <Grid
                    col={breakpoints.mobileDown ? 1 : 2}
                    rowGap={breakpoints.mobileDown ? 'none' : undefined}
                  >
                    <Form.Group>
                      <label>Rabattkod</label>
                      <TextInput
                        onChange={(e) => {
                          setCoupon(e.target.value)
                        }}
                      />
                      {invalidCouponMessage && (
                        <p>{invalidCouponMessage}</p>
                      )}
                    </Form.Group>

                    <Form.Group
                      style={{
                        marginBottom: breakpoints.mobileDown ? '1.8rem' : 0,
                        marginTop: breakpoints.mobileDown ? 0 : '1.8rem',
                      }}
                    >
                      <Button
                        disabled={!coupon || coupon?.length === 0}
                        handleButtonClick={(e) => {
                          e.preventDefault()
                          applyCoupon()
                        }}
                      >
                        Använd
                      </Button>
                    </Form.Group>
                  </Grid>

                  {!paymentData && (
                    <>
                      {parseInt(wcCart?.totals?.total_price) === 0 &&
                      wcCart?.items?.length > 0 ? (
                        <Button
                          disabled={
                            !agreedToToS ||
                            !customerAddressIsValid ||
                            signs?.length < 1
                          }
                          handleButtonClick={handleOrderWithoutPayment}
                        >
                          Lägg beställning
                        </Button>
                      ) : (
                        <Button
                          disabled={
                            !agreedToToS ||
                            !customerAddressIsValid ||
                            signs?.length < 1
                          }
                          handleButtonClick={handlePayment}
                        >
                          Betala
                        </Button>
                      )}
                    </>
                  )}
                </Form>
              </div>
            </Section>

            <Section ref={paymentSectionRef}>
              <Heading size={2}>Betalning</Heading>
              <Divider />
              {!paymentData && !loadingPaymentWindow && (
                <div>Fyll i uppgifter för att komma vidare...</div>
              )}
              {loadingPaymentWindow && <Loader />}
              <div id={'payment-menu'} />
            </Section>
          </Grid>
        </>
      ) : (
        <>
          <Divider size={'medium'} />
          <Section>
            <Heading size={2}>Skapar order, var god vänta...</Heading>
            <Divider />
            <Loader />
            <Divider />
          </Section>
        </>
      )}
    </CustomerView>
  )
}

export default Checkout
