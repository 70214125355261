import {
  createVirtualCanvas,
  generatePNG,
  renderSignToCanvas,
} from 'helpers/fabric'
import { deepClone } from 'helpers/helpers'
import { cloneSignWithNewType, getRenderSize, signTheme } from 'helpers/signs'
import { setActiveEditorItem } from 'redux/actions/editorActions'

export const SET_SIGN_TEXT = 'SET_SIGN_TEXT'
export const setSignText = (sign) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SIGN_TEXT,
      payload: sign,
    })
  }
}

export const RECALCULATE_SIGN_COORDINATES = 'RECALCULATE_SIGN_COORDINATES'
export const recalculateSignCoordinates = (sign, size) => {
  return async (dispatch) => {
    const newSign = {
      ...sign,
      // @TODO - fix below
      // textLayouts: sign.textLayouts.map(
      //   (text): SignTextLayouts => {
      //     const scales = {
      //       small: 0.8,
      //       medium: 1,
      //       large: 1.1
      //     }
      //     const newScale = scales[size]
      //     return {
      //       ...text,
      //       scale: newScale
      //     }
      //   }
      // )
    }

    await dispatch({
      type: RECALCULATE_SIGN_COORDINATES,
      payload: newSign,
    })
  }
}

export const SET_SIGN_TEXT_SIZE = 'SET_SIGN_TEXT_SIZE'
export const setSignTextSize = (sign, size) => {
  return async (dispatch) => {
    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        textSize: size,
      },
    }
    await dispatch({
      type: SET_SIGN_TEXT_SIZE,
      payload: newSign,
    })

    // await dispatch(recalculateSignCoordinates(newSign, size))
  }
}

export const SET_SIGN_TEXT_ALIGNMENT = 'SET_SIGN_TEXT_ALIGNMENT'
export const setSignTextAlignment = (sign, alignment) => {
  return async (dispatch) => {
    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        align: alignment,
      },
    }
    await dispatch({
      type: SET_SIGN_TEXT_ALIGNMENT,
      payload: newSign,
    })

    // await dispatch(recalculateSignCoordinates(newSign, size))
  }
}

export const SET_SIGN_THEME = 'SET_SIGN_THEME'
export const setSignTheme = (sign, theme) => {
  return async (dispatch) => {
    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        theme: {
          ...sign.values.theme,
          ...signTheme(theme),
        },
      },
    }
    await dispatch({
      type: SET_SIGN_THEME,
      payload: newSign,
    })
  }
}

export const SET_CUSTOM_SIGN_COLORS = 'SET_CUSTOM_SIGN_COLORS'
export const setCustomSignColors = (sign, colorPrimary, colorSecondary) => {
  return async (dispatch) => {
    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        theme: {
          ...sign.values.theme,
          name: 'Custom',
          type: 'Custom',
          colorPrimary: colorPrimary,
          colorSecondary: colorSecondary,
        },
      },
    }
    await dispatch({
      type: SET_CUSTOM_SIGN_COLORS,
      payload: newSign,
    })
  }
}

export const SET_CUSTOM_SIGN_FONT = 'SET_CUSTOM_SIGN_FONT'
export const setCustomSignFont = (sign, fontFamily) => {
  return async (dispatch) => {
    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        theme: {
          ...sign.values.theme,
          name: 'Custom',
          type: 'Custom',
          fontFamily: fontFamily,
        },
      },
    }
    await dispatch({
      type: SET_CUSTOM_SIGN_FONT,
      payload: newSign,
    })
  }
}

export const SET_SIGN_TYPE = 'SET_SIGN_TYPE'
export const setSignType = (sign, type) => {
  return async (dispatch) => {
    const newSign = cloneSignWithNewType(sign, type)
    await dispatch({
      type: SET_SIGN_TYPE,
      payload: newSign,
    })
  }
}

// export const SET_SIGN_MOUNTING = 'SET_SIGN_MOUNTING'
// export const setSignMounting = (sign) => {
//   return async (dispatch) => {
//     await dispatch({
//       type: SET_SIGN_MOUNTING,
//       payload: sign,
//     })
//   }
// }

const toggleMounting = (mountings, currentMounting) => {
  if (currentMounting.type === 'none') {
    return [{ type: 'none' }]
  }

  let newMountings = []
  const mountingsWithoutNone = mountings.filter(
    (mounting) => mounting.type !== 'none'
  )
  const hasMounting = mountingsWithoutNone.find(
    (mounting) => mounting.type === currentMounting.type
  )

  // Nål and magnet types are not combineable
  if (
    currentMounting.type === 'nal' &&
    mountings.find((mounting) => mounting.type === 'magnet')
  ) {
    return [
      ...mountings.filter((mounting) => mounting.type !== 'magnet'),
      currentMounting,
    ]
  }
  // Nål and magnet types are not combineable
  if (
    currentMounting.type === 'magnet' &&
    mountings.find((mounting) => mounting.type === 'nal')
  ) {
    return [
      ...mountings.filter((mounting) => mounting.type !== 'nal'),
      currentMounting,
    ]
  }

  if (hasMounting) {
    newMountings = mountingsWithoutNone.filter(
      (mounting) => mounting.type !== currentMounting.type
    )
  } else {
    newMountings = [...mountingsWithoutNone, currentMounting]
  }
  return newMountings
}

const getAddOnProducts = (mounting, prices) => {
  return mounting.reduce((acc, currentMounting) => {
    const priceNode = prices[currentMounting.type]
      ? prices[currentMounting.type]
      : null

    const mountingAddOnProduct = priceNode
      ? {
          price: priceNode.price,
          tax: priceNode.tax,
          total: priceNode.total,
          id: priceNode.id,
          name: currentMounting.name, // For visual
          type: currentMounting.type, // For visual
        }
      : null

    return mountingAddOnProduct ? [...acc, mountingAddOnProduct] : acc
  }, [])
}

export const TOGGLE_SIGN_MOUNTING = 'TOGGLE_SIGN_MOUNTING'
export const toggleSignMounting = (sign, mounting) => {
  return async (dispatch, getState) => {
    const newMounting = toggleMounting(sign.values.mounting, mounting)

    // Mountings should result in addOnProducts
    const prices = getState().app.prices

    // @TODO, currently not taking previous addOnProducts into account
    // Not an issue right now, since mountings is the only available addOnProduct
    // But perhaps take into account in the future and compare
    const newAddOnProducts = getAddOnProducts(newMounting, prices)
    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        mounting: newMounting,
        addOnProducts: newAddOnProducts,
      },
    }
    await dispatch({
      type: TOGGLE_SIGN_MOUNTING,
      payload: newSign,
    })
  }
}

export const SET_SIGN_SHAPE = 'SET_SIGN_SHAPE'
export const setSignShape = (sign, shape) => {
  return async (dispatch) => {
    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        shape: shape,
      },
    }
    await dispatch({
      type: SET_SIGN_SHAPE,
      payload: newSign,
    })
  }
}

export const SET_SIGN_SIZE = 'SET_SIGN_SIZE'
export const setSignSize = (sign) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_SIGN_SIZE,
      payload: sign,
    })
  }
}

export const SET_SIGN_TEXT_TOO_WIDE = 'SET_SIGN_TEXT_TOO_WIDE'
export const setSignTextTooWide = (sign, rowId, isTooWide) => {
  return async (dispatch) => {
    const {
      values: { tooWideTextRowIds },
    } = sign

    let updatedTooWideTextRowIds = []

    // Add
    if (isTooWide) {
      updatedTooWideTextRowIds = tooWideTextRowIds.includes(rowId)
        ? tooWideTextRowIds
        : [...tooWideTextRowIds, rowId]
    }

    // Remove
    if (!isTooWide) {
      updatedTooWideTextRowIds = tooWideTextRowIds.includes(rowId)
        ? tooWideTextRowIds.filter((id) => rowId !== id)
        : tooWideTextRowIds
    }

    const newSign = {
      ...sign,
      values: {
        ...sign.values,
        tooWideTextRowIds: updatedTooWideTextRowIds,
      },
    }
    await dispatch({
      type: SET_SIGN_TEXT_TOO_WIDE,
      payload: newSign,
    })
  }
}

export const DUPLICATE_SIGNS = 'DUPLICATE_SIGNS'
export const duplicateSigns = (ids) => {
  return async (dispatch) => {
    await dispatch({
      type: DUPLICATE_SIGNS,
      payload: ids,
    })
  }
}

export const DUPLICATE_SIGNS_NO_TEXT = 'DUPLICATE_SIGNS_NO_TEXT'
export const duplicateSignsNoText = (ids) => {
  return async (dispatch) => {
    await dispatch({
      type: DUPLICATE_SIGNS_NO_TEXT,
      payload: ids,
    })
  }
}

export const ADD_SIGN = 'ADD_SIGN'
export const addSign = (sign, mounting) => {
  return async (dispatch, getState) => {
    if (mounting) {
      const prices = getState().app.prices
      sign.values.mounting = mounting
      sign.values.addOnProducts = getAddOnProducts(mounting, prices)
    }

    await dispatch({
      type: ADD_SIGN,
      payload: sign,
    })
  }
}

export const ADD_SIGNS = 'ADD_SIGNS'
export const addSigns = (signs) => {
  return async (dispatch) => {
    await dispatch({
      type: ADD_SIGNS,
      payload: signs,
    })
  }
}

export const GENERATE_IMAGE_FOR_ALL_SIGNS = 'GENERATE_IMAGE_FOR_ALL_SIGNS'
export const generateImageForAllSigns = () => {
  return async (dispatch, getState) => {
    const signs = getState().signs.present.signs
    signs.forEach((sign) => {
      dispatch(generateSignImage(sign))
    })
  }
}

export const GENERATE_SIGN_IMAGE = 'GENERATE_SIGN_IMAGE'
export const generateSignImage = (sign) => {
  let canvas = createVirtualCanvas('canvas-' + Date.now())
  const renderSize = getRenderSize(sign)
  renderSignToCanvas(sign, canvas, renderSize.width, renderSize.height)
  const dataURL = generatePNG(canvas)
  canvas.dispose()
  const newSign = {
    ...deepClone(sign),
    values: {
      ...sign.values,
      image: dataURL,
    },
  }
  return async (dispatch) => {
    await dispatch({
      type: GENERATE_SIGN_IMAGE,
      payload: {
        ...newSign,
      },
    })
  }
}

export const DELETE_SIGNS = 'DELETE_SIGNS'
export const deleteSigns = (ids) => {
  return async (dispatch) => {
    await dispatch({ type: DELETE_SIGNS, payload: ids })
  }
}

export const DELETE_ALL_SIGNS = 'DELETE_ALL_SIGNS'
export const deleteAllSigns = () => {
  return async (dispatch) => {
    await dispatch({ type: DELETE_ALL_SIGNS })
  }
}

export const deleteSignsAndSetActive = (ids, activeSign) => {
  return async (dispatch) => {
    if (activeSign) {
      await dispatch(setActiveEditorItem(activeSign))
    }
    await dispatch(deleteSigns(ids))
  }
}

export const undoableActions = [
  SET_SIGN_TEXT,
  SET_SIGN_THEME,
  // SET_SIGN_MOUNTING,
  SET_SIGN_SIZE,
  DUPLICATE_SIGNS,
  DELETE_SIGNS,
  ADD_SIGN,
]
