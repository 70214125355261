import styled from 'styled-components'

import { colors, spacing, radius } from '../../../styles/utilities/variables'
import { text } from '../../../styles/utilities/mixins'

const StyledCart = styled.div`
  padding: ${ props => !props.checkout ? spacing.medium : 0};
  color: ${colors.black};
  background: white;

  button {
    width: 100%;
    margin: 0;
  }
`

StyledCart.DesktopContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: ${spacing.medium};
  border-left: 1px solid ${colors.blueDark};
`

StyledCart.DesktopDropDown = styled.div`
  position: absolute;
  z-index: 2;
  right: ${spacing.xSmall};
  bottom: 0;
  transform: translate(0, 100%);
  border-radius: ${radius.small};
  margin: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid ${colors.grey04};
  width: 31rem;
`

StyledCart.MobileContainer = styled.div`
  position: absolute;
  top: 4.8rem;
  left: 0;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 4.8rem);
  background: white;
  overflow: scroll;
`

StyledCart.Title = styled.h2`
  ${text.bodyBold}
  font-size: 2.2rem;
  text-align: center;
  margin: ${spacing.small} 0 ${spacing.medium};
`

StyledCart.Table = styled.table`
  margin: 0;
  padding: 0;
  width: 100%;

  thead, tr, tfoot {
    th, td {
      padding: 1.6rem 0;

      &:first-child {
        padding-right: 1.6rem;
        width: 1.6rem;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
`

StyledCart.Header = styled.thead`
  border-bottom: 1px solid ${colors.grey05};

  &:first-child {
    ${text.smallBold}
  }
`

StyledCart.Row = styled.tr`
  border-bottom: 1px solid ${colors.grey05};
`

StyledCart.ProductRow = styled.tr`
  padding: ${spacing.medium} 0;
  border-bottom: 1px solid ${({ darkBorder }) => darkBorder ? colors.grey05 : colors.grey03};
`

StyledCart.Footer = styled.tfoot`
  border-top: 1px solid ${colors.grey05};

  td {
    ${text.bodyBold}
  }
`

StyledCart.Info = styled.div`
  padding: ${spacing.medium};
  background: ${colors.tealLight};
  margin-bottom: ${spacing.medium};
  border-radius: ${radius.small};
`

StyledCart.EmptyMessage = styled.p`
  text-align: center;
  color: ${colors.grey05};
  margin: ${spacing.small} ${spacing.xLarge} ${spacing.medium};
`

export { StyledCart }
