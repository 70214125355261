import React, { useEffect, useRef, useState, useCallback } from 'react'
import StyledStage from 'components/editor/Stage/Stage.style'
import {
  generateExportSVG,
  renderSignForExport,
  renderSignToCanvas,
} from 'helpers/fabric'

import { saveAs } from 'file-saver'
import { cloneSignWithNewText, getRenderSize } from 'helpers/signs'
import { useDispatch } from 'react-redux'
import { setSignText } from 'redux/actions/signsActions'
import { generateSignPdf } from 'api/Netlify'
import { fabric } from 'fabric'
import { useResponsive } from 'hooks/useResponsive'

const Canvas = (props) => {
  const { sign, scale, width, height, canvasId = 'stage' } = props
  const { breakpoints } = useResponsive()

  const dispatch = useDispatch()

  const [focusedTextbox, setFocusedTextbox] = useState(null)
  const [editingTextId, setEditingTextId] = useState(null)

  const node = useRef(null)

  const canvas = useRef(false)

  useEffect(() => {
    if (!canvas.current) {
      canvas.current = new fabric.Canvas(canvasId, { selection: false })
    }

    return function cleanup() {
      canvas.current.dispose()
    }
  }, [canvasId])

  const handleCanvasMouseDown = useCallback(
    (options) => {
      // Step down into groups and find textboxes
      if (canvas.current && options.target) {
        let thisTarget = options.target
        let mousePos = canvas.current.getPointer(options.e)

        if (thisTarget.isType('group') && thisTarget.name === 'mainGroup') {
          thisTarget.forEachObject((object) => {
            if (object.isType('group') && object.name === 'textGroup') {
              object.forEachObject((textObject, index) => {
                if (textObject.isType('i-text')) {
                  // Check positions to see if we click inside textbox
                  const {
                    top: textTop,
                    left: textLeft,
                    width: textWidth,
                    height: textHeight,
                  } = textObject

                  let textObjectPos = {
                    xStart: textLeft,
                    xEnd: textLeft + textWidth,
                    yStart: textTop,
                    yEnd: textTop + textHeight,
                  }

                  const clickedX = mousePos.x / scale
                  const clickedY = mousePos.y / scale

                  // prettier-ignore
                  const relativeClickedX = clickedX - ((width/scale) / 2)
                  // prettier-ignore
                  const relativeClickedY = clickedY - ((height/scale) / 2)

                  if (
                    relativeClickedX >= textObjectPos.xStart &&
                    relativeClickedX <= textObjectPos.xEnd &&
                    relativeClickedY >= textObjectPos.yStart &&
                    relativeClickedY <= textObjectPos.yEnd
                  ) {
                    setFocusedTextbox(textObject)
                    setEditingTextId(index)
                  }
                }
              })
            }
          })
        }
      }
    },
    [height, scale, width]
  )

  // const handleCanvasMouseDown = (options) => {
  //   // Step down into groups and find textboxes
  //   if (canvas.current && options.target) {
  //     let thisTarget = options.target
  //     let mousePos = canvas.current.getPointer(options.e)

  //     if (thisTarget.isType('group') && thisTarget.name === 'mainGroup') {
  //       thisTarget.forEachObject((object) => {
  //         if (object.isType('group') && object.name === 'textGroup') {
  //           object.forEachObject((textObject, index) => {
  //             if (textObject.isType('i-text')) {
  //               // Check positions to see if we click inside textbox
  //               const {
  //                 top: textTop,
  //                 left: textLeft,
  //                 width: textWidth,
  //                 height: textHeight,
  //               } = textObject

  //               let textObjectPos = {
  //                 xStart: textLeft,
  //                 xEnd: textLeft + textWidth,
  //                 yStart: textTop,
  //                 yEnd: textTop + textHeight,
  //               }

  //               const clickedX = mousePos.x / scale
  //               const clickedY = mousePos.y / scale

  //               // prettier-ignore
  //               const relativeClickedX = clickedX - ((width/scale) / 2)
  //               // prettier-ignore
  //               const relativeClickedY = clickedY - ((height/scale) / 2)

  //               if (
  //                 relativeClickedX >= textObjectPos.xStart &&
  //                 relativeClickedX <= textObjectPos.xEnd &&
  //                 relativeClickedY >= textObjectPos.yStart &&
  //                 relativeClickedY <= textObjectPos.yEnd
  //               ) {
  //                 setFocusedTextbox(textObject)
  //                 setEditingTextId(index)
  //               }
  //             }
  //           })
  //         }
  //       })
  //     }
  //   }
  // }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
        console.log('canvas inside click')
        // inside click
        return
      }
      // outside click
      setFocusedTextbox(null)
      setEditingTextId(null)
    }

    if (focusedTextbox) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [node, focusedTextbox, setFocusedTextbox, setEditingTextId])

  const onTextChanged = useCallback(
    (e) => {
      console.log('🚀 ~ e.target.text', e.target.text)
      if (editingTextId !== null) {
        const newSign = cloneSignWithNewText(sign, e.target.text, editingTextId)
        dispatch(setSignText(newSign))
      }
    },
    [dispatch, editingTextId, sign]
  )

  // const onTextChanged = (e) => {
  //   console.log('🚀 ~ e.target.text', e.target.text)
  //   if (editingTextId !== null) {
  //     const newSign = cloneSignWithNewText(sign, e.target.text, editingTextId)
  //     dispatch(setSignText(newSign))
  //   }
  // }

  const onTextEditExited = useCallback((e) => {
    console.log('🚀 ~ onTextEditExited')
    canvas.current.off('text:editing:exited', onTextEditExited)
    setFocusedTextbox(null)
    setEditingTextId(null)
  }, [])

  // const onTextEditExited = (e) => {
  //   console.log('🚀 ~ onTextEditExited')
  //   canvas.current.off('text:editing:exited', onTextEditExited)
  //   setFocusedTextbox(null)
  //   setEditingTextId(null)
  // }

  useEffect(() => {
    if (canvas && canvas.current) {
      const callback = () => {
        if (focusedTextbox) {
          canvas.current.setActiveObject(focusedTextbox)
          focusedTextbox.enterEditing()
          focusedTextbox.hiddenTextarea.focus()
          // focusedTextbox.selectAll()
        }
      }
      renderSignToCanvas(
        sign,
        canvas.current,
        width,
        height,
        scale,
        callback,
        false,
        dispatch,
        focusedTextbox,
        editingTextId,
        breakpoints.mobileDown,
      )

      if (canvasId === 'SignSingleViewCanvas') {
        canvas.current.on('mouse:down', handleCanvasMouseDown)
        canvas.current.on('text:changed', onTextChanged)
        canvas.current.on('text:editing:exited', onTextEditExited)
      }
    }

    return function cleanup() {
      canvas.current.off('mouse:down', handleCanvasMouseDown)
      canvas.current.off('text:changed', onTextChanged)
      canvas.current.off('text:editing:exited', onTextEditExited)
    }
  }, [
    sign,
    canvasId,
    dispatch,
    focusedTextbox,
    handleCanvasMouseDown,
    height,
    onTextChanged,
    onTextEditExited,
    scale,
    width,
    editingTextId,
    breakpoints.mobileDown,
  ])

  const generateFile = async () => {
    try {
      const renderSize = getRenderSize(sign)
      const exportCanvas = renderSignForExport(
        sign,
        renderSize.width,
        renderSize.height
      )
      const svgBlob = generateExportSVG(
        exportCanvas,
        renderSize.width,
        renderSize.height
      )
      const data = await generateSignPdf(svgBlob)
      saveAs(data, 'skylt.pdf')
    } catch (error) {
      console.log('error:', error)
    }
  }

  return (
    <StyledStage borderRadius={0}>
      <div ref={node}>
        <canvas id={canvasId} width={width} height={height} /> { /* Do width and height actually do anything? */ }
      </div>

      {process.env.NODE_ENV === 'development' && (
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <button
            style={{ position: 'fixed' }}
            onClick={(e) => {
              e.preventDefault()
              generateFile()
            }}
          >
            Download
          </button>
        </div>
      )}
    </StyledStage>
  )
}
export { Canvas }
