import React from 'react'
import StyledToast from './Toast.style'

const Toast = (props) => {
  const { message, type, offset, clickHandler } = props
  const messageType = type ? type : 'info'
  return (
    <StyledToast offset={offset} className={messageType} onClick={clickHandler}>
      {message}
    </StyledToast>
  )
}

export default Toast
