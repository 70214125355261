import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'

const StyledHeader = styled.header`
  // overflow-y: scroll;
  // overflow-x: hidden;
  background: ${colors.primary};
  color: ${colors.white};
`

StyledHeader.Top = styled.div`
  background: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${spacing.xLarge};
  padding: 0 ${spacing.small};
  border-bottom: 1px solid ${colors.blueDark};
`

StyledHeader.TopRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${spacing.xLarge};

  a {
    color: white;
  }
`

StyledHeader.Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: ${spacing.xLarge};
  padding: 0 ${spacing.medium};
  border-bottom: 1px solid ${colors.blueDark};
`

StyledHeader.Col = styled.div`
  display: flex;
`

StyledHeader.Logo = styled.div`
  ${text.bodyBold}
`

export default StyledHeader
