import React from 'react'
import Button from 'components/common/Button'
import Overlay from 'components/common/Overlay'
import StyledModal from './Modal.style'
import Loader from '../Loader'

const Modal = (props) => {
  const {
    isActive,
    header,
    children,
    acceptHandler,
    rejectHandler,
    backHandler,
    acceptText,
    rejectText,
    backText,
    size,
    isLoading = false,
  } = props

  if (!isActive) return null

  const hasActions = backText || rejectText || acceptText ? true : false

  return (
    <StyledModal>
      <Overlay handleOverlayClick={rejectHandler} />
      <StyledModal.Modal padding={props.padding} size={size}>
        {header && <StyledModal.Header>{header}</StyledModal.Header>}

        {children && (
          <StyledModal.Content height={props.contentHeight}>
            {children}
          </StyledModal.Content>
        )}

        {hasActions && (
          <StyledModal.Actions>
            {backText && (
              <Button handleButtonClick={backHandler}>{backText}</Button>
            )}
            {rejectText && (
              <Button type={'bordered'} handleButtonClick={rejectHandler}>
                {rejectText}
              </Button>
            )}
            {acceptText && (
              <Button handleButtonClick={acceptHandler}>
                {isLoading ?
                  <Loader size={20} noMargin /> :
                  acceptText
                }
              </Button>
            )}
          </StyledModal.Actions>
        )}
      </StyledModal.Modal>
    </StyledModal>
  )
}

export default Modal
