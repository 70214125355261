import React from 'react'
import { useDispatch } from 'react-redux'
import { duplicateSign } from 'helpers/signs'
import { addSign } from 'redux/actions/signsActions'
import { focusOnTableTextInput } from 'redux/actions/editorActions'
import StyledTable from 'components/common/Table/Table.style'
import TextInput from 'components/common/TextInput'
import StyledSignList from './SignList.style'
import { SIGN_TYPES } from 'constants/Signs'

const clearText = (text) => {
  return {
    ...text,
    content: '',
  }
}

const AddSignRow = (props) => {
  const { template } = props
  const dispatch = useDispatch()
  const placeholderTexts = template ? SIGN_TYPES.find(
    ({ type }) => type === template.values.type
  ).placeholderTexts : []

  const handleChange = (e, rowId) => {
    const duplicate = duplicateSign(template)
    duplicate.values.text = duplicate.values.text.map(clearText)
    duplicate.values.text[rowId] = {
      rowId: rowId,
      content: e.target.value,
    }

    dispatch(addSign(duplicate))
    dispatch(
      focusOnTableTextInput({
        sign: duplicate,
        rowId: rowId,
      })
    )
  }

  return (
    <StyledTable.Row>
      <StyledTable.Column width={50}>&nbsp;</StyledTable.Column>
      <StyledTable.Column width={50} center={true}>
        +
      </StyledTable.Column>

      <StyledSignList.TextFields>
        {template.values.text.map((text, textIndex) => (
          <StyledTable.Column key={textIndex} isActive={false}>
            <TextInput
              onChange={(e) => {
                handleChange(e, textIndex)
              }}
              value={''}
              placeholder={placeholderTexts[textIndex]}
            />
          </StyledTable.Column>
        ))}
      </StyledSignList.TextFields>
    </StyledTable.Row>
  )
}

export { AddSignRow }
