/**
 * Calculate the "center" value of a subject relative to it's containing element.
 * @param subject
 * @param container
 */
export const center = (subject, container) => {
  return (container - subject) / 2
}

/**
 * Calculate the width of the largest possible square contained within a circle
 * @param diameter
 */
export const squareCircle = (diameter) => {
  return diameter / Math.sqrt(2)
}
