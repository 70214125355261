import React, { useState, useEffect, useRef } from 'react'
import DropdownList from 'components/common/DropdownList'
import { StyledDropdown } from './Dropdown.style'
import Icon from '../Icon'

const Dropdown = (props) => {
  const { options, currentValue, handleOptionChange } = props
  const node = useRef(null)
  const [open, setOpen] = useState(false)

  const handleTextInputClick = () => {
    setOpen(!open)
  }

  const handleOptionClick = (value) => {
    // Intermediary function to send value up and close dropdown
    handleOptionChange(value)
    setOpen(!open)
  }

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const currentLabel = () => {
    return options
      .filter((option) => option.value === currentValue)
      .reduce((acc, option) => option.label, '')
  }

  return (
    <StyledDropdown ref={node}>
      <StyledDropdown.Input
        type='text'
        readOnly
        onClick={handleTextInputClick}
        value={currentLabel()}
      />

      <StyledDropdown.Icon>
        {open ? (
          <Icon name={'chevronUp'} size={18} />
        ) : (
          <Icon name={'chevronDown'} size={18} />
        )}
      </StyledDropdown.Icon>

      <DropdownList
        options={options}
        open={open}
        handleOptionChange={handleOptionClick}
      />
    </StyledDropdown>
  )
}

export default Dropdown
