import React from 'react'
import Icon from '../Icon'
import StyledIconLink from './IconLink.style'

const IconLink = (props) => {
  return (
    <StyledIconLink {...props}>
      <Icon name={props.icon} />
      <span>{props.text}</span>
    </StyledIconLink>
  )
}

export default IconLink
