import React from 'react'
import { useSelector } from 'react-redux'
import { StyledCart } from './Cart.styles'
import Cart from './Cart'
import { getCartTotal } from 'helpers/cart'

const CartMobile = () => {
  const { signs, isCartActive, shipping } = useSelector((state) => {
    return {
      cart: state.signs.present.cart,
      signs: state.cart.signs,
      isCartActive: state.app.isCartActive,
      shipping: state.app.shipping,
    }
  })

  const total = getCartTotal(signs)

  return isCartActive ? (
    <StyledCart.MobileContainer>
      <Cart signs={signs} total={total} shipping={shipping} />
    </StyledCart.MobileContainer>
  ) : null
}

export default CartMobile
