import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'redux/stores/rootReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/hardSet'
import createMigrate from 'redux-persist/es/createMigrate'
import { getData } from 'helpers/storage'

const migrations = {
  1: (state) => {
    return {
    ...state,
    cart: {
      ...state.cart,
      itemCount: state.cart.signs.length,
    }
  }},
  2: (state) => {
    return {
    ...state,
    cart: {
      ...state.cart,
      cartHash: getData('cart-hash'),
    }
  }}
}

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  blacklist: ['order'],
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations)
}

// Sanitizer some dev tools actions
const actionSanitizer = (action) => {
  return action.type === 'ON_FONTS_LOADED'
    ? { ...action, payload: '<<FONT_FILES_OMITTED_FOR_DEVTOOLS>>' }
    : action
}

const composeEnhancers = composeWithDevTools({
  actionSanitizer: actionSanitizer,
  stateSanitizer: (state) => {
    return state.app.fonts
      ? {
          ...state,
          app: { ...state.app, fonts: '<<FONT_FILES_OMITTED_FOR_DEVTOOLS>>' },
        }
      : state
  },
})

/**
 * Get dev or prod enhancer
 * @returns {*}
 */
const getEnhancer = () => {
  let middleware = [thunk]
  if (process.env.NODE_ENV === 'production') {
    return compose(applyMiddleware(...middleware))
  } else {
    // return compose(applyMiddleware(...middleware))
    return composeEnhancers(applyMiddleware(...middleware))
  }
}

export default function configureStore(initialState) {
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, initialState, getEnhancer())
  const persistor = persistStore(store)

  return {store, persistor}
}
