import React from 'react'
import { useDispatch } from 'react-redux'
import { TABLE_STAGE } from 'constants/Editor'
import {
  setActiveStage,
  undoLastAction,
} from 'redux/actions/editorActions'

import StyledToolbarHeaderMobile from './ToolbarHeaderMobile.style'
import Button from 'components/common/Button'
import AddToCartButton from 'components/common/AddToCartButton'
import { showDeleteSignModal, showNewSignModal } from 'redux/actions/appActions'

const ToolbarHeaderMobileSingle = () => {
  const dispatch = useDispatch()

  const handleDeleteClick = async () => {
    dispatch(showDeleteSignModal())
  }

  return (
    <StyledToolbarHeaderMobile>
      <Button
        type={'plain'}
        icon={'list'}
        iconSize={26}
        handleButtonClick={() => {
          dispatch(setActiveStage(TABLE_STAGE))
        }}
      />
      <Button
        type={'plain'}
        icon={'backLeft'}
        handleButtonClick={() => {
          dispatch(undoLastAction())
        }}
      />

      <Button
        type={'plain'}
        icon={'bin'}
        handleButtonClick={() => handleDeleteClick()}
      />

      <Button
        type={'plain light'}
        handleButtonClick={() => dispatch(showNewSignModal())}
      >
        Ny skylt
      </Button>

      <AddToCartButton />
    </StyledToolbarHeaderMobile>
  )
}
export { ToolbarHeaderMobileSingle }
