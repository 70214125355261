import React from 'react'
import StyledHeaderMobile from 'components/editor/Header/HeaderMobile.style'
import Button from 'components/common/Button/index'
import Logo from 'components/common/Logo'
import { useSelector } from 'react-redux'

const stateSelector = (state) => {
  return {
    itemCount: state.cart.itemCount,
  }
}

const HeaderMobile = (props) => {
  const { handleToggleCartClick } = props
  const { itemCount } = useSelector(stateSelector)

  return (
    <StyledHeaderMobile>
      <StyledHeaderMobile.Bottom>
        <StyledHeaderMobile.Col>
          <Button type={'plain'} handleButtonClick={() => {}}>
            <Logo />
          </Button>
        </StyledHeaderMobile.Col>
        <StyledHeaderMobile.Col>
          <Button
            type={'plain'}
            icon={'cart'}
            handleButtonClick={handleToggleCartClick}
            notification={itemCount > 0 ? itemCount : false}
          />
        </StyledHeaderMobile.Col>
      </StyledHeaderMobile.Bottom>
    </StyledHeaderMobile>
  )
}

export { HeaderMobile }
