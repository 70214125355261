const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

const generateTempId = (list, max = 1000000) => {
  const usedIds = list.map((item) => item.id)
  const randomId = 'TEMP-ID-' + Math.floor(Math.random() * max)

  if (usedIds.indexOf(randomId) < 0) {
    return randomId
  }

  return generateTempId(list)
}

const listWithout = (excludeId, list) => {
  return list.reduce((acc, item) => {
    if (item.id === excludeId) {
      return acc
    }
    return [...acc, item]
  }, [])
}

const curry = (f) => {
  return function currify() {
    const args = Array.prototype.slice.call(arguments)
    return args.length >= f.length
      ? f.apply(null, args)
      : currify.bind(null, ...args)
  }
}

export { deepClone, generateTempId, listWithout, curry }
