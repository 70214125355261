import { setData, removeToken, removeData, setToken } from 'helpers/storage'
import { login, logout } from 'api/Customer'

export const UPDATE_CUSTOMER_PROP = 'UPDATE_CUSTOMER_PROP'
export const updateCustomerProp = (key, value) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_CUSTOMER_PROP,
      payload: {
        key: key,
        value: value,
      },
    })
  }
}

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const updateCustomer = (state) => {
  setData('customer', state)
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_CUSTOMER,
      payload: state,
    })
  }
}

export const LOGIN_CUSTOMER = 'LOGIN_CUSTOMER'
export const loginCustomer = () => {
  return async (dispatch) => {
    await dispatch({
      type: LOGIN_CUSTOMER,
    })
  }
}

export const LOGOUT_CUSTOMER = 'LOGOUT_CUSTOMER'
export const logoutCustomer = () => {
  removeToken()
  removeData('customer')
  return async (dispatch) => {
    await dispatch({
      type: LOGOUT_CUSTOMER,
    })
  }
}

export const SET_CUSTOMER_TOKEN = 'SET_TOKEN'
export const setCustomerToken = (token) => {
  setToken(token)
  return async (dispatch) => {
    await dispatch({
      type: SET_CUSTOMER_TOKEN,
      payload: token,
    })
  }
}

export const REMOVE_CUSTOMER_TOKEN = 'REMOVE_CUSTOMER_TOKEN'
export const removeCustomerToken = () => {
  removeToken()
  return async (dispatch) => {
    await dispatch({
      type: REMOVE_CUSTOMER_TOKEN,
    })
  }
}

export const DO_LOGIN = 'DO_LOGIN'
export const doLogin = (email, password) => {
  return async (dispatch) => {
    login(email, password)
      .then((resp) => resp.json())
      .then((data) => {
        dispatch(loginCustomer())
        dispatch(updateCustomer(data.customer))
        dispatch(setCustomerToken(data.accessToken))
      })
      .catch((error) => {
        alert('Error')
      })
  }
}

export const DO_LOGOUT = 'DO_LOGOUT'
export const doLogout = () => {
  return async (dispatch) => {
    logout().then((data) => {
      dispatch(logoutCustomer())
    })
  }
}
