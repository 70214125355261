import styled, { css } from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { media } from 'styles/utilities/functions'
import { text } from 'styles/utilities/mixins'

const StyledTextInput = styled.div`
  ${media.tabletPortraitBelow`
    border-bottom: 1px solid ${colors.grey03};
    border-color: ${(props) =>
      props.invalid ? colors.warning : colors.grey03};
  `}

  margin-bottom: ${(props) => (props.bottomSpace ? spacing.large : '0')};
`

StyledTextInput.LabelInputWrapper = styled.div`
  ${media.tabletPortraitBelow`
    display: flex;
    align-items: center;
  `}
`

StyledTextInput.InputWrapper = styled.div`
  position: relative;
  ${media.tabletPortraitBelow`
    flex: 1;
    margin-right: ${spacing.small};
  `}
`

StyledTextInput.Label = styled.label`
  display: block;
  ${media.tabletPortraitBelow`
    width: 50px;
    color: ${colors.grey05};
    ${text.bodyTabularMobile}
  `}
`

StyledTextInput.Input = styled.input`
  display: block;
  margin: ${spacing.small} 0;

  ${(props) =>
    props.invalid &&
    !props.inTable &&
    css`
      border: 1px solid ${colors.warning} !important;
    `}

  ${media.tabletPortraitBelow`
    border:0 !important;
    ${text.bodyTabularMobile}
  `}
`

StyledTextInput.ErrorMessage = styled.p`
  font-style: italic;
  color: ${colors.warning};
  margin-bottom: ${spacing.xSmall};

  ${media.tabletPortraitBelow`
    margin-bottom: ${spacing.small};
  `}
`

StyledTextInput.ErrorIcon = styled.div`
  position: absolute;
  top: 50%;
  right: ${spacing.small};
  transform: translateY(-50%);
  cursor: ${(props) => (props.inTable ? 'pointer' : 'auto')};
`

export default StyledTextInput
