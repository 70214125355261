import React from 'react'
import { SIGN_TYPES } from 'constants/Signs'
import List from 'components/common/List/List.style'
import SignTypeIcon from './SignTypeIcon'
import Icon from 'components/common/Icon'
// import RowContentExpander from './RowContentExpander'

const SignListAside = (props) => {
  const {
    activeSign,
    setActiveSign,
    categorisedSigns,
    delimiterText,
    capitalizeMounting,
    show,
  } = props
  return (
    <List show={show}>
      {categorisedSigns.map((category) => {
        const signType = SIGN_TYPES.find(
          (signType) => signType.type === category.type
        )

        return (
          <div key={category.type}>
            <List.Header small>
              {signType.name}
              {/* <Button
                icon={'addCircle'}
                type={'only-icon'}
                handleButtonClick={() => {
                  dispatch(duplicateSigns([category.signs.at(-1).values.id]))
                }}
              /> */}
            </List.Header>

            {/* <RowContentExpander items={category.signs} type={'aside_sign'}> */}
            {category.signs.map((sign, index) => {
              const text = delimiterText(sign.values.text)
              const mounting = capitalizeMounting(sign.values.mounting)
              const hasTooWideText = sign.values.tooWideTextRowIds.length > 0

              return (
                <List.Row
                  onClick={() => setActiveSign(sign)}
                  active={activeSign && sign.values.id === activeSign.values.id}
                  key={index}
                >
                  <List.RowIcon>
                    <SignTypeIcon sign={sign} />
                  </List.RowIcon>
                  <List.RowContent>
                    <List.RowTitle
                      small
                      width={hasTooWideText ? '15rem' : '18rem'}
                    >
                      {text}
                    </List.RowTitle>
                    <List.RowMeta
                      small
                      width={hasTooWideText ? '15rem' : '18rem'}
                    >
                      {sign.values.theme.name}, {mounting}
                    </List.RowMeta>
                    {hasTooWideText && (
                      <List.RowErrorIcon>
                        <Icon name={'errorTriangle'} size={20} />
                      </List.RowErrorIcon>
                    )}
                  </List.RowContent>
                </List.Row>
              )
            })}
            {/* </RowContentExpander> */}
          </div>
        )
      })}
    </List>
  )
}

export default SignListAside
