import styled from 'styled-components'
import { colors, spacing, radius, mq } from 'styles/utilities/variables'
import { text } from 'styles/utilities/mixins'

const StyledButton = styled.button`
  ${text.bodyBold}
  border: none;
  border-radius: ${radius.small};
  padding: ${spacing.xSmall} ${spacing.small};
  margin: 0 5px 0 0;
  min-height: 34px;
  transition: background-color .1s ease-in-out;

  &.primary {
    background: ${colors.primary};
    color: white;
  }
  &.white {
    color: ${colors.primary};
    background: white;
  }
  &.primary-bordered {
    background: ${colors.blueLight};
    color: white;
    border: 1px solid ${colors.blueDark};

    &:hover,
    &.active {
      background: ${colors.blueDark};

      &:disabled {
        background: ${colors.blueLight};
      }
    }
  }

  &.teal-light {
    background-color: ${colors.tealLight};
    color: ${colors.tealDark};

    &:hover,
    &.active {
      @media (min-width: ${mq.mobileDown}px) {
        background: ${colors.white};
      }

      &:disabled {
        background: ${colors.tealLight};
      }
    }
  }

  &.plain {
    border: 0;
    background: transparent;
    color: white;
  }

  &.only-icon {
    border: 0;
    background: transparent;
    color: inherit;
    padding: 0;
    margin: 0;

    .svg-wrapper {
      margin: 0;
    }

    &.primary-bordered {
      padding: ${spacing.xSmall} ${spacing.small};
      margin: 0 5px 0 0;
      background: ${colors.blueLight};
      color: white;
      border: 1px solid ${colors.blueDark};

      &:hover,
      &.active {
        background: ${colors.blueDark};

        &:disabled {
          background: ${colors.blueLight};
        }
      }
    }
  }

  &.light {
    ${text.body}
  }
  &:focus {
    outline: none;
  }

  &.push-left {
    margin-left: auto;
  }

  &.push-right {
    margin-right: auto;
  }

  &:disabled {
    opacity: 0.5;
  }
`

StyledButton.Inner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-wrapper {
    margin-right: ${spacing.small};
  }

  div:last-child {
    .svg-wrapper {
      margin-right: 0;
    }
  }
`

StyledButton.Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => (props.withMargin ? spacing.xSmall : 0)};
`

StyledButton.Notification = styled.div`
  ${text.small}
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -20%);
  height: 1.8rem;
  min-width: 1.8rem;
  padding: 0 0.4rem;
  border-radius: 1rem;
  color: ${colors.black};
  background: ${colors.white};
  border: 2px solid ${colors.primary};
`

const StyledButtonGroup = styled.div`
  margin: 0 5px 0 0;
  display: flex;

  .button {
    margin: 0;
    border-radius: 0;
    width: 100%;

    &:first-child {
      border-top-left-radius: ${radius.small};
      border-bottom-left-radius: ${radius.small};
    }
    &:last-child {
      border-top-right-radius: ${radius.small};
      border-bottom-right-radius: ${radius.small};
    }
  }
`

export { StyledButton, StyledButtonGroup }
