import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

const StyledOrder = styled.section`
  table {
    td {
      padding: 0.5rem 1rem;
      &:first-child {
        padding-left: 0;
      }
    }
  }
`

export default StyledOrder

export const StyledOrderGrid = styled.div`
  display: grid;
  grid-template-columns: 40px auto auto auto 60px 60px 140px;
`

export const StyledOrderGridItemHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey02};
  border-bottom: 1px solid ${colors.grey03};
  border-right: ${({ lastColumn }) =>
    lastColumn ? 'none' : `1px solid ${colors.grey03}`};
  min-height: ${spacing.xLarge};
`

export const StyledOrderGridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid ${colors.grey03};
  border-right: 1px solid ${colors.grey03};
  border-left: ${({ firstColumn }) =>
    firstColumn ? `1px solid ${colors.grey03}` : `none`};
  padding-left: ${({ lastColumn }) => (lastColumn ? `5px;` : ``)}
  min-height: ${spacing.xLarge};
`
