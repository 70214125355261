import styled, { css } from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'
import { media } from 'styles/utilities/functions'

const StyledNewSignModal = styled.div`
  height: min(500px, 90vh);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${spacing.xLarge} auto ${spacing.xLarge};
  background: ${colors.grey01};

  ${media.mobileDown`
    height: 80vh;
  `}

  ${media.tabletPortraitDown`
    height: 60vh;
  `}
`

StyledNewSignModal.Header = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${spacing.xLarge};
  padding: 0 ${spacing.medium};
  border-bottom: 1px solid ${colors.grey03};
`

StyledNewSignModal.Section = styled.section`
  flex-grow: 1;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.grey03};
  margin-bottom: -1px;
  overflow-y: auto;
  ${media.tabletPortraitBelow`
    grid-column-start: 1;
    grid-column-end: 3;
    overflow: scroll;
    margin-bottom: 0;
  `}
`

StyledNewSignModal.Row = styled.div`
  min-height: ${spacing.xLarge};
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  background-color: ${colors.white};
  padding-left: ${spacing.medium};
  background: ${(props) => (props.isActive ? colors.grey01 : 'white')};
  border-bottom: 1px solid ${colors.grey03};

  ${(props) =>
    props.selected
      ? css`
          background-color: ${colors.grey02};
          &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 2px;
            left: 0;
            background-color: ${colors.primary};
          }
          & + & {
            .rowSelectBorder {
              border-top: none;
            }
          }
        `
      : null};
`

StyledNewSignModal.Description = styled.div`
  padding: ${spacing.small};
`

StyledNewSignModal.Footer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${spacing.xLarge};
  border-top: 1px solid ${colors.grey03};
`

export default StyledNewSignModal
