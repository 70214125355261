import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables.js'
import { media } from 'styles/utilities/functions'

const StyledTextPanel = styled.div`
  //border: 1px solid ${colors.grey01};
  ${media.tabletPortraitBelow`
    margin: 0 0 ${spacing.small} ${spacing.medium};
  `}
`

StyledTextPanel.Controls = styled.div`
  margin-bottom: ${spacing.large};

  &:last-child {
    margin-bottom: 0;
  }
`

export default StyledTextPanel
