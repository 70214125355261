import { API_AUTH_BASE } from './Api'
import { getData, getToken } from 'helpers/storage'

export const CUSTOMER_ENDPOINT = 'customers'
export const headers = () => {
  const token = getData('wc-cart-token')
  const nonce = getData('wc-cart-nonce')

  return new Headers({
    ...token && { 'Cart-Token': token },
    ...nonce && { 'Nonce': nonce },
    'X-CSRF-TOKEN': getToken(),
    'Content-type': 'application/json',
  })
}

export const login = async (email, password) => {
  return await await fetch(
    `${API_AUTH_BASE}/${CUSTOMER_ENDPOINT}/login?email=${email}&password=${password}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: headers(),
    }
  )
}

export const logout = async () => {
  return await await fetch(`${API_AUTH_BASE}/${CUSTOMER_ENDPOINT}/logout`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
}
