import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables'

const StyledCustomerView = styled.div`
  padding: 0;
  padding-bottom: ${spacing.large};
  background: ${colors.grey02};
  min-height: 100%;
  form {
      max-width:100%;
  }
`

export default StyledCustomerView
