import { signColors } from 'styles/utilities/variables.js'

export const SIGN_TYPES = [
  {
    name: 'Nyckelbricka - rund',
    name_plural: 'Nyckelbrickor - runda',
    type: 'nyckelbricka-rund',
    icon: {
      height: 80,
      width: 80,
      name: 'keyChain',
    },
    desc: () => (
      <p>
        För t ex nycklar, hund och katt. Gjord i slitstark och färgbeständig
        plast. 25mm i diameter. Finns i flera färger
      </p>
    ),
    placeholderTexts: ['ex Garage'],
  },
  {
    name: 'Nyckelbricka - rektangulär',
    name_plural: 'Nyckelbrickor - rektangulära',
    type: 'nyckelbricka-rektangular',
    icon: {
      height: 80,
      width: 80,
      name: 'keyChain',
    },
    desc: () => (
      <p>
        För t ex nycklar, hund och katt. Gjord i slitstark och färgbeständig
        plast. Välj mellan två olika storlekar, 45x25 mm och 50x20 mm. Finns i
        flera färger.
      </p>
    ),
    placeholderTexts: ['ex Johanna', 'ex Skolan'],
  },
  // {
  //   name: 'Namnbricka - oval',
  //   name_plural: 'Namnbrickor',
  //   type: 'namnbricka-oval',
  //   desc: 'För att fästa i skjorta mm. Gjord i slitstark och färgbeständig plast.',
  // },
  // {
  //   name: 'Namnbricka - rektangulär',
  //   name_plural: 'Namnbrickor',
  //   type: 'namnbricka-rektangular',
  //   desc: 'För att fästa i skjorta mm. Gjord i slitstark och färgbeständig plast.',
  // },
  // {
  //   name: 'Namnbricka med militärklämma',
  //   name_plural: 'Namnbrickor med militärklämma',
  //   type: 'namnbricka-med-militarklamma',
  //   desc: 'För att fästa i skjorta mm. Gjord i slitstark och färgbeständig plast.',
  // },
  // {
  //   name: 'Märkskylt',
  //   name_plural: 'Märkskyltar',
  //   type: 'markskylt',
  //   icon: {
  //     height: 48,
  //     width: 163,
  //     name: 'mark',
  //   },
  //   desc: 'Gjord i slitstark och färgbeständig plast.',
  // },
  {
    name: 'Brevlådeskylt',
    name_plural: 'Brevlådeskyltar',
    type: 'brevladeskylt',
    desc: () => (
      <>
        <p>Skylt för tex brevlåda, förvaringslådor, adresskylt mm</p>
        <p>
          Gjord i slitstark och färgbeständig plast, fästes med tejp eller
          skruvar. Välj mellan tre olika storlekar, 80x30 mm, 100x40 mm, 110x50
          mm. Finns i flera färger.
        </p>
      </>
    ),
    placeholderTexts: [
      'ex Fam Johansson',
      'ex Gatuadress',
      'ex Ingen reklam, tack!',
      'Id-nummer',
      'Övrig info',
    ],
  },
  // {
  //   name: 'Generell skylt',
  //   name_plural: 'Generella skyltar',
  //   type: 'generell-skylt',
  //   icon: {
  //     height: 48,
  //     width: 48,
  //     name: 'maximize'
  //   },
  //   desc: 'Gjord i slitstark och färgbeständig plast.',
  // },
]

export const MOUNTING_TEXTS = {
  panelHeader: 'Montering',
}
export const MOUNTING_TYPES = [
  {
    name: 'Nyckelring',
    type: 'nyckelring',
    icon: 'keyChainMount',
  },
  {
    name: '2 skruvhål',
    type: '2-skruvhal',
    icon: 'screw',
  },
  {
    name: '4 skruvhål',
    type: '4-skruvhal',
    icon: 'screw',
  },
  {
    name: 'Tejp',
    type: 'tejp',
    icon: 'tape_roll',
  },
  {
    name: 'Militärklämma',
    type: 'militarklamma',
    icon: 'military',
  },
  {
    name: 'Magnet',
    type: 'magnet',
    icon: 'magnet',
  },
  {
    name: 'Nål',
    type: 'nal',
    icon: 'needle',
  },
  {
    name: 'Ingen',
    type: 'none',
    icon: 'close',
  },
]

export const SHAPE_TYPES = [
  {
    name: 'Fyrkantig',
    type: 'rect',
    icon: 'rect',
    iconWidth: 48,
    iconHeight: 24,
  },
  {
    name: 'Rund',
    type: 'circle',
    icon: 'circle',
    iconWidth: 24,
    iconHeight: 24,
  },
  {
    name: 'Oval',
    type: 'ellipse',
    icon: 'ellipse',
    iconWidth: 48,
    iconHeight: 24,
  },
]

// Not used
// export const SIGN_SIZES = [
//   {
//     name: 'Liten',
//     type: 'small',
//   },
//   {
//     name: 'Normal',
//     type: 'medium',
//   },
//   {
//     name: 'Stor',
//     type: 'large',
//   },
// ]

export const TEXT_SIZES = [
  {
    name: 'Liten',
    type: 'small',
  },
  {
    name: 'Normal',
    type: 'medium',
  },
  {
    name: 'Stor',
    type: 'large',
  },
]

export const TEXT_ALIGNMENT = [
  {
    name: 'Vänster',
    type: 'left',
  },
  {
    name: 'Centrerad',
    type: 'center',
  },
  {
    name: 'Höger',
    type: 'right',
  },
]

export const THEMES = [
  {
    name: 'Neutral Black',
    type: 'Neutral Black',
    colorPrimary: signColors.black, // bakgrund
    colorSecondary: signColors.white, // text
    fontFamily: 'Inter',
  },
  {
    name: 'Neutral White',
    type: 'Neutral White',
    colorPrimary: signColors.white, // bakgrund
    colorSecondary: signColors.black, // text
    fontFamily: 'Inter',
  },
  {
    name: 'Neutral Red',
    type: 'Neutral Red',
    colorPrimary: signColors.red, // bakgrund
    colorSecondary: signColors.white, // text
    fontFamily: 'Inter',
  },
  {
    name: 'Bobby Digital',
    type: 'Bobby Digital',
    colorPrimary: signColors.blue, // bakgrund
    colorSecondary: signColors.white, // text
    fontFamily: 'Tomorrow',
  },
  {
    name: 'White Whale',
    type: 'White Whale',
    colorPrimary: signColors.white, // bakgrund
    colorSecondary: signColors.black, // text
    fontFamily: 'Roboto Slab',
  },
  {
    name: 'Happy Green',
    type: 'Happy Green',
    colorPrimary: signColors.green, // bakgrund
    colorSecondary: signColors.white, // text
    fontFamily: 'Nunito',
  },
  {
    name: 'Classy',
    type: 'Classy',
    colorPrimary: signColors.red, // bakgrund
    colorSecondary: signColors.white, // text
    fontFamily: 'Libre Baskerville',
  },
  {
    name: 'Dutch',
    type: 'Dutch',
    colorPrimary: signColors.yellow, // bakgrund
    colorSecondary: signColors.black, // text
    fontFamily: 'Inter',
  },
]

export const COLOR_COMBINATIONS = [
  {
    name: 'black-white', // backgrund-text
    colorPrimary: signColors.black,
    colorSecondary: signColors.white,
  },
  {
    name: 'red-white',
    colorPrimary: signColors.red,
    colorSecondary: signColors.white,
  },
  {
    name: 'yellow-black',
    colorPrimary: signColors.yellow,
    colorSecondary: signColors.black,
  },
  {
    name: 'white-black',
    colorPrimary: signColors.white,
    colorSecondary: signColors.black,
  },
  {
    name: 'green-white',
    colorPrimary: signColors.green,
    colorSecondary: signColors.white,
  },
  {
    name: 'blue-white',
    colorPrimary: signColors.blue,
    colorSecondary: signColors.white,
  },
]

export const FONTS = [
  {
    name: 'Inter',
    fontFamily: 'Inter',
  },
  {
    name: 'Roboto Slab',
    fontFamily: 'Roboto Slab',
    // fontFamily: 'RobotoSlab' // For paths object key in useFonts hook
  },
  {
    name: 'Tomorrow',
    fontFamily: 'Tomorrow',
  },
  {
    name: 'Nunito',
    fontFamily: 'Nunito',
  },
  {
    name: 'Baskerville',
    fontFamily: 'Libre Baskerville',
    // fontFamily: 'LibreBaskerville' // For paths object key in useFonts hook
  },
]

export const SIGN_EXPORT_SCALE = 3.7795323207
export const ENGRAVING_COLOR = '#000000' // Black for engraving
export const CUT_COLOR = '#ff0000' // Red for cut
export const CUT_STROKE_WIDTH = 0.01
export const MOUNTING_HOLE_RADIUS = 6
export const MOUNTING_HOLE_RADIUS_NYCKELRING = 6
export const MOUNTING_HOLE_CUTTING_WIDTH = 1
