import styled from 'styled-components'

import { colors, spacing } from 'styles/utilities/variables.js'
import { media } from 'styles/utilities/functions'

const StyledSizePanel = styled.div`
  //border: 1px solid ${colors.grey01};
  ${media.tabletPortraitBelow`
    margin: 0 0 ${spacing.small} ${spacing.medium};
  `}
`

StyledSizePanel.Message = styled.p`
  font-style: italic;
  margin-bottom: ${spacing.xSmall};

  ${media.tabletPortraitBelow`
    margin-top: ${spacing.medium};
  `}
`

StyledSizePanel.Actions = styled.div`
  display: flex;
  ${media.tabletPortraitBelow`
     flex-wrap: wrap;
  `}//flex-wrap: wrap;
`

StyledSizePanel.Action = styled.div`
  &:nth-child(odd) {
    margin-right: ${spacing.large};
  }
  ${media.tabletPortraitBelow`
    width: 100%;
    &:nth-child(odd) {
      margin-right: 0;
    }
  `}
`

StyledSizePanel.Controls = styled.div`
  margin-bottom: ${spacing.large};

  &:last-child {
    margin-bottom: 0;
  }

  ${media.tabletPortraitBelow`
    margin-top: ${spacing.medium};
    margin-bottom: ${spacing.medium};
  `}
`

export default StyledSizePanel
