import React from 'react'
import ThemeBadge from './'
import StyledThemeBadgeList from './ThemeBadgeList.style'

const ThemeBadgeList = (props) => {
  const { options } = props
  return (
    <StyledThemeBadgeList>
      {options.map((option, index) => {
        return (
          <ThemeBadge
            key={index}
            background={option.colorPrimary}
            isActive={option.isActive}
            color={option.colorSecondary}
            fontFamily={option.fontFamily}
            clickCallback={option.clickCallback}
            name={option.name}
          />
        )
      })}
    </StyledThemeBadgeList>
  )
}

export default ThemeBadgeList

