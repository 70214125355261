import React from 'react'
import { categoriesSignsByType, sortCategorisedSigns } from 'helpers/signs'
import { SIGN_TYPES } from 'constants/Signs'
import Button from 'components/common/Button'
import { StyledCart } from 'components/common/Cart/Cart.styles'
import { Divider } from '../Divider'
import { hideCart, showProceedToCheckoutModal } from 'redux/actions/appActions'
import { useDispatch, useSelector } from 'react-redux'

const getCategoryPriceFromOrder = (wcOrder, sign) => {
  const { subtotal, subtotal_tax } = wcOrder?.line_items?.find(
    (item) => item.variation_id === sign.values.price.id
  ) ?? { subtotal: 0, subtotal_tax: 0 }

  return parseInt(subtotal) + parseInt(subtotal_tax)
}

const getCategoryPriceFromCartItem = (cartItem) => {
  const {
    totals: { line_subtotal, line_subtotal_tax },
  } = cartItem

  return (parseInt(line_subtotal) + parseInt(line_subtotal_tax)) / 100
}

const stateSelector = (state) => {
  return {
    wcCart: state.cart.wcCart,
  }
}

const Cart = (props) => {
  const { signs, wcOrder, checkout = false } = props
  const { wcCart } = useSelector(stateSelector)
  const dispatch = useDispatch()
  const categorisedSigns = categoriesSignsByType(signs)
  const sortedCategories = sortCategorisedSigns(categorisedSigns)

  console.log('🚀 ~ file: Cart.js:34 ~ wcOrder:', wcOrder)

  if (!wcOrder && !wcCart) {
    return null
  }

  const shippingTotal = wcOrder
    ? parseFloat(wcOrder.shipping_total) + parseFloat(wcOrder.shipping_tax)
    : wcCart
    ? parseInt(wcCart.totals.total_shipping) / 100 +
      parseInt(wcCart.totals.total_shipping_tax) / 100
    : 0
  const total = wcOrder
    ? wcOrder.total
    : wcCart
    ? parseInt(wcCart.totals.total_price) / 100
    : 0

  return (
    <StyledCart checkout={checkout}>
      {!checkout && <StyledCart.Title>I varukorgen</StyledCart.Title>}

      {signs.length ? (
        <>
          <StyledCart.Table>
            <StyledCart.Header>
              <tr>
                <th align='left'>St</th>
                <th align='left'>Produkt</th>
                <th align='left'>Pris</th>
              </tr>
            </StyledCart.Header>

            <tbody>
              {sortedCategories.map((category, index) => {
                const signType = SIGN_TYPES.find(
                  (signType) => signType.type === category.type
                )

                const categoryPrice = wcOrder
                  ? getCategoryPriceFromOrder(wcOrder, category.signs[0])
                  : getCategoryPriceFromCartItem(category.signs[0].cartItem)

                return (
                  <StyledCart.ProductRow
                    key={category.type}
                    darkBorder={index === sortedCategories.length - 1}
                  >
                    <td>{category.signs.length}</td>
                    <td>
                      {category.signs.length > 1
                        ? signType.name_plural
                        : signType.name}
                    </td>
                    <td>{categoryPrice} kr</td>
                  </StyledCart.ProductRow>
                )
              })}

              {wcOrder ? wcOrder?.coupon_lines?.map(
                ({ code, discount, discount_tax }) => (
                  <StyledCart.Row key={code}>
                    <td></td>
                    <td>Rabattkupong ({code})</td>
                    <td>
                      -{parseFloat(discount) + parseFloat(discount_tax)} kr
                    </td>
                  </StyledCart.Row>
                )
              ) : wcCart?.coupons?.map(
                ({ code, totals: { total_discount, total_discount_tax } }) => (
                  <StyledCart.Row key={code}>
                    <td></td>
                    <td>Rabattkupong ({code})</td>
                    <td>
                      -
                      {(parseInt(total_discount) +
                        parseInt(total_discount_tax)) /
                        100}{' '}
                      kr
                    </td>
                  </StyledCart.Row>
                )
              )}
              <StyledCart.Row>
                <td></td>
                <td>Frakt</td>
                <td>{shippingTotal} kr</td>
              </StyledCart.Row>
            </tbody>

            <StyledCart.Footer>
              <tr>
                <td></td>
                <td>Totalt (inkl moms)</td>
                <td>{total} kr</td>
              </tr>
            </StyledCart.Footer>
          </StyledCart.Table>

          {!checkout && (
            <Button
              type={'primary light'}
              handleButtonClick={() => {
                dispatch(showProceedToCheckoutModal())
              }}
            >
              Till kassan
            </Button>
          )}
        </>
      ) : (
        <StyledCart.EmptyMessage>
          Varukorgen är tom eftersom du har inte skapat några skyltar.
        </StyledCart.EmptyMessage>
      )}

      {!checkout && (
        <>
          <Divider size='small' />
          <Button type='white' handleButtonClick={() => dispatch(hideCart())}>
            Stäng
          </Button>
        </>
      )}
    </StyledCart>
  )
}

export default Cart
