import styled from 'styled-components'
import { colors, spacing } from 'styles/utilities/variables.js'
import { media } from 'styles/utilities/functions'

const StyledThemePanel = styled.div``

StyledThemePanel.Actions = styled.div`
  p {
    margin-bottom: ${spacing.medium};

    ${media.tabletPortraitBelow`
      display: none;
    `}
  }
`

StyledThemePanel.Action = styled.div`
  border-top: 1px solid ${colors.grey04};
  margin-top: ${spacing.large};
  padding-top: ${spacing.large};

  &:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  ${media.tabletPortraitBelow`
    border-top: none;
    margin-top: ${spacing.medium};
    padding-top: 0;
  `}
`

StyledThemePanel.SelectWrapper = styled.div`
  padding: 0 ${spacing.medium};
`

export default StyledThemePanel
