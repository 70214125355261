import styled from 'styled-components'
import { text } from 'styles/utilities/mixins'
import { spacing, colors, typography } from 'styles/utilities/variables'


const StyledThemeBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

StyledThemeBadge.Badge = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => (background ? background : colors.primary)};
  box-shadow: ${({ background }) =>
    background === '#ffffff' ? '2px 2px 4px rgba(0, 0, 0, 0.25)' : ''};
  color: ${({ color }) => (color ? color : colors.white)};
  width: ${spacing.xLarge};
  height: ${spacing.xLarge};
  border-radius: 50%;
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : typography.primaryFont};
`

StyledThemeBadge.Text = styled.div`
  font-size: ${typography.sizes.large};
`

StyledThemeBadge.Active = styled.div`
  position: absolute;
  inset: -4px;
  border: 1px solid ${colors.blueMid};
  border-radius: 50%;
`

StyledThemeBadge.Name = styled.div`
  ${text.small}
  width: 100%;
  text-align: center;
  margin-top: ${spacing.small};
  white-space: pre-wrap;
  line-height: 1.3;
`

export default StyledThemeBadge
