import styled from 'styled-components'
import Button from '../Button'
import StyledIcon from '../Icon/Icon.style'
import { StyledButton } from '../Button/Button.style'
import { media } from 'styles/utilities/functions'
import StyledLoader from '../Loader/Loader.style'

const StyledAddToCartButton = styled(Button)`
  min-width: 164px;

  ${StyledIcon} {
    margin-left: .25rem;
  }

  ${StyledLoader} {
    margin: 0;
  }
  ${StyledButton.Text} {
    margin-left: auto;
    margin-right: auto;
  }

  ${media.tabletPortraitBelow`
    min-width: unset;
    margin-right: 10px;
  `}
`

export default StyledAddToCartButton
