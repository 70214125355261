import React from 'react'
import { StyledDropdownList } from './DropdownList.style'

const DropdownList = (props) => {
  const { options, open, handleOptionChange, width = '100%' } = props
  return (
    open && (
      <StyledDropdownList width={width}>
        {options.map((option, index) => {
          return (
            <li key={index}>
              <button onClick={() => handleOptionChange(option.value)}>
                {option.label}
              </button>
            </li>
          )
        })}
      </StyledDropdownList>
    )
  )
}

export default DropdownList
