import React from 'react'
import { useSelector } from 'react-redux'
import StyledEditor from './EditorDesktop.style'
// import { ToolbarHeader } from 'components/editor/Toolbar/Header'
import Header from 'components/editor/Header'
import SignList from 'components/editor/SignList'
import { EType } from 'constants/Editor'
import SignSingleView from 'components/editor/SignSingleView'
import ToolbarPane from 'components/editor/Toolbar/Pane'
import EmptyListMessage from 'components/editor/EmptyListMessage'

const stateSelector = (state) => {
  return {
    signs: state.signs.present.signs,
    editor: state.editor,
  }
}

const EditorDesktopLayout = (props) => {
  const { isTableStage, isSingleStage } = props
  const { signs, editor } = useSelector(stateSelector)

  const hasSigns = signs && signs.length > 0

  return (
    <StyledEditor>
      <StyledEditor.Header>
        <Header />
      </StyledEditor.Header>

      <StyledEditor.SplitLayout>
        {/* {isSingleStage && (
          <StyledEditor.Aside width={25} left>
            {hasSigns && <SignList type={EType.aside} />}
          </StyledEditor.Aside>
        )} */}

        <StyledEditor.Aside show={isSingleStage} width={25} left>
          <SignList show={hasSigns} type={EType.aside} />
        </StyledEditor.Aside>

        <StyledEditor.Stage>
          {isSingleStage && hasSigns && (
            <SignSingleView editor={editor} signs={signs} />
          )}

          {/* Removed for now */}
          {/* {isTableStage && (
            <StyledEditor.Toolbar>
              <ToolbarHeader />
            </StyledEditor.Toolbar>
          )} */}

          {/* {isTableStage && hasSigns && <SignList type={EType.desktop} />} */}
          <SignList show={isTableStage && hasSigns} type={EType.desktop} />

          {!hasSigns && isTableStage && (
            <EmptyListMessage
              title={'Här var det tomt!'}
              message={'Tryck på "Ny skylt" ovan för att skapa en ny skylt.'}
            />
          )}
        </StyledEditor.Stage>

        <StyledEditor.Aside show={true} width={29} right>
          <ToolbarPane />
        </StyledEditor.Aside>
      </StyledEditor.SplitLayout>
    </StyledEditor>
  )
}

export default EditorDesktopLayout
