import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NewSignModalDesktop from './NewSignModalDesktop'
import NewSignModalMobile from './NewSignModalMobile'
import { hideNewSignModal } from 'redux/actions/appActions'
import { cloneSignWithNewType, duplicateSign, newSignTemplate } from 'helpers/signs'
import { addSign } from 'redux/actions/signsActions'
import {
  setActiveStage,
  setNewestItemToActive,
} from 'redux/actions/editorActions'
import { SINGLE_STAGE } from 'constants/Editor'
import Cookies from 'js-cookie'
import { useResponsive } from 'hooks/useResponsive'
import { MOUNTING_TYPES } from 'constants/Signs'

const NewSignModal = () => {
  const dispatch = useDispatch()
  const { breakpoints } = useResponsive()
  const [selectedSignType, setSelectedSignType] = useState(null)
  const [selectedShape, setSelectedShape] = useState(null)

  const { prices } = useSelector((state) => {
    return {
      prices: state.app.prices,
    }
  })

  let sign = selectedSignType
    ? cloneSignWithNewType(newSignTemplate, selectedSignType.type, prices)
    : null

  useEffect(() => {
    if (selectedSignType && !selectedShape && sign?.values) {
      setSelectedShape(sign.values.shape)
    }
  }, [selectedShape, selectedSignType, sign])

  const closeModalAndCreateSign = () => {
    sign.values.shape = selectedShape

    if (!Cookies.get('stage_type')) {
      dispatch(setActiveStage(SINGLE_STAGE))
    }
    if (
      selectedSignType.type === 'nyckelbricka-rund' ||
      selectedSignType.type === 'nyckelbricka-rektangular'
    ) {
      const mounting = MOUNTING_TYPES.filter(
        (mounting) => mounting.type === 'nyckelring'
      )
      dispatch(addSign(duplicateSign(sign), mounting))
    } else {
      dispatch(addSign(duplicateSign(sign)))
    }
    dispatch(hideNewSignModal())
    dispatch(setNewestItemToActive())
  }

  const onModalReject = () => {
    dispatch(hideNewSignModal())
  }
  const onGoBack = () => {
    setSelectedSignType(null)
    setSelectedShape(null)
  }

  const props = {
    sign,
    onModalReject,
    onGoBack,
    closeModalAndCreateSign,
    selectedSignType,
    setSelectedSignType,
    selectedShape,
    setSelectedShape,
  }

  return (
    <>
      {breakpoints.tabletPortraitBelow ? (
        <NewSignModalMobile {...props} />
      ) : (
        <NewSignModalDesktop {...props} />
      )}
    </>
  )
}

export default NewSignModal
