import { Container } from 'components/common/Container'
import { Divider } from 'components/common/Divider'
import { Header } from 'components/common/Header'
import React from 'react'
import StyledCustomerView from './style'

const CustomerView = ({backTitle, children}) => {
  return (
    <StyledCustomerView>
      <Header backTitle={backTitle} />

      <Divider />
      <Container width={1000}>
        {children}
      </Container>
    </StyledCustomerView>
  )
}

export default CustomerView
