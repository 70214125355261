import { getData } from 'helpers/storage'
import { API_BASE } from './Api'
import { headers } from './Customer'

const ORDER_ENDPOINT = 'orders'

const checkStatus = (response) => {
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}


export const postOrder = async (payload) => {
  const hash = getData('cart-hash')

  return await await fetch(`${API_BASE}/${ORDER_ENDPOINT}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
    body: JSON.stringify({
      ...payload,
      hash,
    })
  })
    .then(checkStatus)
    .then((res) => res.json())
}


export const fetchSingleOrder = async (id) => {
  return await await fetch(`${API_BASE}/${ORDER_ENDPOINT}/${id}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: headers(),
  })
    .then(checkStatus)
    .then((res) => res.json())
}
