import React from 'react'
import PropTypes from 'prop-types'
import StyledContainer from './style'

const Container = (props) => {
  const { children, width } = props

  return <StyledContainer width={width}>{children}</StyledContainer>
}

Container.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number
}

export { Container }
